import React from 'react'
import {
	Modal,
	Input,
	Button,
	DatePicker,
	TimePicker,
	Empty,
	Select,
	List,
	Divider,
	Spin,
} from 'antd'
import Config from '../config'
import moment from 'moment'
import axios from 'axios'
import { saveAs } from 'file-saver'
import { DownloadOutlined, DeleteOutlined } from '@ant-design/icons'
import locale from 'antd/es/date-picker/locale/ko_KR'
import 'moment/locale/ko'
const { confirm } = Modal
const { Option } = Select
const { TextArea } = Input

class EditAccessModal extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			timer: 0,
			visible: false,
			gate: null,
			access: {},
			title: '',
			gates: null,
			onOk: null,
			onDelete: null,
			onCancel: null,
			selectedGate: null,
			okText: '수정',
			imgNotFound: false,
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			this.state.gate === nextState.gate &&
			this.state.access === nextState.access &&
			this.state.visible === nextState.visible &&
			this.state.selectedGate === nextState.selectedGate &&
			this.state.title === nextState.title &&
			this.state.timer === nextState.timer &&
			this.state.imgNotFound === nextState.imgNotFound &&
			this.state.visible == false &&
			nextState.visible == false
		) {
			return false
		}
		return true
	}

	componentDidUpdate(prevProps) {}

	getImageUrl = (id) => {
		const { user } = this.props
		if (user == null) {
			return
		}
		return (
			Config.SERVER_URL +
			'/access/getImage/v2?parking_id=' +
			user.parking_id +
			'&access_id=' +
			id
		)
	}

	setAccess = (access) => {
		this.setState({
			access,
		})
	}
	show({
		gate,
		access = {
			accessTime: moment().format(),
			gateId: gate != null ? gate._id : null,
		},
		timer = 0,
		title = '출입기록 수정',
		okText = '수정',
		gates,
		onOk,
		onDelete,
		onCancel,
		onAddBlacklist,
		onAddTicket,
		onDeleteBlacklist,
	}) {
		var tmpAccess = { ...access }
		if (gate == null && access.gateId == null) {
			tmpAccess.gateId =
				gates != null && gates.length != 0 ? Object.keys(gates)[0] : null
		}
		this.setState({
			visible: true,
			gate,
			access: tmpAccess,
			timer,
			title,
			gates,
			onOk,
			onDelete,
			onCancel,
			okText,
			imgNotFound: false,
			onAddBlacklist,
			onAddTicket,
			onDeleteBlacklist,
		})

		if (timer != 0) {
			clearInterval(this.interval)
			this.interval = setInterval(() => {
				this.setState({
					timer: this.state.timer - 1,
				})
				if (this.state.timer - 1 < 0) {
					this.hide()
				}
			}, 1000)
		}
	}

	isVisible() {
		return this.state.visible
	}

	hide() {
		clearInterval(this.interval)
		this.setState({
			visible: false,
			gate: null,
			access: {},
			timer: 0,
			title: '출입기록 수정',
			gates: null,
			onOk: null,
			onDelete: null,
			onCancel: null,
			onAddBlacklist: null,
			onAddTicket: null,
			onDeleteBlacklist: null,
		})
	}
	render() {
		const { loading, memos } = this.props
		const {
			visible,
			title,
			timer,
			gate,
			access,
			onOk,
			onDelete,
			onCancel,
			gates,
			selectedGate,
			okText,
			imgNotFound,
			onAddBlacklist,
			onAddTicket,
			onDeleteBlacklist,
		} = this.state

		return (
			<Modal
				title={
					<p>
						{timer != 0 ? title + '(' + timer + ')' : title}
						{onDelete && (
							<Button
								key='delete'
								onClick={() => {
									onDelete(access)
								}}
								style={{ marginLeft: 8 }}
								shape='circle'
								type='text'
							>
								<DeleteOutlined style={{ color: 'red' }} />
							</Button>
						)}
					</p>
				}
				centered
				open={visible}
				onOk={onOk}
				width={
					access.carplate == '미인식' || (memos != null && memos.length == 0)
						? 520
						: 960
				}
				onCancel={() => {
					this.setState({
						visible: false,
					})
					if (onCancel) {
						onCancel()
					}
				}}
				footer={[
					onAddBlacklist &&
						access.carplate != '미인식' &&
						access.isBlacklist == 0 &&
						access.isRegistered != 1 && (
							<Button
								key='add_blacklist'
								onClick={() => {
									onAddBlacklist(access)
								}}
								style={{ float: 'left' }}
								type='danger'
							>
								블랙리스트 등록
							</Button>
						),
					onDeleteBlacklist &&
						access.carplate != '미인식' &&
						access.isBlacklist == 1 && (
							<Button
								key='delete_blacklist'
								onClick={() => {
									onDeleteBlacklist(access)
								}}
								style={{ float: 'left' }}
								type='danger'
							>
								블랙리스트 해제
							</Button>
						),
					onDeleteBlacklist && access.isBlacklist == 1 && (
						<div
							style={{
								float: 'left',
								marginLeft: 16,
								height: 32,
								display: 'flex',
								alignItems: 'center',
								color: 'red',
							}}
						>
							사유: {access.blacklistComment}
						</div>
					),
					onAddTicket &&
						access.carplate != '미인식' &&
						access.isRegistered == 0 &&
						access.isBlacklist == 0 &&
						access.isTempRegistered == 0 && (
							<Button
								key='add_ticket'
								onClick={() => {
									onAddTicket(access)
								}}
								style={{ float: 'left' }}
								type='default'
							>
								임시방문증 등록
							</Button>
						),
					access.carplate != '미인식' && access.isRegistered == 1 && (
						<Button
							key='show_registration'
							onClick={() => {
								window.open(
									`/car/registration/${access.carplate}?isPopup=true`,
									'new_window'
								)
							}}
							style={{ float: 'left' }}
							type='default'
						>
							등록차량 조회
						</Button>
					),
					<Button
						key='cancel'
						onClick={() => {
							this.setState({
								visible: false,
							})
							if (onCancel) {
								onCancel()
							}
						}}
					>
						취소
					</Button>,
					<Button
						key='edit'
						onClick={() => {
							if (onOk) {
								onOk(access)
							}
						}}
						loading={loading}
						type='primary'
					>
						{okText}
					</Button>,
				]}
			>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<div style={{ width: 520 }} ref='mainLayout'>
						{access == null || access._id == null || imgNotFound ? (
							<Empty
								description='No Image'
								image={Empty.PRESENTED_IMAGE_SIMPLE}
							/>
						) : (
							<div style={{ position: 'relative' }}>
								<img
									src={this.getImageUrl(access._id)}
									style={{ height: '100%', width: '100%' }}
									onLoad={(e) => {
										this.setState({
											imgNotFound: false,
										})
									}}
									alt={access.carplate}
									onError={(e) => {
										this.setState({
											imgNotFound: true,
										})
									}}
								/>

								<Button
									icon={<DownloadOutlined />}
									shape='circle'
									style={{ position: 'absolute', top: 8, right: 8 }}
									onClick={() => {
										axios({
											url: this.getImageUrl(access._id),
											method: 'GET',
											responseType: 'blob', // important
										}).then((response) => {
											saveAs(
												new Blob([response.data]),
												`${gate.name}_${moment(access.accessTime).format(
													'YYYYMMDD_HHmmss'
												)}_${access.carplate}.jpg`
											)
										})
									}}
								></Button>
							</div>
						)}
						{gate == null && gates != null ? (
							<div style={{ marginTop: 16 }}>
								<label>출입구</label>
								<Select
									value={access.gateId}
									style={{ width: '100%' }}
									onChange={(k) => {
										var access = { ...this.state.access }
										access.gateId = k
										this.setState({
											access,
										})
									}}
								>
									{Object.keys(gates)
										.sort((x, y) => {
											return gates[x].name > gates[y].name
												? 1
												: gates[x].name < gates[y].name
												? -1
												: 0
										})
										.map((k, i) => {
											var gate = gates[k]
											return (
												<Option key={gate._id} value={gate._id}>
													{gate.name}
												</Option>
											)
										})}
								</Select>
							</div>
						) : (
							<div style={{ marginTop: 16 }}>
								<label>출입구</label>
								<Input value={gate != null ? gate.name : ''} disabled />
							</div>
						)}
						<div style={{ marginTop: 16 }}>
							<label>차량번호</label>
							<Input
								placeholder={'차량번호'}
								style={{ fontSize: 18, fontWeight: 'bold' }}
								value={access != null ? access.carplate : ''}
								onChange={(e) => {
									var access = { ...this.state.access }
									access.carplate = e.target.value
									this.setState({
										access,
									})
								}}
							/>
						</div>

						<div style={{ marginTop: 16 }}>
							<label>메모</label>
							<TextArea
								rows={3}
								placeholder={'메모'}
								value={access != null ? access.comment : ''}
								onChange={(e) => {
									var access = { ...this.state.access }
									access.comment = e.target.value
									this.setState({
										access,
									})
								}}
							/>
						</div>
						<div style={{ marginTop: 16 }}>
							<label style={{ marginTop: 16 }}>진입시간</label>
							<DatePicker
								locale={locale}
								style={{ width: '100%' }}
								value={
									access != null && access.accessTime != null
										? moment(access.accessTime)
										: null
								}
								defaultValue={moment()}
								onChange={(datetime) => {
									var access = { ...this.state.access }
									access.accessTime =
										datetime == null ? null : datetime.format()
									this.setState({
										access,
									})
								}}
							/>
							<TimePicker
								style={{ width: '100%', marginTop: 8 }}
								value={
									access != null && access.accessTime != null
										? moment(access.accessTime)
										: null
								}
								defaultValue={moment()}
								onChange={(datetime) => {
									var access = { ...this.state.access }
									access.accessTime =
										datetime == null ? null : datetime.format()
									this.setState({
										access,
									})
								}}
							/>
						</div>
					</div>
					{access.carplate != '미인식' &&
						memos != null &&
						memos.length != 0 && (
							<Divider type='vertical' style={{ height: 'auto' }} />
						)}
					{access.carplate != '미인식' &&
						memos != null &&
						memos.length != 0 && (
							<span
								style={{
									flex: 1,
									height:
										this.refs.mainLayout == null
											? 0
											: this.refs.mainLayout.clientHeight,
									overflowY: 'auto',
								}}
							>
								{memos == null ? (
									<div
										style={{
											flex: 1,
											height: '100%',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<Spin size='large' />
									</div>
								) : (
									<List
										itemLayout='horizontal'
										dataSource={memos}
										renderItem={(item) => (
											<List.Item
												style={{
													background:
														item.accessId == access._id ? '#f3f3f3' : '',
													padding: 8,
												}}
											>
												<List.Item.Meta
													title={
														<span>
															<p style={{ display: 'inline' }}>
																{item.gateName}
															</p>
															<p
																style={{
																	float: 'right',
																	fontWeight: 'normal',
																}}
															>
																{moment(item.accessTime).format(
																	'YYYY-MM-DD HH:mm'
																)}
															</p>
														</span>
													}
													description={
														<p style={{ whiteSpace: 'pre-line' }}>
															{item.comment == null
																? '메모 없음'
																: item.comment}
														</p>
													}
												/>
											</List.Item>
										)}
									/>
								)}
							</span>
						)}
				</div>
			</Modal>
		)
	}
}

export default EditAccessModal
