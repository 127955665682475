import Config from '../config'

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'


export const updatePassword = (oldPassword, newPassword) => {
	return {
		type: UPDATE_PASSWORD,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/auth/updatePassword',
			data: {
				oldPassword, newPassword
			}
		}
	}
}
