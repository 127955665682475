import Config from '../config'

export const GET_TYPES = 'GET_TYPES'

export const getTypes = () => {
	return {
		type: GET_TYPES,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + '/device/getTypes'
		}
	}
}
