import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

import { Provider } from 'react-redux'
import createStore from './store/configureStore'

import axios from 'axios'

import { notification, Button } from 'antd'

import { logout } from './action/user'

axios.defaults.withCredentials = true
axios.interceptors.response.use(
	function (response) {
		return response
	},
	function (error) {
		if (error.response == null) {
			return Promise.reject(error)
		}
		if (error.response.status === 401) {
			if (error.request.responseURL.indexOf('auth/info') != -1) {
				return Promise.reject(error)
			}
			notification['error']({
				key: 'login_require',
				message: '세션 만료',
				duration: 0,
				description: '세션이 만료되었거나 유효하지 않은 세션입니다.',
				placement: 'bottomLeft',
				btn: (
					<Button
						type='danger'
						size='small'
						onClick={() =>
							window.location.replace(
								`/login?prevUrl=${window.location.pathname}`
							)
						}
					>
						로그인
					</Button>
				),
			})
		} else if (error.response.status === 402) {
			//window.location.replace('/membership')
		} else if (error.response.status === 403) {
			alert('해당 기능은 관리자만 접근할 수 있습니다.')
			window.location.replace('/')
		} else if (error.response.status === 400) {
			notification['error']({
				key: 'not_valid_session',
				message: '유효하지 않은 세션',
				duration: 0,
				description: '세션이 만료되었거나 유효하지 않은 세션입니다.',
				placement: 'bottomLeft',
				// btn: (
				// 	<Button
				// 		type='danger'
				// 		size='small'
				// 		onClick={() => {
				// 			localStorage.removeItem('loggedInfo')
				// 			window.location.replace('/login')
				// 		}}
				// 	>
				// 		재접속
				// 	</Button>
				// ),
			})
			window.location.replace('/')
		}
		return Promise.reject(error)
	}
)

const store = createStore()
ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
