import React from 'react'
import {
	Modal,
	Input,
	Button,
	DatePicker,
	TimePicker,
	Empty,
	Select,
	List,
	Divider,
	Spin,
	AutoComplete,
} from 'antd'
import Config from '../config'
import moment from 'moment'
import axios from 'axios'
import { saveAs } from 'file-saver'
import { DownloadOutlined, DeleteOutlined } from '@ant-design/icons'
import locale from 'antd/es/date-picker/locale/ko_KR'
import 'moment/locale/ko'
const { confirm } = Modal
const { Option } = Select
const { TextArea } = Input

class ViewMemoModal extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			visible: false,
			title: '메모 확인',
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			this.state.visible === nextState.visible &&
			this.state.visible == false &&
			nextState.visible == false
		) {
			return false
		}
		return true
	}

	componentDidUpdate(prevProps) {}

	show() {
		this.setState({
			visible: true,
		})
	}

	isVisible() {
		return this.state.visible
	}

	hide() {
		this.setState({
			visible: false,
			memo: [],
		})
	}
	render() {
		const { memos } = this.props
		const { visible, title } = this.state

		return (
			<Modal
				title={title}
				centered
				open={visible}
				// onOk={onOk}
				width={480}
				onCancel={() => {
					this.setState({
						visible: false,
					})
				}}
				footer={[
					<Button
						type='primary'
						onClick={() => {
							this.setState({
								visible: false,
							})
						}}
					>
						닫기
					</Button>,
				]}
				bodyStyle={{ height: 600, overflow: 'auto', display: 'flex' }}
			>
				<div style={{ display: 'flex', flex: 1 }}>
					{memos == null && (
						<div
							style={{
								justifyContent: 'center',
								alignItems: 'center',
								display: 'flex',
								flex: 1,
							}}
						>
							<Spin />
						</div>
					)}
					{memos != null && memos.length == 0 && (
						<div
							style={{
								justifyContent: 'center',
								alignItems: 'center',
								display: 'flex',
								flex: 1,
							}}
						>
							입력된 메모가 없습니다.
						</div>
					)}
					{memos != null && memos.length != 0 && (
						<div style={{ width: '100%' }}>
							{memos == null ? (
								<div
									style={{
										flex: 1,
										height: '100%',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<Spin size='large' />
								</div>
							) : (
								<List
									itemLayout='horizontal'
									dataSource={memos}
									renderItem={(item) => (
										<List.Item
											style={{
												padding: 8,
											}}
										>
											<List.Item.Meta
												title={
													<span>
														<p style={{ display: 'inline' }}>{item.gateName}</p>
														<p
															style={{
																float: 'right',
																fontWeight: 'normal',
															}}
														>
															{moment(item.accessTime).format(
																'YYYY-MM-DD HH:mm'
															)}
														</p>
													</span>
												}
												description={
													<p style={{ whiteSpace: 'pre-line' }}>
														{item.comment == null ? '메모 없음' : item.comment}
													</p>
												}
											/>
										</List.Item>
									)}
								/>
							)}
						</div>
					)}
				</div>
			</Modal>
		)
	}
}

export default ViewMemoModal
