import React from 'react'
import MJPEG from '../mjpeg'

import { PlayCircleFilled, PauseCircleFilled } from '@ant-design/icons'
class VideoPlayer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			status: 'uninitialzed',
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			this.state.status === nextState.status &&
			this.props.url === nextProps.url &&
			this.state.playerWidth === nextState.playerWidth &&
			this.state.playerHeight === nextState.playerHeight &&
			this.props.style === nextProps.style
		) {
			return false
		}
		return true
	}

	componentDidMount() {
		const { url } = this.props
		this.initPlayer(url)
	}

	componentDidUpdate(prevProps) {
		const { url } = this.props
		if (url != prevProps.url) {
			this.player.stop()
			this.player = null
			this.initPlayer(url)
		}
	}

	initPlayer = (url) => {
		const { reconnectDelay } = this.props
		if (this.player == null) {
			this.player = new MJPEG.Player(this.playerRef, url, {
				onStop: () => {
					this.setState({
						status: 'stopped',
					})
					// this.handle = setTimeout(() => {
					// 	this.player.start()
					// }, reconnectDelay || 1000 * 30)
				},
				onStart: () => {
					this.setState({
						status: 'playing',
					})
				},
			})
			this.player.start()
		}
	}

	componentWillUnmount() {
		if (this.player != null) {
			this.player.stop()
		}
		clearInterval(this.handle)
	}

	render() {
		const { status } = this.state

		var style = { ...this.props.style }
		style.position = 'relative'
		style.background = 'black'
		style.display = 'inline-block'

		return (
			<div style={style}>
				<p
					style={{
						position: 'absolute',
						margin: 8,
						color: '#444',
					}}
				>
					{status == 'playing' ? (
						<PlayCircleFilled style={{ fontSize: 20 }} />
					) : (
						<PauseCircleFilled style={{ fontSize: 20 }} />
					)}
				</p>
				<canvas
					ref={(e) => {
						if (e != null) {
							this.playerRef = e
							this.setState({
								playerWidth: e.getBoundingClientRect().width,
								playerHeight: e.getBoundingClientRect().height,
							})
						}
					}}
					style={{ height: '100%', width: '100%' }}
					width={this.state.playerWidth}
					height={this.state.playerHeight}
				/>
			</div>
		)
	}
}

export default VideoPlayer
