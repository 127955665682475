import { createStore, applyMiddleware, compose } from 'redux'
import reducers from '../reducer'
import promiseMiddleware from '../middleware/promiseMiddleware.js'

export default function(initialState) {
	const composeEnhancers =
		typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
			? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
					// Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
			  })
			: compose

	const enhancer = composeEnhancers(applyMiddleware(promiseMiddleware))
	return createStore(reducers, initialState, enhancer)
}
