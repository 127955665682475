import React from 'react'
import {
	Modal,
	Input,
	Button,
	DatePicker,
	TimePicker,
	Empty,
	Select,
	Col,
	Row,
	Typography,
	Radio,
	List,
} from 'antd'
import Config from '../config'
import moment from 'moment'

const { confirm } = Modal
const { Option } = Select
const { Text, Title } = Typography
const { TextArea } = Input
class ModifyCarplateModal extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			visible: false,
			input: '',
			imageUrl: null,
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (this.state.visible == false && nextState.visible == false) {
			return false
		}
		return true
	}

	componentDidUpdate(prevProps) {}

	show({ imageUrl, input, onOk }) {
		this.setState({
			visible: true,
			imageUrl,
			input,
			onOk,
		})
	}

	isVisible() {
		return this.state.visible
	}

	hide() {
		clearInterval(this.interval)
		this.setState({
			visible: false,
			input: '',
			onOk: null,
		})
	}

	render() {
		const { loading } = this.props
		const { visible, input, imageUrl, onOk } = this.state

		return (
			<Modal
				width={900}
				title={'차량번호 정정'}
				centered
				open={visible}
				onOk={onOk}
				onCancel={() => {
					this.setState({
						visible: false,
					})
				}}
				footer={[
					<Button
						key='cancel'
						onClick={() => {
							this.setState({
								visible: false,
							})
						}}
					>
						취소
					</Button>,
					<Button
						key='edit'
						onClick={() => {
							if (onOk) {
								onOk(input)
							}
							this.hide()
						}}
						loading={loading}
						type='primary'
					>
						정정하기
					</Button>,
				]}
			>
				<Row style={{ display: 'flex', alignItems: 'stretch', height: 400 }}>
					<Col span={16}>
						<img
							src={imageUrl}
							style={{
								width: '100%',
								height: '100%',
								backgroundColor: 'black',
								objectFit: 'scale-down',
							}}
							alt={'carplate'}
							onError={(e) => {
								this.setState({
									imageUrl: '/assets/title.png',
								})
							}}
						/>
					</Col>

					<Col
						span={8}
						style={{ display: 'flex', flexDirection: 'column', paddingLeft: 8 }}
					>
						<Row
							style={{
								flex: 1,
								display: 'flex',
								overflowY: 'auto',
								marginBottom: 8,
							}}
						>
							<List
								style={{ flex: 1, overflowY: 'auto', marginBottom: 8 }}
								bordered
								dataSource={[
									'47러2505',
									'1234',
									'10수1020',
									'20머1577',
									'05오1010',
								]}
								renderItem={(item) => (
									<List.Item
										onClick={() => {
											this.setState({
												input: item,
											})
										}}
									>
										{item}
									</List.Item>
								)}
							/>
						</Row>
						<Row>
							<Input
								placeholder='차량번호'
								size='large'
								value={input}
								onChange={(e) => {
									this.setState({
										input: e.target.value,
									})
								}}
							></Input>
						</Row>
					</Col>
				</Row>
			</Modal>
		)
	}
}

export default ModifyCarplateModal
