import Config from '../config'

export const GET_SETTING = 'GET_SETTING'
export const UPDATE_SETTING = 'UPDATE_SETTING'

export const GET_VISITS = 'GET_VISITS'
export const GET_VISITS_COUNT = 'GET_VISITS_COUNT'
export const GET_MONTHLY_STATISTICS = 'GET_MONTHLY_STATISTICS'
export const GET_MONTHLY_STATISTICS_COUNT = 'GET_MONTHLY_STATISTICS_COUNT'
export const GET_MONTHLY_STATISTICS_ALL = 'GET_MONTHLY_STATISTICS_ALL'

export const ADD_VISIT = 'ADD_VISIT'
export const DELETE_VISIT = 'DELETE_VISIT'
export const DELETE_VISITS = 'DELETE_VISITS'

export const UPDATE_MONTHLY_STATISTICS = 'UPDATE_MONTHLY_STATISTICS'
export const UPDATE_MONTHLY_STATISTICS_ALL = 'UPDATE_MONTHLY_STATISTICS_ALL'

export const DELETE_MONTHLY_STATISTICS = 'DELETE_MONTHLY_STATISTICS'

export const getSetting = () => {
	return {
		type: GET_SETTING,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/dt/getSetting`,
		},
	}
}
export const updateSetting = (setting) => {
	return {
		type: UPDATE_SETTING,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/dt/updateSetting`,
			data: setting,
		},
		payload: { setting },
	}
}

export const getVisits = (data) => {
	return {
		type: GET_VISITS,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/dt/getVisits`,
			params: data,
		},
	}
}

export const getVisitsCount = (data) => {
	return {
		type: GET_VISITS_COUNT,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/dt/getVisitsCount`,
			params: data,
		},
	}
}

export const getMonthlyStatistics = (data) => {
	return {
		type: GET_MONTHLY_STATISTICS,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/dt/getMonthlyStatistics`,
			params: data,
		},
	}
}
export const getMonthlyStatisticsCount = (data) => {
	return {
		type: GET_MONTHLY_STATISTICS_COUNT,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/dt/getMonthlyStatisticsCount`,
			params: data,
		},
	}
}

export const getMonthlyStatisticsAll = (data) => {
	return {
		type: GET_MONTHLY_STATISTICS_ALL,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/dt/getMonthlyStatistics`,
			params: {
				...data,
				page: 0,
				limit: 999999,
			},
		},
	}
}

export const addVisit = (data) => {
	return {
		type: ADD_VISIT,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/dt/addVisitManual`,
			data: data,
		},
		payload: { data },
	}
}

export const deleteVisit = (id) => {
	return {
		type: ADD_VISIT,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/dt/deleteVisit`,
			data: { id },
		},
	}
}

export const deleteVisits = (ids) => {
	return {
		type: ADD_VISIT,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/dt/deleteVisits`,
			data: { ids },
		},
	}
}

export const updateMonthlyStatistics = (data) => {
	return {
		type: UPDATE_MONTHLY_STATISTICS,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/dt/updateMonthlyStatisticsForIndividual`,
			data: data,
		},
	}
}

export const updateMonthlyStatisticsAll = (data) => {
	return {
		type: UPDATE_MONTHLY_STATISTICS_ALL,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/dt/updateMonthlyStatistics`,
			data: data,
		},
	}
}

export const deleteMonthlyStatistics = (data) => {
	return {
		type: DELETE_MONTHLY_STATISTICS,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/dt/deleteStatistics`,
			data: data,
		},
	}
}
