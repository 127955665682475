import { GET_GATES } from '../action/gate.js'

const REQUEST = '_REQUEST',
	SUCCESS = '_SUCCESS',
	FAILURE = '_FAILURE'

const defaultState = {
	fetchingUpdate: false,
	gates: null,
	errorMessage: null,
}

const gateReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_GATES + REQUEST:
			return {
				...state,
				fetchingUpdate: true,
				errorMessage: null,
				gates: null,
			}
		case GET_GATES + SUCCESS:
			return {
				...state,
				fetchingUpdate: false,
				gates: action.result.data.reduce((acc, item) => {
					acc[item._id] = item
					return acc
				}, {}),
				errorMessage: null,
			}
		case GET_GATES + FAILURE:
			return {
				...state,
				fetchingUpdate: false,
				errorMessage: action.error.response,
			}
		default: {
			return state
		}
	}
}

export default gateReducer
