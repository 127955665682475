import {
	GET_FUNCTIONS,
	ADD_FUNCTION,
	UPDATE_FUNCTION,
	DELETE_FUNCTION
} from '../action/function.js'

const REQUEST = '_REQUEST',
	SUCCESS = '_SUCCESS',
	FAILURE = '_FAILURE'

const defaultState = {
	updating: false,
	functions: {}
}

const functionReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_FUNCTIONS + REQUEST:
			return {
				...state,
				fetchingUpdate: true
			}
		case GET_FUNCTIONS + SUCCESS:
			return {
				...state,
				fetchingUpdate: false,
				functions: action.result.data.reduce((acc, item) => {
					if (acc[item.TBL_GATE_id] == null) {
						acc[item.TBL_GATE_id] = []
					}
					acc[item.TBL_GATE_id].push(item)
					return acc
				}, {})
			}
		case GET_FUNCTIONS + FAILURE:
			return {
				...state,
				fetchingUpdate: false,
				errorMessage: action.error.response
			}

		case ADD_FUNCTION + REQUEST:
		case UPDATE_FUNCTION + REQUEST:
		case DELETE_FUNCTION + REQUEST:
			return { ...state, updating: true }
		case ADD_FUNCTION + SUCCESS:
			var functions = { ...state.functions }
			if (functions[action.payload.function.TBL_GATE_id] == null) {
				functions[action.payload.function.TBL_GATE_id] = []
			}
			functions[action.payload.function.TBL_GATE_id] = [
				action.payload.function,
				...functions[action.payload.function.TBL_GATE_id]
			]
			return { ...state, updating: false, functions }

		case UPDATE_FUNCTION + SUCCESS:
			var newFunctions = { ...state.functions }
			if (newFunctions[action.payload.function.TBL_GATE_id] == null) {
				newFunctions[action.payload.function.TBL_GATE_id] = []
			}

			newFunctions[action.payload.function.TBL_GATE_id] = newFunctions[
				action.payload.function.TBL_GATE_id
			].map((item, index) => {
				if (action.payload.function.functionId == item.functionId) {
					return action.payload.function
				}
				return item
			})

			return {
				...state,
				updating: false,
				functions: newFunctions
			}
		case DELETE_FUNCTION + SUCCESS:
			var functions = { ...state.functions }
			var tmp = functions[action.payload.function.TBL_GATE_id].filter(item => {
				return item.functionId != action.payload.function.functionId
			})
			functions[action.payload.function.TBL_GATE_id] = tmp

			return { ...state, updating: false, functions }

		case ADD_FUNCTION + FAILURE:
		case UPDATE_FUNCTION + FAILURE:
		case DELETE_FUNCTION + FAILURE:
			return {
				...state,
				updating: false
			}
		default: {
			return state
		}
	}
}

export default functionReducer
