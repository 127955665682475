import { LOGIN, INFO, LOGOUT, SIGNUP } from '../action/user.js'

const REQUEST = '_REQUEST',
	SUCCESS = '_SUCCESS',
	FAILURE = '_FAILURE'

const defaultState = {
	isLoggedIn: false,
	fetchingUpdate: false,
	user: null,
	errorMessage: null,
	signupResult: null,
	isSessionInvalid: false,
}

const userReducer = (state = defaultState, action) => {
	switch (action.type) {
		case LOGIN + REQUEST:
			return {
				...state,
				fetchingUpdate: true,
				errorMessage: null,
			}
		case LOGIN + SUCCESS:
			localStorage.setItem(
				'loggedInfo',
				JSON.stringify(action.result.data.user)
			)
			return {
				...state,
				fetchingUpdate: false,
				isLoggedIn: true,
				user: action.result.data.user,
				errorMessage: null,
				isSessionInvalid: false,
			}
		case LOGIN + FAILURE:
			return {
				...state,
				fetchingUpdate: false,
				errorMessage: 'ID와 비밀번호를 확인해주세요.',
			}
		case INFO + REQUEST:
			return {
				...state,
				user: action.payload.user,
				isLoggedIn: true,
			}
		case INFO + SUCCESS:
			var user = state.user
			// if (action.result.data.uid !== state.user.uid) {
			// 	user = action.result.data
			// 	localStorage.setItem('loggedInfo', JSON.stringify(user))
			// }
			user = action.result.data
			localStorage.setItem('loggedInfo', JSON.stringify(user))
			return {
				...state,
				fetchingUpdate: false,
				isLoggedIn: true,
				user: user,
				isSessionInvalid: false,
			}
		case INFO + FAILURE:
			localStorage.removeItem('loggedInfo')
			return {
				...state,
				fetchingUpdate: false,
				isLoggedIn: false,
				user: null,
				isSessionInvalid: true,
			}
		case LOGOUT + REQUEST:
			return {
				...state,
				isLoggedIn: false,
				user: null,
			}
		case SIGNUP + SUCCESS:
			return {
				...state,
				signupResult: {
					result: true,
				},
			}
		case SIGNUP + FAILURE:
			return {
				...state,
				signupResult: {
					result: false,
					data: action.error.response.data,
				},
			}
		default: {
			return state
		}
	}
}

export default userReducer
