import {
	GET_DEVICES,
	ADD_GATE,
	DELETE_GATE,
	ADD_CONTROLLER,
	ADD_LED,
	ADD_CAMERA,
	DELETE_CONTROLLER,
	DELETE_LED,
	ADD_LED_TEXT,
	DELETE_LED_TEXT,
	DELETE_CAMERA,
	UPDATE_CAMERA,
	UPDATE_CONTROLLER,
	UPDATE_GATE,
	UPDATE_LED,
	UPDATE_LED_TEXT,
	ADD_RELAY,
	UPDATE_RELAY,
	DELETE_RELAY,
	ADD_SCHEDULE,
	UPDATE_SCHEDULE,
	DELETE_SCHEDULE,
	ADD_TTS,
	UPDATE_TTS,
	DELETE_TTS,
	ADD_TTS_MESSAGE,
	UPDATE_TTS_MESSAGE,
	DELETE_TTS_MESSAGE,
	ADD_SUBGROUP,
	UPDATE_SUBGROUP,
	DELETE_SUBGROUP,
	SET_SUBGROUP_GATE,
	GET_SUBGROUPS,
	//SET_POST_PROCESS,
	ADD_POST_PROCESS,
	UPDATE_POST_PROCESS,
	DELETE_POST_PROCESS,
	SET_SNAPSHOT_OPTION,
	ADD_SCHEDULED_FUNCTION,
	DELETE_SCHEDULED_FUNCTION,
	ADD_INTEGRATED,
	UPDATE_INTEGRATED,
	DELETE_INTEGRATED,
	SET_GATE_PERMISSIONS,
	GET_POSSIBLE_DONGS,
} from '../action/device.js'

const REQUEST = '_REQUEST',
	SUCCESS = '_SUCCESS',
	FAILURE = '_FAILURE'

const defaultState = {
	fetchingUpdate: false,
	gates: {},
	subgroups: {},
	dongOptions: [],
	action: '',
}

const deviceReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_DEVICES + REQUEST:
			return {
				...state,
				errorMessage: null,
			}
		case GET_DEVICES + SUCCESS:
			return {
				...state,
				gates: action.result.data,
				errorMessage: null,
			}
		case GET_DEVICES + FAILURE:
			return {
				...state,
				errorMessage: action.error.response,
			}
		case GET_POSSIBLE_DONGS + SUCCESS:
			return {
				...state,
				dongOptions: action.result.data,
			}
		case ADD_GATE + REQUEST:
		case DELETE_GATE + REQUEST:
		case UPDATE_GATE + REQUEST:
		case ADD_CAMERA + REQUEST:
		case DELETE_CAMERA + REQUEST:
		case UPDATE_CAMERA + REQUEST:
		case ADD_CONTROLLER + REQUEST:
		case DELETE_CONTROLLER + REQUEST:
		case UPDATE_CONTROLLER + REQUEST:
		case ADD_LED + REQUEST:
		case DELETE_LED + REQUEST:
		case UPDATE_LED + REQUEST:
		case ADD_LED_TEXT + REQUEST:
		case DELETE_LED_TEXT + REQUEST:
		case UPDATE_LED_TEXT + REQUEST:
		case ADD_RELAY + REQUEST:
		case UPDATE_RELAY + REQUEST:
		case DELETE_RELAY + REQUEST:
		case ADD_SCHEDULE + REQUEST:
		case UPDATE_SCHEDULE + REQUEST:
		case DELETE_SCHEDULE + REQUEST:
		case ADD_TTS + REQUEST:
		case UPDATE_TTS + REQUEST:
		case DELETE_TTS + REQUEST:
		case ADD_TTS_MESSAGE + REQUEST:
		case UPDATE_TTS_MESSAGE + REQUEST:
		case DELETE_TTS_MESSAGE + REQUEST:
		case ADD_SUBGROUP + REQUEST:
		case UPDATE_SUBGROUP + REQUEST:
		case DELETE_SUBGROUP + REQUEST:
		case SET_SUBGROUP_GATE + REQUEST:
		case ADD_POST_PROCESS + REQUEST:
		case UPDATE_POST_PROCESS + REQUEST:
		case DELETE_POST_PROCESS + REQUEST:
		case SET_SNAPSHOT_OPTION + REQUEST:
		case ADD_SCHEDULED_FUNCTION + REQUEST:
		case DELETE_SCHEDULED_FUNCTION + REQUEST:
		case ADD_INTEGRATED + REQUEST:
		case DELETE_INTEGRATED + REQUEST:
		case UPDATE_INTEGRATED + REQUEST:
		case SET_GATE_PERMISSIONS + REQUEST:
			return {
				...state,
				fetchingUpdate: true,
			}
		case ADD_GATE + SUCCESS:
		case ADD_GATE + FAILURE:
		case ADD_CAMERA + SUCCESS:
		case ADD_CAMERA + FAILURE:
		case ADD_CONTROLLER + SUCCESS:
		case ADD_CONTROLLER + FAILURE:
		case ADD_LED + SUCCESS:
		case ADD_LED + FAILURE:
		case ADD_LED_TEXT + SUCCESS:
		case ADD_LED_TEXT + FAILURE:
		case ADD_RELAY + SUCCESS:
		case ADD_RELAY + FAILURE:
		case ADD_SCHEDULE + SUCCESS:
		case ADD_SCHEDULE + FAILURE:
		case DELETE_GATE + SUCCESS:
		case DELETE_GATE + FAILURE:
		case DELETE_CAMERA + SUCCESS:
		case DELETE_CAMERA + FAILURE:
		case DELETE_CONTROLLER + SUCCESS:
		case DELETE_CONTROLLER + FAILURE:
		case DELETE_LED + SUCCESS:
		case DELETE_LED + FAILURE:
		case DELETE_LED_TEXT + SUCCESS:
		case DELETE_LED_TEXT + FAILURE:
		case DELETE_RELAY + SUCCESS:
		case DELETE_RELAY + FAILURE:
		case DELETE_SCHEDULE + SUCCESS:
		case DELETE_SCHEDULE + FAILURE:
		case UPDATE_GATE + SUCCESS:
		case UPDATE_GATE + FAILURE:
		case UPDATE_CAMERA + SUCCESS:
		case UPDATE_CAMERA + FAILURE:
		case UPDATE_CONTROLLER + SUCCESS:
		case UPDATE_CONTROLLER + FAILURE:
		case UPDATE_LED + SUCCESS:
		case UPDATE_LED + FAILURE:
		case UPDATE_LED_TEXT + SUCCESS:
		case UPDATE_LED_TEXT + FAILURE:
		case UPDATE_RELAY + SUCCESS:
		case UPDATE_RELAY + FAILURE:
		case UPDATE_SCHEDULE + SUCCESS:
		case UPDATE_SCHEDULE + FAILURE:
		case ADD_TTS + SUCCESS:
		case UPDATE_TTS + SUCCESS:
		case DELETE_TTS + SUCCESS:
		case ADD_TTS_MESSAGE + SUCCESS:
		case UPDATE_TTS_MESSAGE + SUCCESS:
		case DELETE_TTS_MESSAGE + SUCCESS:
		case ADD_TTS + FAILURE:
		case UPDATE_TTS + FAILURE:
		case DELETE_TTS + FAILURE:
		case ADD_TTS_MESSAGE + FAILURE:
		case UPDATE_TTS_MESSAGE + FAILURE:
		case DELETE_TTS_MESSAGE + FAILURE:
		case ADD_SUBGROUP + SUCCESS:
		case UPDATE_SUBGROUP + SUCCESS:
		case DELETE_SUBGROUP + SUCCESS:
		case SET_SUBGROUP_GATE + SUCCESS:
		case ADD_SUBGROUP + FAILURE:
		case UPDATE_SUBGROUP + FAILURE:
		case DELETE_SUBGROUP + FAILURE:
		case SET_SUBGROUP_GATE + FAILURE:
		case ADD_POST_PROCESS + SUCCESS:
		case UPDATE_POST_PROCESS + SUCCESS:
		case DELETE_POST_PROCESS + SUCCESS:
		case SET_SNAPSHOT_OPTION + SUCCESS:
		case ADD_POST_PROCESS + FAILURE:
		case UPDATE_POST_PROCESS + FAILURE:
		case DELETE_POST_PROCESS + FAILURE:
		case SET_SNAPSHOT_OPTION + FAILURE:
		case ADD_SCHEDULED_FUNCTION + SUCCESS:
		case DELETE_SCHEDULED_FUNCTION + SUCCESS:
		case ADD_SCHEDULED_FUNCTION + FAILURE:
		case DELETE_SCHEDULED_FUNCTION + FAILURE:
		case ADD_INTEGRATED + SUCCESS:
		case DELETE_INTEGRATED + SUCCESS:
		case UPDATE_INTEGRATED + SUCCESS:
		case ADD_INTEGRATED + FAILURE:
		case DELETE_INTEGRATED + FAILURE:
		case UPDATE_INTEGRATED + FAILURE:
		case SET_GATE_PERMISSIONS + SUCCESS:
		case SET_GATE_PERMISSIONS + FAILURE:
			return { ...state, fetchingUpdate: false, action: action.type }
		case GET_SUBGROUPS + SUCCESS:
			var init = {}
			init[null] = {
				name: '미분류',
				gates: [],
			}
			return {
				...state,
				fetchingUpdate: false,
				subgroups: action.result.data.reduce((acc, x, i) => {
					acc[x._id] = x
					return acc
				}, init),
			}
		case GET_SUBGROUPS + FAILURE:
			return {
				...state,
				fetchingUpdate: false,
				subgroups: {},
			}
		default: {
			return state
		}
	}
}

export default deviceReducer
