var Config = require('./config')

module.exports = {
	fillArrayWithNumbers: (n) => {
		var arr = Array.apply(null, Array(n))
		return arr.map(function (x, i) {
			return i
		})
	},
	fixForReadAsArrayBuffer: (data) => {
		var o = '',
			l = 0,
			w = 10240
		for (; l < data.byteLength / w; ++l)
			o += String.fromCharCode.apply(
				null,
				new Uint8Array(data.slice(l * w, l * w + w))
			)
		o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)))
		return o
	},

	/* 
     checkBrowser return 1 : Internet Explorer 11ver
                  return 2 : Internet Explorer < 11ver  ex) IE 6,7,8 ... 
                  return 3 : other browser              ex) Chrome, Firefox...
    */
	checkBrowser: () => {
		var agent = navigator.userAgent.toLowerCase()

		if (
			navigator.appName == 'Netscape' &&
			navigator.userAgent.search('Trident') != -1
		) {
			return 1
		} else if (agent.indexOf('msie') != -1) {
			return 2
		} else {
			return 3
		}
	},

	getImageUrl: (user, id, n = 0) => {
		return (
			Config.SERVER_URL +
			'/access/getImage/v2?parking_id=' +
			user.parking_id +
			'&access_id=' +
			id +
			'&n=' +
			n
		)
	},

	getParameterByName: (location, name) => {
		name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
		var regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
			results = regex.exec(location.search)
		return results === null
			? ''
			: decodeURIComponent(results[1].replace(/\+/g, ' '))
	},

	requireRole: (user, requiredRole) => {
		const roles = ['super_admin', 'admin', 'only_search', 'only_monitoring']
		if (requiredRole == null) {
			return true
		}
		if (user == null) {
			return false
		}
		const userRole = roles.indexOf(user.role)
		if (userRole == -1) {
			return false
		}
		return roles.indexOf(requiredRole) >= userRole
	},
}
