import {
	GET_OVERDUE,
	GET_OVERDUE_COUNT,
	DELETE_OVERDUE
} from '../action/query.js'

import moment from 'moment'

const REQUEST = '_REQUEST',
	SUCCESS = '_SUCCESS',
	FAILURE = '_FAILURE'

const defaultState = {
	fetchingUpdate: false,
	overdue: [],
	total: 0,
	updating: false,
	errorMessage: null
}

const overdueReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_OVERDUE + REQUEST:
			return {
				...state,
				fetchingUpdate: true,
				errorMessage: null
			}
		case GET_OVERDUE + SUCCESS:
			return {
				...state,
				fetchingUpdate: false,
				overdue: action.result.data,
				errorMessage: null
			}
		case GET_OVERDUE + FAILURE:
			return {
				...state,
				fetchingUpdate: false,
				errorMessage: action.error.response
			}

		case GET_OVERDUE_COUNT + SUCCESS:
			return {
				...state,
				total: action.result.data.count
			}
		case DELETE_OVERDUE + REQUEST:
			return {
				...state,
				updating: true
			}
		case DELETE_OVERDUE + SUCCESS:
		case DELETE_OVERDUE + FAILURE:
			return {
				...state,
				updating: false
			}
		default: {
			return state
		}
	}
}

export default overdueReducer
