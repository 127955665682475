import {
	GET_REGISTRATIONS,
	GET_REGISTRATIONS_COUNT,
	ADD_REGISTRATION,
	UPDATE_REGISTRATION,
	DELETE_REGISTRATION,
	GET_ALL_REGISTRATIONS,
	IMPORT_REGISTRATIONS,
} from '../action/registration'

const REQUEST = '_REQUEST',
	SUCCESS = '_SUCCESS',
	FAILURE = '_FAILURE'

const defaultState = {
	cars: null,
	totalCarsCount: 0,
	errorMessage: [],
	updating: false,
	allCars: null,
	downloadingAllCars: false,
}

const registrationReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_REGISTRATIONS + REQUEST:
			return state
		case GET_REGISTRATIONS + SUCCESS:
			return {
				...state,
				cars: action.result.data,
			}
		case GET_REGISTRATIONS + FAILURE:
			return {
				...state,
				errorMessage: [...state.errorMessage, action.error.response],
			}

		case GET_REGISTRATIONS_COUNT + REQUEST:
			return state
		case GET_REGISTRATIONS_COUNT + SUCCESS:
			return {
				...state,
				totalCarsCount: action.result.data.count,
			}
		case GET_REGISTRATIONS_COUNT + FAILURE:
			return {
				...state,
				totalCarsCount: 0,
				errorMessage: [...state.errorMessage, action.error.response],
			}
		case ADD_REGISTRATION + REQUEST:
		case UPDATE_REGISTRATION + REQUEST:
		case DELETE_REGISTRATION + REQUEST:
			return {
				...state,
				updating: true,
			}
		case ADD_REGISTRATION + SUCCESS:
		case UPDATE_REGISTRATION + SUCCESS:
		case DELETE_REGISTRATION + SUCCESS:
			return {
				...state,
				updating: false,
			}
		case ADD_REGISTRATION + FAILURE:
		case UPDATE_REGISTRATION + FAILURE:
		case DELETE_REGISTRATION + FAILURE:
			return {
				...state,
				updating: false,
				errorMessage: [
					...state.errorMessage,
					action.error.response.data.message,
				],
			}
		case GET_ALL_REGISTRATIONS + REQUEST:
			return {
				...state,
				downloadingAllCars: true,
				allCars: null,
			}
		case GET_ALL_REGISTRATIONS + SUCCESS:
			return {
				...state,
				downloadingAllCars: false,
				allCars: action.result.data,
			}
		case GET_ALL_REGISTRATIONS + FAILURE:
			return {
				...state,
				downloadingAllCars: false,
				errorMessage: [...state.errorMessage, action.error.response],
			}

		case IMPORT_REGISTRATIONS + REQUEST:
			return { ...state, importing: true }
		case IMPORT_REGISTRATIONS + SUCCESS:
			return { ...state, importing: false, importingResult: action.result.data }
		case IMPORT_REGISTRATIONS + FAILURE:
			return {
				...state,
				importing: false,
				errorMessage: [...state.errorMessage, action.error.response],
			}
		default: {
			return state
		}
	}
}

export default registrationReducer
