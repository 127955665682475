import Config from '../config'
import moment from 'moment'

export const GET_RESIDENTS = 'GET_RESIDENTS'
export const GET_RESIDENTS_COUNT = 'GET_RESIDENTS_COUNT'
export const ADD_RESIDENT = 'ADD_RESIDENT'
export const UPDATE_RESIDENT = 'UPDATE_RESIDENT'
export const DELETE_RESIDENTS = 'DELETE_RESIDENTS'

export const getResidents = ({
	parkingId,
	page = 0,
	limit = 20,
	qName = '',
	qHo = '',
	qDong = '',
}) => {
	return {
		type: GET_RESIDENTS,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/admin/getResidents`,
			params: {
				parking_id: parkingId,
				page,
				limit,
				q_name: qName,
				q_dong: qDong,
				q_ho: qHo,
			},
		},
	}
}

export const getResidentsCount = ({
	parkingId,
	qName = '',
	qHo = '',
	qDong = '',
}) => {
	return {
		type: GET_RESIDENTS_COUNT,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/admin/getResidentsCount`,
			params: {
				parking_id: parkingId,
				q_name: qName,
				q_dong: qDong,
				q_ho: qHo,
			},
		},
	}
}

/**
 * item: dong, ho, password, name
 */
export const addResident = ({ parkingId, item }) => {
	return {
		type: ADD_RESIDENT,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/admin/addResident',
			data: {
				parking_id: parkingId,
				...item,
			},
		},
	}
}

/**
  var parkingId = req.body.parking_id;
  var dong = req.body.dong;
  var ho = req.body.ho;
  var password = req.body.password;
  var name = req.body.name;
 */
export const updateResident = ({ parkingId, item }) => {
	return {
		type: UPDATE_RESIDENT,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/admin/updateResident',
			data: {
				parking_id: parkingId,
				resident_id: item._id,
				...item,
			},
		},
	}
}

export const deleteResidents = ({ parkingId, residentIds }) => {
	return {
		type: DELETE_RESIDENTS,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/admin/deleteResidents',
			data: {
				parking_id: parkingId,
				resident_ids: residentIds,
			},
		},
	}
}
