import React from 'react'
import { Modal, Input, Button } from 'antd'

const { TextArea } = Input

class EditBlacklistModal extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			visible: false,
			onOk: null,
			onCancel: null,
			isEdit: false,
			item: null,
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			this.state.visible === nextState.visible &&
			this.state.item === nextState.item &&
			this.state.isEdit === nextState.isEdit &&
			this.state.visible == false &&
			nextState.visible == false
		) {
			return false
		}
		return true
	}

	show({ item, onOk, onCancel, isEdit }) {
		this.setState({
			visible: true,
			isEdit: isEdit == null ? item != null : isEdit,
			item,
			onOk,
			onCancel,
		})
	}
	hide() {
		this.setState({
			visible: false,
			onOk: null,
			onCancel: null,
			item: null,
		})
	}

	render() {
		const { visible, onOk, onCancel, isEdit, item } = this.state

		return (
			<Modal
				title={isEdit ? '블랙리스트 수정' : '블랙리스트 등록'}
				centered
				open={visible}
				onOk={onOk}
				onCancel={() => {
					this.setState({
						visible: false,
					})
					if (onCancel) {
						onCancel()
					}
				}}
				footer={[
					<Button
						key='cancel'
						onClick={() => {
							this.setState({
								visible: false,
							})
							if (onCancel) {
								onCancel()
							}
						}}
					>
						취소
					</Button>,
					<Button
						key='edit'
						onClick={() => {
							if (onOk) {
								onOk(item)
							}
						}}
						type='primary'
					>
						{isEdit ? '수정' : '등록'}
					</Button>,
				]}
			>
				<div style={{ marginTop: 16 }}>
					<label>차량번호</label>
					<Input
						placeholder={'차량번호'}
						value={item != null ? item.car : ''}
						disabled={isEdit}
						onChange={(e) => {
							var item = { ...this.state.item }
							item.car = e.target.value
							this.setState({
								item,
							})
						}}
					/>
				</div>
				<div style={{ marginTop: 16 }}>
					<label>사유</label>
					<TextArea
						rows={3}
						placeholder={'블랙리스트 사유'}
						value={item != null ? item.comment : ''}
						onChange={(e) => {
							var item = { ...this.state.item }
							item.comment = e.target.value
							this.setState({
								item,
							})
						}}
					/>
				</div>
			</Modal>
		)
	}
}

export default EditBlacklistModal
