import Config from '../config'

export const GET_DEVICE_GATES = 'GET_DEVICE_GATES'
export const GET_DEVICE_CAMERAS = 'GET_DEVICE_CAMERAS'
export const GET_DEVICE_CONTROLLERS = 'GET_DEVICE_CONTROLLERS'
export const GET_DEVICE_LEDS = 'GET_DEVICE_LEDS'
export const GET_DEVICE_LED_TEXTS = 'GET_DEVICE_LED_TEXTS'
export const GET_RELAY = 'GET_RELAY'

export const ADD_GATE = 'ADD_GATE'
export const DELETE_GATE = 'DELETE_GATE'
export const UPDATE_GATE = 'UPDATE_GATE'

export const ADD_CAMERA = 'ADD_CAMERA'
export const DELETE_CAMERA = 'DELETE_CAMERA'
export const UPDATE_CAMERA = 'UPDATE_CAMERA'

export const ADD_CONTROLLER = 'ADD_CONTROLLER'
export const DELETE_CONTROLLER = 'DELETE_CONTROLLER'
export const UPDATE_CONTROLLER = 'UPDATE_CONTROLLER'

export const ADD_LED = 'ADD_LED'
export const DELETE_LED = 'DELETE_LED'
export const UPDATE_LED = 'UPDATE_LED'

export const ADD_LED_TEXT = 'ADD_LED_TEXT'
export const DELETE_LED_TEXT = 'DELETE_LED_TEXT'
export const UPDATE_LED_TEXT = 'UPDATE_LED_TEXT'

export const ADD_RELAY = 'ADD_RELAY'
export const DELETE_RELAY = 'DELETE_RELAY'
export const UPDATE_RELAY = 'UPDATE_RELAY'

export const ADD_SCHEDULE = 'ADD_SCHEDULE'
export const DELETE_SCHEDULE = 'DELETE_SCHEDULE'
export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE'
export const GET_DEVICES = 'GET_DEVICES'

export const ADD_TTS = 'ADD_TTS'
export const DELETE_TTS = 'DELETE_TTS'
export const UPDATE_TTS = 'UPDATE_TTS'

export const ADD_TTS_MESSAGE = 'ADD_TTS_MESSAGE'
export const DELETE_TTS_MESSAGE = 'DELETE_TTS_MESSAGE'
export const UPDATE_TTS_MESSAGE = 'UPDATE_TTS_MESSAGE'

export const ADD_SUBGROUP = 'ADD_SUBGROUP'
export const UPDATE_SUBGROUP = 'UPDATE_SUBGROUP'
export const DELETE_SUBGROUP = 'DELETE_SUBGROUP'
export const SET_SUBGROUP_GATE = 'SET_SUBGROUP_GATE'
export const GET_SUBGROUPS = 'GET_SUBGROUPS'

// export const SET_POST_PROCESS = 'SET_POST_PROCESS'

export const ADD_POST_PROCESS = 'ADD_POST_PROCESS'
export const UPDATE_POST_PROCESS = 'UPDATE_POST_PROCESS'
export const DELETE_POST_PROCESS = 'DELETE_POST_PROCESS'
export const SET_SNAPSHOT_OPTION = 'SET_SNAPSHOT_OPTION'

export const ADD_SCHEDULED_FUNCTION = 'ADD_SCHEDULED_FUNCTION'
export const DELETE_SCHEDULED_FUNCTION = 'DELETE_SCHEDULED_FUNCTION'
export const GET_SCHEDULED_FUNCTION = 'GET_SCHEDULED_FUNCTION'

export const ADD_INTEGRATED = 'ADD_INTEGRATED'
export const UPDATE_INTEGRATED = 'UPDATE_INTEGRATED'
export const DELETE_INTEGRATED = 'DELETE_INTEGRATED'

export const SET_GATE_PERMISSIONS = 'SET_GATE_PERMISSIONS'
export const GET_POSSIBLE_DONGS = 'GET_POSSIBLE_DONGS'

export const getDevices = (parkingId) => {
	return {
		type: GET_DEVICES,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + '/device/getDevices',
			params: {
				parking_id: parkingId,
			},
		},
	}
}

export const getGates = (parkingId) => {
	return {
		type: GET_DEVICE_GATES,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + '/device/getGates',
			params: {
				parking_id: parkingId,
			},
		},
	}
}

export const addGate = (parkingId, gate) => {
	return {
		type: ADD_GATE,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/addGate',
			data: { parking_id: parkingId, ...gate },
		},
	}
}
export const deleteGate = (parkingId, gateId) => {
	return {
		type: DELETE_GATE,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/deleteGate',
			data: { parking_id: parkingId, id: gateId },
		},
	}
}

export const updateGate = (parkingId, gate) => {
	return {
		type: UPDATE_GATE,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/updateGate',
			data: { parking_id: parkingId, id: gate._id, ...gate },
		},
	}
}

export const getCameras = (parkingId, gateId) => {
	return {
		type: GET_DEVICE_CAMERAS,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + '/device/getCameras',
			params: {
				parking_id: parkingId,
				gate_id: gateId,
			},
		},
	}
}

export const addCamera = (parkingId, gateId, camera) => {
	return {
		type: ADD_CAMERA,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/addCamera',
			data: { parking_id: parkingId, gate_id: gateId, ...camera },
		},
	}
}

export const deleteCamera = (parkingId, cameraId) => {
	return {
		type: DELETE_CAMERA,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/deleteCamera',
			data: { parking_id: parkingId, id: cameraId },
		},
	}
}

export const updateCamera = (parkingId, gateId, camera) => {
	return {
		type: UPDATE_CAMERA,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/updateCamera',
			data: {
				parking_id: parkingId,
				gate_id: gateId,
				id: camera._id,
				...camera,
			},
		},
	}
}

export const getControllers = (parkingId, gateId) => {
	return {
		type: GET_DEVICE_CONTROLLERS,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + '/device/getControllers',
			params: {
				parking_id: parkingId,
				gate_id: gateId,
			},
		},
	}
}

export const addController = (parkingId, gateId, controller) => {
	return {
		type: ADD_CONTROLLER,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/addController',
			data: { parking_id: parkingId, gate_id: gateId, ...controller },
		},
	}
}
export const deleteController = (parkingId, controllerId) => {
	return {
		type: DELETE_CONTROLLER,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/deleteController',
			data: { parking_id: parkingId, id: controllerId },
		},
	}
}

export const updateController = (parkingId, gateId, controller) => {
	return {
		type: UPDATE_CONTROLLER,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/updateController',
			data: {
				parking_id: parkingId,
				gate_id: gateId,
				id: controller._id,
				...controller,
			},
		},
	}
}

export const getLEDs = (parkingId, gateId) => {
	return {
		type: GET_DEVICE_LEDS,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + '/device/getLEDs',
			params: {
				parking_id: parkingId,
				gate_id: gateId,
			},
		},
	}
}

export const addLED = (parkingId, gateId, led) => {
	return {
		type: ADD_LED,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/addLED',
			data: { parking_id: parkingId, gate_id: gateId, ...led },
		},
	}
}

export const deleteLED = (parkingId, ledId) => {
	return {
		type: DELETE_LED,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/deleteLED',
			data: { parking_id: parkingId, id: ledId },
		},
	}
}

export const updateLED = (parkingId, gateId, led) => {
	return {
		type: UPDATE_LED,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/updateLED',
			data: { parking_id: parkingId, gate_id: gateId, id: led._id, ...led },
		},
	}
}

export const getLEDTexts = (parkingId, ledId) => {
	return {
		type: GET_DEVICE_LED_TEXTS,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + '/device/getLEDTexts',
			params: {
				parking_id: parkingId,
				led_id: ledId,
			},
		},
	}
}
export const addLEDText = (parkingId, ledId, text) => {
	return {
		type: ADD_LED_TEXT,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/addLEDText',
			data: { parking_id: parkingId, led_id: ledId, ...text },
		},
	}
}

export const deleteLEDText = (parkingId, textId) => {
	return {
		type: DELETE_LED_TEXT,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/deleteLEDText',
			data: { parking_id: parkingId, id: textId },
		},
	}
}

export const updateLEDText = (parkingId, text) => {
	return {
		type: UPDATE_LED_TEXT,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/updateLEDText',
			data: { parking_id: parkingId, id: text._id, ...text },
		},
	}
}

export const getRelays = (parkingId, controllerId) => {
	return {
		type: GET_RELAY,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/device/getRelays`,
			params: {
				parking_id: parkingId,
				controller_id: controllerId,
			},
		},
	}
}
export const addRelay = (parkingId, controllerId, relay) => {
	return {
		type: ADD_RELAY,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/addRelay',
			data: { parking_id: parkingId, controller_id: controllerId, ...relay },
		},
	}
}

export const deleteRelay = (parkingId, relayId) => {
	return {
		type: DELETE_RELAY,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/deleteRelay',
			data: { parking_id: parkingId, id: relayId },
		},
	}
}

export const updateRelay = (parkingId, relay) => {
	return {
		type: UPDATE_RELAY,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/updateRelay',
			data: { parking_id: parkingId, id: relay._id, ...relay },
		},
	}
}

export const addSchedule = (parkingId, cameraId, schedule) => {
	return {
		type: ADD_SCHEDULE,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/addSchedule',
			data: { parking_id: parkingId, camera_id: cameraId, ...schedule },
		},
	}
}

// export const setPostProcess = (parkingId, cameraId, data) => {
// 	return {
// 		type: SET_POST_PROCESS,
// 		promise: {
// 			method: 'post',
// 			url: Config.SERVER_URL + '/device/updatePostProcess',
// 			data: { parking_id: parkingId, camera_id: cameraId, ...data },
// 		},
// 	}
// }

export const deleteSchedule = (parkingId, scheduleId) => {
	return {
		type: DELETE_SCHEDULE,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/deleteSchedule',
			data: { parking_id: parkingId, id: scheduleId },
		},
	}
}

export const updateSchedule = (parkingId, schedule) => {
	return {
		type: UPDATE_SCHEDULE,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/updateSchedule',
			data: { parking_id: parkingId, id: schedule._id, ...schedule },
		},
	}
}

export const addTTS = (parkingId, gateId, tts) => {
	return {
		type: ADD_TTS,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/addTTS',
			data: { parking_id: parkingId, gate_id: gateId, ...tts },
		},
	}
}

export const deleteTTS = (parkingId, ttsId) => {
	return {
		type: DELETE_TTS,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/deleteTTS',
			data: { parking_id: parkingId, id: ttsId },
		},
	}
}

export const updateTTS = (parkingId, gateId, tts) => {
	return {
		type: UPDATE_TTS,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/updateTTS',
			data: {
				parking_id: parkingId,
				gate_id: gateId,
				id: tts._id,
				camera_id: tts.cameraId,
			},
		},
	}
}

export const addTTSMessage = (parkingId, ttsId, message) => {
	return {
		type: ADD_TTS_MESSAGE,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/addTTSMessage',
			data: { parking_id: parkingId, tts_id: ttsId, ...message },
		},
	}
}

export const deleteTTSMessage = (parkingId, messageId) => {
	return {
		type: DELETE_TTS_MESSAGE,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/deleteTTSMessage',
			data: { parking_id: parkingId, id: messageId },
		},
	}
}

export const updateTTSMessage = (parkingId, message) => {
	return {
		type: UPDATE_TTS_MESSAGE,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/updateTTSMessage',
			data: { parking_id: parkingId, id: message._id, ...message },
		},
	}
}

export const addSubgroup = (parkingId, groupName) => {
	return {
		type: ADD_SUBGROUP,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/subgroup/addSubgroup',
			data: { parking_id: parkingId, name: groupName },
		},
	}
}
export const updateSubgroup = (id, groupName) => {
	return {
		type: UPDATE_SUBGROUP,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/subgroup/updateSubgroup',
			data: { id: id, name: groupName },
		},
	}
}

export const deleteSubgroup = (id) => {
	return {
		type: DELETE_SUBGROUP,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/subgroup/deleteSubgroup',
			data: { id: id },
		},
	}
}

export const setSubgroupGate = (gateId, groupId, newGroupId) => {
	return {
		type: SET_SUBGROUP_GATE,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/subgroup/setSubgroupGate',
			data: {
				gate_id: gateId,
				group_id: groupId == 'null' ? null : groupId,
				new_group_id: newGroupId == 'null' ? null : newGroupId,
			},
		},
	}
}

export const getSubgroups = (parkingId) => {
	return {
		type: GET_SUBGROUPS,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/subgroup/getSubgroups`,
			params: {
				parking_id: parkingId,
			},
		},
	}
}

export const addPostProcess = (parkingId, cameraId, options) => {
	return {
		type: ADD_POST_PROCESS,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/addPostProcess',
			data: { camera_id: cameraId, ...options },
		},
	}
}

export const deletePostProcess = (parkingId, id) => {
	return {
		type: DELETE_POST_PROCESS,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/deletePostProcess',
			data: { id: id },
		},
	}
}

export const updatePostProcess = (parkingId, id, options) => {
	return {
		type: UPDATE_TTS_MESSAGE,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/updatePostProcess',
			data: { id: id, ...options },
		},
	}
}

export const setSnapshotOption = (cameraId, options) => {
	return {
		type: SET_SNAPSHOT_OPTION,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/setSnapshotOption',
			data: { camera_id: cameraId, ...options },
		},
	}
}

export const addScheduledFunction = (gateId, options) => {
	return {
		type: ADD_SCHEDULED_FUNCTION,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/addScheduledFunction',
			data: { gate_id: gateId, ...options },
		},
	}
}

export const deleteScheduledFunction = (id) => {
	return {
		type: DELETE_SCHEDULED_FUNCTION,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/deleteScheduledFunction',
			data: { id: id },
		},
	}
}

export const addIntegrated = (gateId, data) => {
	return {
		type: ADD_INTEGRATED,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/addIntegrated',
			data: { gate_id: gateId, ...data },
		},
	}
}

export const deleteIntegrated = (id) => {
	return {
		type: DELETE_INTEGRATED,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/deleteIntegrated',
			data: { id: id },
		},
	}
}

export const updateIntegrated = (id, data) => {
	return {
		type: UPDATE_INTEGRATED,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/updateIntegrated',
			data: { id: id, ...data },
		},
	}
}

export const setGatePermissions = (gateId, allowList, denyList) => {
	return {
		type: SET_GATE_PERMISSIONS,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/device/setGatePermissions',
			data: { gate_id: gateId, allow_list: allowList, deny_list: denyList },
		},
	}
}

export const getPossibleDongs = (parkingId) => {
	return {
		type: GET_POSSIBLE_DONGS,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + '/device/getPossibleDongs',
			params: {
				parking_id: parkingId,
			},
		},
	}
}
