import { UPDATE_PASSWORD } from '../action/password'

const REQUEST = '_REQUEST',
	SUCCESS = '_SUCCESS',
	FAILURE = '_FAILURE'

const defaultState = {
	updating: false,
	errorMessage: null
}

const passwordReducer = (state = defaultState, action) => {
	switch (action.type) {
		case UPDATE_PASSWORD + REQUEST:
			return {
				...state,
				updating: true
			}
		case UPDATE_PASSWORD + SUCCESS:
			return {
				...state,
				errorMessage: null,
				updating: false
			}
		case UPDATE_PASSWORD + FAILURE:
			return {
				...state,
				errorMessage: action.error.response != null && action.error.response.data != null ? action.error.response.data.message : '오류가 발생하였습니다.',
				updating: false
			}
		default: {
			return state
		}
	}
}

export default passwordReducer
