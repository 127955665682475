import Config from '../config'

export const GET_CORRECT_INFO = 'GET_CORRECT_INFO'
export const ADD_CORRECT_INFO = 'ADD_CORRECT_INFO'
export const DELETE_CORRECT_INFO = 'DELETE_CORRECT_INFO'

export const getCorrectInfo = (parkingId) => {
	return {
		type: GET_CORRECT_INFO,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/car/getCorrectInfo`,
			params: {
				parking_id: parkingId,
			},
		},
	}
}

export const addCorrectInfo = (parkingId, mistakeCarplate, originCarplate) => {
	return {
		type: ADD_CORRECT_INFO,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/car/addCorrectInfo',
			data: {
				parking_id: parkingId,
				mistake_carplate: mistakeCarplate,
				origin_carplate: originCarplate,
			},
		},
		payload: {
			mistakeCarplate,
			originCarplate,
		},
	}
}

export const deleteCorrectInfo = (parkingId, mistakeCarplate) => {
	return {
		type: DELETE_CORRECT_INFO,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/car/deleteCorrectInfo',
			data: {
				parking_id: parkingId,
				mistake_carplate: mistakeCarplate,
			},
		},
		payload: {
			mistakeCarplate,
		},
	}
}
