import {
	GET_TICKETS,
	GET_ISSUED_TICKETS,
	GET_ISSUED_TICKETS_COUNT,
	CANCEL_TICKET,
	DELETE_TICKET,
	ISSUE_TICKET,
	GET_TICKET_SETTING,
	SET_TICKET_SETTING,
	GET_TICKET_INFOS,
	ADD_TICKET_INFO,
	UPDATE_TICKET_INFO,
	DELETE_TICKET_INFO,
	GET_TICKET_STATISTICS,
	ADD_TICKET_BLACKLIST,
	GET_TICKET_BLACKLIST,
	DELETE_TICKET_BLACKLIST
} from '../action/ticket'

const REQUEST = '_REQUEST',
	SUCCESS = '_SUCCESS',
	FAILURE = '_FAILURE'

const defaultState = {
	tickets: null,
	issuedTickets: null,
	statistics: null,
	setting: {},
	total: 0,
	errorMessage: null,
	updating: false,
	blacklist: null,
	updatingBlacklist: false
}

const ticketReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_TICKET_BLACKLIST + SUCCESS:
			return {
				...state,
				blacklist: action.result.data,
				errorMessage: null
			}
		case GET_TICKET_BLACKLIST + REQUEST:
			return {
				...state
			}
		case GET_TICKET_BLACKLIST + FAILURE:
			return {
				...state,
				errorMessage: action.error.response.data.message,
			}
		case ADD_TICKET_BLACKLIST + SUCCESS:
			return {
				...state,
				updatingBlacklist: false,
				errorMessage: null
			}
		case ADD_TICKET_BLACKLIST + REQUEST:
			return {
				...state,
				updatingBlacklist: true
			}
		case ADD_TICKET_BLACKLIST + FAILURE:
			return {
				...state,
				updatingBlacklist: false,
				errorMessage: action.error.response.data.message,
			}
		case DELETE_TICKET_BLACKLIST + SUCCESS:
			return {
				...state,
				updatingBlacklist: false
			}
		case DELETE_TICKET_BLACKLIST + REQUEST:
			return {
				...state,
				updatingBlacklist: true
			}
		case DELETE_TICKET_BLACKLIST + FAILURE:
			return {
				...state,
				updatingBlacklist: false,
				errorMessage: action.error.response,
			}	

		case GET_TICKET_SETTING + SUCCESS:
			return {
				...state,
				setting: action.result.data,
			}
		case SET_TICKET_SETTING + REQUEST:
			return {
				...state,
				updating: true,
			}
		case SET_TICKET_SETTING + SUCCESS:
			return {
				...state,
				updating: false,
			}
		case GET_TICKET_INFOS + SUCCESS:
			return {
				...state,
				tickets: action.result.data,
			}
		case ADD_TICKET_INFO + REQUEST:
			return {
				...state,
				updating: true,
			}
		case ADD_TICKET_INFO + SUCCESS:
			return {
				...state,
				updating: false,
				errorMessage: null,
			}
		case ADD_TICKET_INFO + FAILURE:
			return {
				...state,
				updating: false,
				errorMessage: action.error.response,
			}

		case UPDATE_TICKET_INFO + REQUEST:
			return {
				...state,
				updating: true,
			}
		case UPDATE_TICKET_INFO + SUCCESS:
			return {
				...state,
				updating: false,
				errorMessage: null,
			}
		case UPDATE_TICKET_INFO + FAILURE:
			return {
				...state,
				updating: false,
				errorMessage: action.error.response,
			}
		case DELETE_TICKET_INFO + REQUEST:
			return {
				...state,
				updating: true,
			}
		case DELETE_TICKET_INFO + SUCCESS:
			return {
				...state,
				updating: false,
				errorMessage: null,
			}
		case DELETE_TICKET_INFO + FAILURE:
			return {
				...state,
				updating: false,
				errorMessage: action.error.response,
			}
		case GET_TICKETS + SUCCESS:
			return {
				...state,
				tickets: action.result.data,
			}
		case GET_ISSUED_TICKETS + SUCCESS:
			return {
				...state,
				issuedTickets: action.result.data,
			}
		case GET_ISSUED_TICKETS_COUNT + SUCCESS:
			return {
				...state,
				total: action.result.data.count,
			}
		case GET_TICKETS + FAILURE:
		case GET_ISSUED_TICKETS + FAILURE:
		case GET_ISSUED_TICKETS_COUNT + FAILURE:
			return {
				...state,
				errorMessage: action.error.response,
			}
		case ISSUE_TICKET + REQUEST:
		case CANCEL_TICKET + REQUEST:
		case DELETE_TICKET + REQUEST:
			return {
				...state,
				updating: true,
			}
		case ISSUE_TICKET + SUCCESS:
		case CANCEL_TICKET + SUCCESS:
		case DELETE_TICKET + SUCCESS:
			return {
				...state,
				updating: false,
			}

		case ISSUE_TICKET + FAILURE:
		case CANCEL_TICKET + FAILURE:
		case DELETE_TICKET + FAILURE:
			return {
				...state,
				updating: false,
				errorMessage: action.error.response,
			}
		case GET_TICKET_STATISTICS + REQUEST:
			return {
				...state,
				updating: true,
				statistics: [],
			}
		case GET_TICKET_STATISTICS + SUCCESS:
			return {
				...state,
				updating: false,
				statistics: action.result.data,
			}
		case GET_TICKET_STATISTICS + FAILURE:
			return {
				...state,
				updating: false,
				statistics: [],
				errorMessage: action.error.response,
			}
		default: {
			return state
		}
	}
}

export default ticketReducer
