import {
	GET_DISCOUNTS,
	ADD_DISCOUNT,
	DELETE_DISCOUNT,
	UPDATE_DISCOUNT
} from '../action/discount.js'

const REQUEST = '_REQUEST',
	SUCCESS = '_SUCCESS',
	FAILURE = '_FAILURE'

const defaultState = {
	fetchingUpdate: false,
	updating: false,
	discounts: []
}

const discountReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_DISCOUNTS + REQUEST:
			return {
				...state,
				fetchingUpdate: true
			}
		case GET_DISCOUNTS + SUCCESS:
			return {
				...state,
				fetchingUpdate: false,
				discounts: action.result.data.reduce((acc, item, key) => {
					item.key = key
					acc.push(item)
					return acc
				}, [])
			}
		case GET_DISCOUNTS + FAILURE:
			return {
				...state,
				fetchingUpdate: false,
				errorMessage: action.error.response
			}
		case ADD_DISCOUNT + REQUEST:
		case UPDATE_DISCOUNT + REQUEST:
		case DELETE_DISCOUNT + REQUEST:
			return {
				...state,
				updating: true,
				errorMessage: null
			}
		case ADD_DISCOUNT + SUCCESS:
		case UPDATE_DISCOUNT + SUCCESS:
		case DELETE_DISCOUNT + SUCCESS:
			return {
				...state,
				updating: false
			}

		case ADD_DISCOUNT + FAILURE:
		case UPDATE_DISCOUNT + FAILURE:
		case DELETE_DISCOUNT + FAILURE:
			return {
				...state,
				updating: false,
				errorMessage: action.error.response
			}
		default: {
			return state
		}
	}
}

export default discountReducer
