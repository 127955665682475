import React, { Suspense, lazy } from 'react'
import './App.css'
import { Layout, Spin } from 'antd'
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from 'react-router-dom'
import { HeaderLayout } from './component'

import { connect } from 'react-redux'
import { info } from './action/user'
import { retryLazy } from './lazyUtil'
// import {
// 	Monitoring,
// 	Login,
// 	Car,
// 	Search,
// 	Ticket,
// 	Settings,
// 	Signup,
// 	MembershipInfo,
// 	Home,
// 	Payment,
// 	AuthorityInfo,
// } from './page'

const Monitoring = retryLazy(() => import('./page/Monitoring'))
const Login = retryLazy(() => import('./page/Login'))
const Car = retryLazy(() => import('./page/car'))
const Search = retryLazy(() => import('./page/search'))
const Ticket = retryLazy(() => import('./page/ticket'))
const Settings = retryLazy(() => import('./page/settings'))
const Signup = retryLazy(() => import('./page/Signup'))
const MembershipInfo = retryLazy(() => import('./page/MembershipInfo'))
const Home = retryLazy(() => import('./page/Home'))
const Payment = retryLazy(() => import('./page/payment'))
const AuthorityInfo = retryLazy(() => import('./page/AuthorityInfo'))
const DurationTime = retryLazy(() => import('./page/dt'))
class App extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isReady: false,
		}
	}
	componentDidMount() {
		const { dispatch } = this.props

		var loggedItem = localStorage.getItem('loggedInfo')
		if (loggedItem == null) {
			this.setState({
				isReady: true,
			})
			return
		}

		try {
			var user = JSON.parse(loggedItem)
			dispatch(info(user))
		} catch (err) {}
	}
	componentDidUpdate(prevProps) {
		if (this.state.isReady == false) {
			this.setState({
				isReady: true,
			})
		}
	}
	render() {
		const { user } = this.props
		const isLoggedIn = user != null || !this.state.isReady
		return (
			<Layout style={{ minHeight: '100vh', height: 1 }}>
				<Router>
					<HeaderLayout />
					<Suspense
						fallback={
							<div
								style={{
									height: '100%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<Spin />
							</div>
						}
					>
						<Routes>
							<Route
								path='/monitoring'
								element={
									isLoggedIn ? (
										<Monitoring />
									) : (
										<Navigate
											replace
											to={`/login?prevUrl=${
												window.location.pathname + window.location.search
											}`}
										/>
									)
								}
							/>
							<Route
								path='/payment/*'
								element={
									isLoggedIn ? (
										<Payment />
									) : (
										<Navigate
											replace
											to={`/login?prevUrl=${
												window.location.pathname + window.location.search
											}`}
										/>
									)
								}
							/>
							<Route
								path='/car/*'
								element={
									isLoggedIn ? (
										<Car />
									) : (
										<Navigate
											replace
											to={`/login?prevUrl=${
												window.location.pathname + window.location.search
											}`}
										/>
									)
								}
							/>
							<Route
								path='/search/*'
								element={
									isLoggedIn ? (
										<Search />
									) : (
										<Navigate
											replace
											to={`/login?prevUrl=${
												window.location.pathname + window.location.search
											}`}
										/>
									)
								}
							/>
							<Route
								path='/ticket/*'
								element={
									isLoggedIn ? (
										<Ticket />
									) : (
										<Navigate
											replace
											to={`/login?prevUrl=${
												window.location.pathname + window.location.search
											}`}
										/>
									)
								}
							/>
							<Route
								path='/settings/*'
								element={
									isLoggedIn ? (
										<Settings />
									) : (
										<Navigate
											replace
											to={`/login?prevUrl=${
												window.location.pathname + window.location.search
											}`}
										/>
									)
								}
							/>

							<Route
								path='/dt/*'
								element={
									isLoggedIn ? (
										<DurationTime />
									) : (
										<Navigate
											replace
											to={`/login?prevUrl=${
												window.location.pathname + window.location.search
											}`}
										/>
									)
								}
							/>
							<Route path='/' element={<Home />} />
							<Route path='/login' element={<Login />} />
							<Route path='/membership/' element={<MembershipInfo />} />
							<Route path='/authority/' element={<AuthorityInfo />} />
							<Route path='/signup/' element={<Signup />} />
						</Routes>
					</Suspense>
				</Router>
			</Layout>
		)
	}
}

function mapStateToProps(state) {
	return { user: state.user.user }
}

export default connect(mapStateToProps)(App)
