import { GET_TYPES } from '../action/type.js'

const REQUEST = '_REQUEST',
	SUCCESS = '_SUCCESS',
	FAILURE = '_FAILURE'

const defaultState = {
	types: []
}

const typeReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_TYPES + SUCCESS:
			return {
				...state,
				types: action.result.data
			}
		default: {
			return state
		}
	}
}

export default typeReducer
