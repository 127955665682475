import Config from '../config'

export const GET_DISCOUNTS = 'GET_DISCOUNTS'
export const ADD_DISCOUNT = 'ADD_DISCOUNT'
export const UPDATE_DISCOUNT = 'UPDATE_DISCOUNT'
export const DELETE_DISCOUNT = 'DELETE_DISCOUNT'

export const getDiscounts = () => {
	return {
		type: GET_DISCOUNTS,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/payment/getDiscounts`
		}
	}
}

export const addDiscount = item => {
	return {
		type: ADD_DISCOUNT,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/payment/addDiscount`,
			data: item
		}
	}
}

export const updateDiscount = item => {
	return {
		type: UPDATE_DISCOUNT,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/payment/updateDiscount`,
			data: item
		}
	}
}

export const deleteDiscount = id => {
	return {
		type: DELETE_DISCOUNT,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/payment/deleteDiscount`,
			data: {
				id
			}
		}
	}
}
