import {
	GET_SETTING,
	UPDATE_SETTING,
	GET_VISITS,
	ADD_VISIT,
	DELETE_VISIT,
	DELETE_VISITS,
	GET_VISITS_COUNT,
	GET_MONTHLY_STATISTICS,
	GET_MONTHLY_STATISTICS_COUNT,
	GET_MONTHLY_STATISTICS_ALL,
	UPDATE_MONTHLY_STATISTICS,
	UPDATE_MONTHLY_STATISTICS_ALL,
	DELETE_MONTHLY_STATISTICS,
} from '../action/dt.js'

const REQUEST = '_REQUEST',
	SUCCESS = '_SUCCESS',
	FAILURE = '_FAILURE'

const defaultState = {
	fetchingUpdate: false,
	modifingUpdate: false,
	setting: null,
	visits: [],
	totalVisits: 0,
	statistics: [],
	totalStatistics: 0,
	statisticsAll: [],
}

const dtReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_SETTING + REQUEST:
		case UPDATE_SETTING + REQUEST:
		case GET_VISITS + REQUEST:
		case GET_MONTHLY_STATISTICS + REQUEST:
			return {
				...state,
				fetchingUpdate: true,
			}
		case GET_SETTING + SUCCESS:
			return {
				...state,
				fetchingUpdate: false,
				setting: action.result.data,
			}
		case GET_SETTING + FAILURE:
			return {
				...state,
				fetchingUpdate: false,
				errorMessage: action.error.response,
			}
		case UPDATE_SETTING + SUCCESS:
			return {
				...state,
				setting: action.payload.setting,
				fetchingUpdate: false,
			}
		case UPDATE_SETTING + FAILURE:
			return {
				...state,
				fetchingUpdate: false,
				errorMessage: action.error.response,
			}
		case GET_VISITS + SUCCESS:
			return {
				...state,
				visits: action.result.data,
				fetchingUpdate: false,
			}
		case GET_VISITS + FAILURE:
			return {
				...state,
				fetchingUpdate: false,
				errorMessage: action.error.response,
			}
		case GET_MONTHLY_STATISTICS + SUCCESS:
			return {
				...state,
				statistics: action.result.data,
				fetchingUpdate: false,
			}
		case GET_MONTHLY_STATISTICS + FAILURE:
			return {
				...state,
				fetchingUpdate: false,
				errorMessage: action.error.response,
			}

		case ADD_VISIT + REQUEST:
		case DELETE_VISIT + REQUEST:
		case DELETE_VISITS + REQUEST:
		case UPDATE_MONTHLY_STATISTICS + REQUEST:
		case UPDATE_MONTHLY_STATISTICS_ALL + REQUEST:
		case DELETE_MONTHLY_STATISTICS + REQUEST:
			return {
				...state,
				modifingUpdate: true,
			}
		case ADD_VISIT + SUCCESS:
		case DELETE_VISIT + SUCCESS:
		case DELETE_MONTHLY_STATISTICS + SUCCESS:
		case DELETE_VISITS + SUCCESS:
			return {
				...state,
				modifingUpdate: false,
				errorMessage: null,
			}
		case ADD_VISIT + FAILURE:
		case DELETE_VISIT + FAILURE:
		case DELETE_MONTHLY_STATISTICS + FAILURE:
		case DELETE_VISITS + FAILURE:
			return {
				...state,
				modifingUpdate: false,
				errorMessage: action.error.response,
			}
		case GET_VISITS_COUNT + SUCCESS:
			return {
				...state,
				totalVisits: action.result.data.count,
			}
		case GET_MONTHLY_STATISTICS_COUNT + SUCCESS:
			return {
				...state,
				totalStatistics: action.result.data.count,
			}
		case UPDATE_MONTHLY_STATISTICS + SUCCESS:
		case UPDATE_MONTHLY_STATISTICS_ALL + SUCCESS:
			return {
				...state,
				modifingUpdate: false,
				errorMessage: null,
			}
		case UPDATE_MONTHLY_STATISTICS + FAILURE:
		case UPDATE_MONTHLY_STATISTICS_ALL + FAILURE:
			return {
				...state,
				modifingUpdate: false,
				errorMessage: action.error.response,
			}

		case GET_MONTHLY_STATISTICS_ALL + SUCCESS:
			return {
				...state,
				statisticsAll: action.result.data,
			}
		default: {
			return state
		}
	}
}

export default dtReducer
