import React from 'react'
import { Modal, Input, DatePicker } from 'antd'
import { Form } from '@ant-design/compatible'
import locale from 'antd/es/date-picker/locale/ko_KR'
import moment from 'moment'

class EditCarModal extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			visible: false,
			loading: false,
			isEditing: false,
			data: null,
		}
	}

	show({ data, onOk, onCancel, isEditing }) {
		const { setFieldsValue } = this.props.form
		this.setState({
			visible: true,
			data,
			onOk,
			onCancel,
			isEditing,
		})
		if (data != null) {
			data.start = data.start != null ? moment(data.start) : null
			data.expire = data.expire != null ? moment(data.expire) : null
		}
		setFieldsValue(data)
	}
	hide() {
		const { resetFields } = this.props.form
		this.setState({
			visible: false,
			data: null,
			onOk: null,
			onCancel: null,
			isEditing: false,
		})

		resetFields()
	}

	render() {
		const formItemLayout = {
			labelCol: {
				xs: { span: 24 },
				sm: { span: 8 },
			},
			wrapperCol: {
				xs: { span: 24 },
				sm: { span: 16 },
			},
		}
		const { getFieldDecorator } = this.props.form
		// const { visible, onOk, onCancel, data, loading } = this.props
		const { visible, onOk, onCancel, loading, isEditing } = this.state
		return (
			<Modal
				title={!isEditing ? '차량 등록' : '차량 정보 수정'}
				centered
				open={visible}
				onOk={onOk}
				onCancel={onCancel}
				cancelText='취소'
				okText={!isEditing ? '등록' : '수정'}
				okButtonProps={{ loading: loading }}
			>
				<Form {...formItemLayout} layout='vertical'>
					<Form.Item label='차량번호'>
						{getFieldDecorator('car', {
							rules: [
								{
									required: true,
									message: '차량번호를 입력하셔야 합니다.',
								},
							],
						})(
							<Input
							// disabled={isEditing}
							/>
						)}
					</Form.Item>
					<Form.Item label='이름'>
						{getFieldDecorator('name')(<Input />)}
					</Form.Item>
					<Form.Item label='연락처'>
						{getFieldDecorator('phone')(<Input />)}
					</Form.Item>
					<Form.Item label='동'>
						{getFieldDecorator('dong')(<Input />)}
					</Form.Item>
					<Form.Item label='호'>{getFieldDecorator('ho')(<Input />)}</Form.Item>
					<Form.Item label='비고'>
						{getFieldDecorator('memo')(<Input />)}
					</Form.Item>
					<Form.Item label='유효기간 시작일' help='미입력시 제한 없음'>
						{getFieldDecorator('start')(
							<DatePicker style={{ width: '100%' }} locale={locale} />
						)}
					</Form.Item>
					<Form.Item label='유효기간 만료일' help='미입력시 제한 없음'>
						{getFieldDecorator('expire')(
							<DatePicker style={{ width: '100%' }} locale={locale} />
						)}
					</Form.Item>
				</Form>
			</Modal>
		)
	}
}

export default Form.create()(EditCarModal)
