import React from 'react'
import {
	Modal,
	Input,
	Button,
	DatePicker,
	TimePicker,
	Empty,
	Select,
	List,
	Divider,
	Spin,
} from 'antd'
import Config from '../config'
import moment from 'moment'
import axios from 'axios'
import { saveAs } from 'file-saver'
import { DownloadOutlined, DeleteOutlined } from '@ant-design/icons'
import locale from 'antd/es/date-picker/locale/ko_KR'
import 'moment/locale/ko'
import Ticket from '../page/ticket/IssueByAdmin'
import { Link } from 'react-router-dom'
const { confirm } = Modal
const { Option } = Select
const { TextArea } = Input

class TicketModal extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			visible: false,
			onOk: null,
			onCancel: null,
			item: {},
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			this.state.visible == false &&
			nextState.visible == false &&
			this.state.item === nextState.item
		) {
			return false
		}
		return true
	}
	show({ carplate, comment, tickets, onOk }) {
		this.setState({
			item: {
				car: carplate,
				comment,
				selectedTicket:
					tickets != null && tickets.length > 0 ? tickets[0]._id : null,
			},
			tickets,
			visible: true,
			onOk,
		})
	}

	isVisible() {
		return this.state.visible
	}

	hide() {
		clearInterval(this.interval)
		this.setState({
			visible: false,
			item: {},
			onOk: null,
			onCancel: null,
		})
	}

	render() {
		const { loading } = this.props
		const { visible, onOk, onCancel, item, tickets } = this.state

		return (
			<Modal
				title={'임시 방문증 발급(관리자)'}
				centered
				open={visible}
				onOk={onOk}
				onCancel={() => {
					this.setState({
						visible: false,
					})
					if (onCancel) {
						onCancel()
					}
				}}
				footer={[
					<Button
						key='cancel'
						onClick={() => {
							this.setState({
								visible: false,
							})
							if (onCancel) {
								onCancel()
							}
						}}
					>
						취소
					</Button>,
					<Button
						key='edit'
						onClick={() => {
							if (onOk) {
								onOk(item)
							}
						}}
						type='primary'
					>
						등록
					</Button>,
				]}
			>
				{/* <Ticket carplate={carplate} /> */}
				<div style={{ marginTop: 16 }}>
					<label>차량번호</label>
					<Input
						placeholder={'차량번호'}
						value={item.car != null ? item.car : ''}
						onChange={(e) => {
							var item = { ...this.state.item }
							item.car = e.target.value
							this.setState({
								item,
							})
						}}
					/>
				</div>
				<div style={{ marginTop: 16 }}>
					<label>방문증 선택</label>
					<Select
						style={{ width: '100%' }}
						onChange={(e) => {
							var item = { ...this.state.item }
							item.selectedTicket = e
							this.setState({
								item,
							})
						}}
						value={item.selectedTicket}
						notFoundContent={
							<p style={{ padding: 16 }}>
								먼저 방문증 설정에서 방문증을 추가해주세요.{'   '}
								<Link to='/ticket/setting'>[바로가기]</Link>
							</p>
						}
					>
						{tickets != null &&
							tickets.map((obj) => (
								<Option key={obj._id} value={obj._id}>
									{`${obj.name}(${obj.duration}시간)`}
								</Option>
							))}
					</Select>
				</div>
				<div style={{ marginTop: 16 }}>
					<label>참고사항</label>
					<TextArea
						rows={3}
						placeholder={'방문 목적이나 연락처, 동/호수 등을 입력하세요.'}
						value={item != null ? item.comment : ''}
						onChange={(e) => {
							var item = { ...this.state.item }
							item.comment = e.target.value
							this.setState({
								item,
							})
						}}
					/>
				</div>
			</Modal>
		)
	}
}

export default TicketModal
