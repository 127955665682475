import Config from '../config'

export const GET_MANAGERS = 'GET_MANAGERS'
export const ADD_MANAGER = 'ADD_MANAGER'
export const DELETE_MANAGER = 'DELETE_MANAGER'

export const getManagers = (parkingId) => {
	return {
		type: GET_MANAGERS,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/manager/getManagers`,
			params: {
				parking_id: parkingId,
			},
		},
	}
}

export const addManager = (parkingId, id, password, name, role_type, role) => {
	return {
		type: ADD_MANAGER,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/manager/addManager',
			data: {
				parking_id: parkingId,
				id,
				password,
				name,
				role_type,
			},
		},
		payload: {
			id,
			name,
			role,
		},
	}
}

export const deleteManager = (parkingId, managerId) => {
	return {
		type: DELETE_MANAGER,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/manager/deleteManager',
			data: {
				parking_id: parkingId,
				manager_id: managerId,
			},
		},
		payload: {
			managerId,
		},
	}
}
