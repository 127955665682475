import {
	GET_CORRECT_INFO,
	ADD_CORRECT_INFO,
	DELETE_CORRECT_INFO
} from '../action/correctInfo'

const REQUEST = '_REQUEST',
	SUCCESS = '_SUCCESS',
	FAILURE = '_FAILURE'

const defaultState = {
	correctInfo: [],
	updating: false,
	errorMessage: null
}

const correctInfoReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_CORRECT_INFO + SUCCESS:
			return {
				...state,
				correctInfo: action.result.data
			}
		case ADD_CORRECT_INFO + REQUEST:
			return {
				...state,
				updating: true
			}
		case ADD_CORRECT_INFO + SUCCESS:
			return {
				...state,
				correctInfo: [
					...state.correctInfo,
					{
						mistakeCarplate: action.payload.mistakeCarplate,
						originCarplate: action.payload.originCarplate
					}
				],
				errorMessage: null,
				updating: false
			}
		case ADD_CORRECT_INFO + FAILURE:
			return {
				...state,
				errorMessage: action.error.response,
				updating: false
			}
		case DELETE_CORRECT_INFO + REQUEST:
			return {
				...state,
				updating: true
			}
		case DELETE_CORRECT_INFO + SUCCESS:
			return {
				...state,
				correctInfo: state.correctInfo.filter(
					x => x.mistakeCarplate != action.payload.mistakeCarplate
				),
				errorMessage: null,
				updating: false
			}
		case DELETE_CORRECT_INFO + FAILURE:
			return {
				...state,
				errorMessage: action.error.response,
				updating: false
			}
		default: {
			return state
		}
	}
}

export default correctInfoReducer
