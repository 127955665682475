import React from 'react'
import moment from 'moment'
const { Tooltip, Popover, Popconfirm, Button, Divider } = require('antd')

export const renderName = (
	record,
	{ privacyMode = false, layoutSetting = { replaceName: false } }
) => {
	var content = null
	var color = 'black'
	var title = ''
	var tag = ''
	if (record.isBlacklist) {
		color = 'red'
		title = '블랙리스트'
		tag = title
		content = (
			<div>
				<p style={{ whiteSpace: 'pre-line' }}>{record.blacklistComment}</p>
				<div style={{ textAlign: 'right' }}>
					{moment(record.blacklistDate).format('YYYY-MM-DD')}
				</div>
				{record.comment != null && <div>메모: {record.comment}</div>}
			</div>
		)
	} else if (record.isRegistered && record.accessType === 8) {
		color = 'tomato'
		title = '권한불일치'
		tag = record.name == null || record.name === '' ? '미입력' : record.name

		tag = privacyMode ? '**' : tag
		content = (
			<div>
				<div>이름: {record.name}</div>
				<div>동: {record.dong}</div>
				<div>호: {record.ho}</div>
				<div>연락처: {record.phone}</div>
				{record.comment != null && <div>메모: {record.comment}</div>}
			</div>
		)
	} else if (record.accessType === 10) {
		color = 'tomato'
		title = '유효기간 만료'
		tag = title
		content = (
			<div>
				<div>이름: {record.name}</div>
				<div>동: {record.dong}</div>
				<div>호: {record.ho}</div>
				<div>연락처: {record.phone}</div>
				{record.comment != null && <div>메모: {record.comment}</div>}
				<div>
					유효기간:{' '}
					{record.start != null
						? moment(record.start).format('YYYY-MM-DD')
						: '*'}{' '}
					-{' '}
					{record.expire != null
						? moment(record.expire).format('YYYY-MM-DD')
						: '*'}
				</div>
			</div>
		)
	} else if (record.isRegistered) {
		color = '#108ee9'
		title = '등록차량'
		tag = layoutSetting.replaceName
			? '등록차량'
			: record.name == null || record.name === ''
			? '미입력'
			: record.name

		tag = privacyMode ? '**' : tag
		content = (
			<div>
				<div>이름: {record.name}</div>
				<div>동: {record.dong}</div>
				<div>호: {record.ho}</div>
				<div>연락처: {record.phone}</div>
				{record.comment != null && <div>메모: {record.comment}</div>}
				<div>
					유효기간:{' '}
					{record.start != null
						? moment(record.start).format('YYYY-MM-DD')
						: '*'}{' '}
					-{' '}
					{record.expire != null
						? moment(record.expire).format('YYYY-MM-DD')
						: '*'}
				</div>
			</div>
		)
	} else if (record.isTempRegistered) {
		color = 'blue'
		title = '임시방문증'
		tag = title
		content = (
			<div>{record.comment != null && <div>메모: {record.comment}</div>}</div>
		)
	} else if (record.carplate !== '미인식') {
		if (/[0-9]{2}[아바사자배][0-9]{4}/gi.test(record.carplate)) {
			color = '#2db7f5'
			title = '영업용 차량'
			tag = title
			content = (
				<div>{record.comment != null && <div>메모: {record.comment}</div>}</div>
			)
		} else if (
			record.carplate.startsWith('998') ||
			record.carplate.startsWith('999')
		) {
			color = '#2db7f5'
			title = '긴급번호판'
			tag = title
			content = (
				<div>{record.comment != null && <div>메모: {record.comment}</div>}</div>
			)
		} else if (record.accessType === 9) {
			color = 'green'
			title = '회차'
			tag = title
			content = (
				<div>{record.comment != null && <div>메모: {record.comment}</div>}</div>
			)
		} else {
			color = 'rgba(0, 0, 0, 0.65)'
			title = '미등록 차량'
			tag = title
			content = (
				<div>{record.comment != null && <div>메모: {record.comment}</div>}</div>
			)
		}
	}
	if (content != null) {
		return (
			<Popover content={content} title={title} trigger='hover'>
				<p style={{ color: color, fontSize: 16 }}>{tag}</p>
			</Popover>
		)
	}
}

export const renderFunctions = (props, isMobile = false) => {
	const { types, functions, onChangeFunction, onClickFunction } = props

	const stateFunctions = types
		.filter((x) => x.category === 'LPR_FUNCTION_STATE')
		.reduce((acc, item, i) => {
			if (
				functions == null ||
				functions.findIndex((x) => x.functionId === item._id) === -1
			) {
				return acc
			}
			acc[item._id] = item
			return acc
		}, {})

	const controlFunctions = types
		.filter((x) => x.category === 'LPR_FUNCTION_CONTROL')
		.reduce((acc, item, i) => {
			if (
				functions == null ||
				functions.findIndex((x) => x.functionId === item._id) === -1
			) {
				return acc
			}
			acc[item._id] = item
			return acc
		}, {})
	return (
		<div>
			{Object.keys(stateFunctions)
				// .sort((x, y) => x._id - y._id)
				.map((key, i) => {
					var item = stateFunctions[key]
					if (item == null) {
						return null
					}
					var splitted = item.name.split('|')
					if (splitted.length < 2) {
						return null
					}
					if (key === '8003' && item.enabled) {
						return (
							<Popconfirm
								key={'popconfirm-' + key}
								okType='danger'
								title={`등록차량 자동통과를 끄면 등록된 차량도 출입구를 통과할 수 없습니다. 계속하시겠습니까?`}
								okText='끄기'
								cancelText='취소'
								onConfirm={() => {
									if (stateFunctions[key] == null) {
										return
									}
									var index = functions.findIndex((x) => x.functionId == key)
									if (index !== -1) {
										var item = Object.assign({}, functions[index])
										item.enabled = !item.enabled
										if (onChangeFunction) {
											onChangeFunction(item)
										}
									}
								}}
							>
								<Button
									key={'button-' + key}
									type={item.enabled ? 'primary' : 'dashed'}
									style={{ marginLeft: i == 0 ? 0 : 4, padding: '4px 8px' }}
									onClick={() => {}}
								>
									{splitted[0]}
								</Button>
							</Popconfirm>
						)
					}
					return (
						<Button
							key={'button-' + key}
							type={item.enabled ? 'primary' : 'dashed'}
							style={{ marginLeft: i == 0 ? 0 : 4, padding: '4px 8px' }}
							onClick={() => {
								if (stateFunctions[key] == null) {
									return
								}
								var index = functions.findIndex((x) => x.functionId == key)
								if (index !== -1) {
									var item = Object.assign({}, functions[index])
									item.enabled = !item.enabled
									if (onChangeFunction) {
										onChangeFunction(item)
									}
								}
							}}
						>
							{splitted[0]}
						</Button>
					)
				})}

			{Object.keys(stateFunctions).length !== 0 &&
				Object.keys(controlFunctions).length !== 0 &&
				(isMobile ? (
					<div style={{ height: 8 }} />
				) : (
					<Divider type='vertical' />
				))}
			{Object.keys(controlFunctions)
				// .sort((x, y) => x._id - y._id)
				.map((key, i) => {
					var item = controlFunctions[key]
					if (item == null) {
						return null
					}
					if (key === '8107') {
						return (
							<Popconfirm
								key={'popconfirm-' + key}
								okType='danger'
								title={`검지기 전원이 리셋되며 오작동이 발생할 수 있습니다. 계속하시겠습니까?`}
								okText='리셋'
								cancelText='취소'
								onConfirm={() => {
									if (onClickFunction) {
										onClickFunction(item)
									}
								}}
							>
								<Button
									key={'button-' + key}
									danger
									style={{ marginLeft: i == 0 ? 0 : 4, padding: '4px 8px' }}
									onClick={() => {}}
								>
									{item.name}
								</Button>
							</Popconfirm>
						)
					}
					return (
						<Button
							key={'button-' + key}
							danger
							style={{ marginLeft: i == 0 ? 0 : 4, padding: '4px 8px' }}
							onClick={() => {
								if (onClickFunction) {
									onClickFunction(item)
								}
							}}
						>
							{item.name}
						</Button>
					)
				})}
		</div>
	)
}
