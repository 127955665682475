import Config from '../config'

export const GET_TICKETS = 'GET_TICKETS'
export const GET_ISSUED_TICKETS = 'GET_ISSUED_TICKETS'
export const GET_ISSUED_TICKETS_COUNT = 'GET_ISSUED_TICKETS_COUNT'
export const CANCEL_TICKET = 'CANCEL_TICKET'
export const DELETE_TICKET = 'DELETE_TICKET'
export const ISSUE_TICKET = 'ISSUE_TICKET'

export const GET_TICKET_SETTING = 'GET_TICKET_SETTING'
export const SET_TICKET_SETTING = 'SET_TICKET_SETTING'
export const GET_TICKET_INFOS = 'GET_TICKET_INFOS'
export const ADD_TICKET_INFO = 'ADD_TICKET_INFO'
export const UPDATE_TICKET_INFO = 'UPDATE_TICKET_INFO'
export const DELETE_TICKET_INFO = 'DELETE_TICKET_INFO'

export const GET_TICKET_STATISTICS = 'GET_TICKET_STATISTICS'

export const ADD_TICKET_BLACKLIST = 'ADD_TICKET_BLACKLIST'
export const GET_TICKET_BLACKLIST = 'GET_TICKET_BLACKLIST'
export const DELETE_TICKET_BLACKLIST = 'DELETE_TICKET_BLACKLIST'
export const GET_TICKET_BLACKLIST_COUNT = 'GET_TICKET_BLACKLIST_COUNT'

export const getTicketSetting = (parkingId) => {
	return {
		type: GET_TICKET_SETTING,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/ticket/getTicketSetting`,
			params: {
				parking_id: parkingId,
			},
		},
	}
}

export const setTicketSetting = (parkingId, limitPeriod, limitCount) => {
	return {
		type: SET_TICKET_SETTING,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/ticket/setTicketSetting`,
			data: {
				parking_id: parkingId,
				limit_period: limitPeriod,
				limit_count: limitCount,
			},
		},
	}
}

export const getTicketInfos = (parkingId) => {
	return {
		type: GET_TICKET_INFOS,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/ticket/getTicketInfos`,
			params: {
				parking_id: parkingId,
			},
		},
	}
}

export const addTicketInfo = (parkingId, data) => {
	return {
		type: ADD_TICKET_INFO,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/ticket/addTicketInfo`,
			data: { parking_id: parkingId, ...data },
		},
	}
}

export const updateTicketInfo = (parkingId, ticketId, data) => {
	return {
		type: UPDATE_TICKET_INFO,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/ticket/updateTicketInfo`,
			data: { parking_id: parkingId, ticket_id: ticketId, ...data },
		},
	}
}

export const deleteTicketInfo = (parkingId, ticketId) => {
	return {
		type: DELETE_TICKET_INFO,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/ticket/deleteTicketInfo`,
			data: {
				parking_id: parkingId,
				ticket_id: ticketId,
			},
		},
	}
}

export const getTicketsStatistics = ({ parkingId, mode, start, end }) => {
	return {
		type: GET_TICKET_STATISTICS,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/ticket/getIssuedTicketsForStatistics`,
			params: {
				parking_id: parkingId,
				mode,
				start: start.format('YYYY-MM-DD HH:mm:ss.SSS'),
				end: end.format('YYYY-MM-DD HH:mm:ss.SSS'),
			},
		},
	}
}

export const getTickets = ({
	parkingId,
	page = 0,
	limit = 100,
	qDong = '',
	qHo = '',
	qCar = '',
	onlyEnabled = true,
	start,
	end,
}) => {
	return {
		type: GET_ISSUED_TICKETS,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/ticket/getIssuedTickets`,
			params: {
				parking_id: parkingId,
				page,
				limit,
				q_car: qCar,
				q_dong: qDong,
				q_ho: qHo,
				only_enabled: onlyEnabled,
				start: start.format('YYYY-MM-DD HH:mm:ss.SSS'),
				end: end.format('YYYY-MM-DD HH:mm:ss.SSS'),
			},
		},
	}
}

export const getTicketsCount = ({
	parkingId,
	qDong = '',
	qHo = '',
	qCar = '',
	onlyEnabled = true,
	start,
	end,
}) => {
	return {
		type: GET_ISSUED_TICKETS_COUNT,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/ticket/getIssuedTicketsCount`,
			params: {
				parking_id: parkingId,
				q_car: qCar,
				q_dong: qDong,
				q_ho: qHo,
				only_enabled: onlyEnabled,
				start: start.format('YYYY-MM-DD HH:mm:ss.SSS'),
				end: end.format('YYYY-MM-DD HH:mm:ss.SSS'),
			},
		},
	}
}

// export const getTickets = ({
// 	parkingId,
// 	page = 0,
// 	limit = 20,
// 	qDong = '',
// 	qHo = '',
// 	qCar = '',
// 	type = 0,
// }) => {
// 	return {
// 		type: GET_TICKETS,
// 		promise: {
// 			method: 'get',
// 			url:
// 				Config.SERVER_URL +
// 				`/admin/getTickets?parking_id=${parkingId}&page=${page}&limit=${limit}&q_car=${qCar}&q_dong=${qDong}&q_ho=${qHo}&type=${type}`,
// 		},
// 	}
// }

// export const getTicketsCount = ({
// 	parkingId,
// 	qDong = '',
// 	qHo = '',
// 	qCar = '',
// 	type = 0,
// }) => {
// 	return {
// 		type: GET_TICKETS_COUNT,
// 		promise: {
// 			method: 'get',
// 			url:
// 				Config.SERVER_URL +
// 				`/admin/getTicketsCount?parking_id=${parkingId}&q_car=${qCar}&q_dong=${qDong}&q_ho=${qHo}&type=${type}`,
// 		},
// 	}
// }

// export const cancelTicket = ({ parkingId, ticketId }) => {
// 	return {
// 		type: CANCEL_TICKET,
// 		promise: {
// 			method: 'post',
// 			url: Config.SERVER_URL + '/admin/cancelTicket',
// 			data: {
// 				parking_id: parkingId,
// 				ticket_id: ticketId,
// 			},
// 		},
// 	}
// }

export const cancelTicket = ({ parkingId, ticketId }) => {
	return {
		type: CANCEL_TICKET,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/ticket/cancelTicket',
			data: {
				parking_id: parkingId,
				ticket_id: ticketId,
			},
		},
	}
}

export const deleteTicket = ({ parkingId, ticketId }) => {
	return {
		type: DELETE_TICKET,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/ticket/deleteIssuedTicket',
			data: {
				parking_id: parkingId,
				id: ticketId,
			},
		},
	}
}

export const issueTicket = (parkingId, data) => {
	return {
		type: ISSUE_TICKET,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/ticket/issueTicket',
			data: {
				parking_id: parkingId,
				...data,
			},
		},
	}
}

export const getTicketBlacklist = ({ parkingId, page = 0, limit = 100 }) => {
	return {
		type: GET_TICKET_BLACKLIST,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/ticket/getTicketBlacklist`,
			params: {
				parking_id: parkingId,
				page,
				limit,
			},
		},
	}
}

export const getTicketBlacklistCount = ({ parkingId }) => {
	return {
		type: GET_TICKET_BLACKLIST_COUNT,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/ticket/getTicketBlacklistCount`,
			params: {
				parking_id: parkingId,
			},
		},
	}
}

export const addTicketBlacklist = ({ parkingId, dong, ho, carNumber }) => {
	return {
		type: ADD_TICKET_BLACKLIST,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/ticket/addTicketBlacklist`,
			data: {
				parking_id: parkingId,
				dong,
				ho,
				car_number: carNumber,
			},
		},
	}
}

export const deleteTicketBlacklist = ({ parkingId, dong, ho, carNumber }) => {
	return {
		type: DELETE_TICKET_BLACKLIST,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/ticket/deleteTicketBlacklist`,
			data: {
				parking_id: parkingId,
				dong,
				ho,
				car_number: carNumber,
			},
		},
	}
}

// export const issueTicket = ({ parkingId, carNumber, memo, duration }) => {
// 	return {
// 		type: ISSUE_TICKET,
// 		promise: {
// 			method: 'post',
// 			url: Config.SERVER_URL + '/admin/issueByAdmin',
// 			data: {
// 				parking_id: parkingId,
// 				car: carNumber,
// 				memo,
// 				duration,
// 			},
// 		},
// 	}
// }
