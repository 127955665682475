import React from 'react'
import { Modal, InputNumber, Checkbox, Row, Col, Divider } from 'antd'

class SelectLayoutModal extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			visible: false,
			rows: 1,
			cols: 1,
			auto: true,
			simple: true,
			video: false,
			ratio16_9: false,
			showName: true,
			showDong: true,
			showHo: true,
			replaceName: false,
		}
	}

	show(setting) {
		this.setState({
			visible: true,
			rows: setting.rows || 1,
			cols: setting.cols || 1,
			auto: setting.auto == null ? true : setting.auto,
			simple: setting.simple == null ? true : setting.simple,
			video: setting.video == null ? false : setting.video,
			ratio16_9: setting.ratio16_9 == null ? false : setting.ratio16_9,
			showName: setting.showName == null ? true : setting.showName,
			showDong: setting.showDong == null ? true : setting.showDong,
			showHo: setting.showHo == null ? true : setting.showHo,
			replaceName: setting.replaceName == null ? true : setting.replaceName,
		})
	}

	hide() {
		this.setState({
			visible: false,
		})
	}

	onChange = (selected) => {
		this.setState({
			selectedGates: selected,
		})
	}
	render() {
		const {
			visible,
			auto,
			rows,
			cols,
			simple,
			video,
			ratio16_9,
			showName,
			showDong,
			showHo,
			replaceName,
		} = this.state
		const { onOk } = this.props
		return (
			<Modal
				title={'모니터링 설정'}
				centered
				open={visible}
				onOk={onOk}
				onCancel={() => {
					this.setState({
						visible: false,
					})
				}}
				cancelText='취소'
				okText={'적용'}
			>
				<Row style={{ marginBottom: 16 }}>
					<Col>
						<Checkbox
							checked={auto}
							onChange={(e) => {
								this.setState({
									auto: e.target.checked,
								})
							}}
						>
							자동
						</Checkbox>
					</Col>
				</Row>
				<Row style={{ marginBottom: 16 }}>
					<Col md={12}>
						가로:{' '}
						<InputNumber
							defaultValue={1}
							value={rows}
							disabled={auto}
							onChange={(value) => {
								this.setState({
									rows: value,
								})
							}}
						/>
					</Col>
					<Col md={12}>
						세로:{' '}
						<InputNumber
							defaultValue={1}
							value={cols}
							disabled={auto}
							onChange={(value) => {
								this.setState({
									cols: value,
								})
							}}
						/>
					</Col>
				</Row>
				<Row style={{ marginBottom: 16 }}>
					<Col>
						<Checkbox
							checked={simple}
							onChange={(e) => {
								this.setState({
									simple: e.target.checked,
								})
							}}
						>
							간편보기(사진/영상 전환) 사용
						</Checkbox>
					</Col>
				</Row>

				<Row style={{ marginBottom: 16 }}>
					<Col>
						<Checkbox
							checked={video}
							onChange={(e) => {
								this.setState({
									video: e.target.checked,
								})
							}}
						>
							비디오 전용 모드 사용
						</Checkbox>
					</Col>
				</Row>

				<Divider />
				<Row style={{ marginBottom: 16 }}>
					<Col>
						<Checkbox
							checked={ratio16_9}
							onChange={(e) => {
								this.setState({
									ratio16_9: e.target.checked,
								})
							}}
						>
							16:9 비율 사용
						</Checkbox>
					</Col>
				</Row>

				<Row style={{ marginBottom: 16 }}>
					<Col>
						<Checkbox
							checked={showName}
							onChange={(e) => {
								this.setState({
									showName: e.target.checked,
								})
							}}
						>
							이름 표시
						</Checkbox>
					</Col>
				</Row>

				<Row style={{ marginBottom: 16 }}>
					<Col>
						<Checkbox
							checked={showDong}
							onChange={(e) => {
								this.setState({
									showDong: e.target.checked,
								})
							}}
						>
							동 표시
						</Checkbox>
					</Col>
				</Row>

				<Row style={{ marginBottom: 16 }}>
					<Col>
						<Checkbox
							checked={showHo}
							onChange={(e) => {
								this.setState({
									showHo: e.target.checked,
								})
							}}
						>
							호 표시
						</Checkbox>
					</Col>
				</Row>
				<Row style={{ marginBottom: 16 }}>
					<Col>
						<Checkbox
							checked={replaceName}
							onChange={(e) => {
								this.setState({
									replaceName: e.target.checked,
								})
							}}
						>
							등록차량 이름 가리기
						</Checkbox>
					</Col>
				</Row>

				{/* <Row style={{ marginBottom: 16 }}>
					<Col>
						<Checkbox
							checked={detail}
							onChange={(e) => {
								this.setState({
									detail: e.target.checked,
								})
							}}
						>
							상세정보 표시
						</Checkbox>
					</Col>
				</Row> */}
			</Modal>
		)
	}
}

export default SelectLayoutModal
