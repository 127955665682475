import {
	GET_ACCESS,
	GET_ACCESSES,
	GET_ACCESSES_SEARCH,
	GET_ACCESSES_STATISTICS,
	GET_ACCESSES_STATISTICS_HOURLY,
	RECEIVE_ACCESS,
	RECEIVE_DELETE_ACCESS,
	RECEIVE_UPDATE_ACCESS,
	ADD_ACCESS,
	UPDATE_ACCESS,
	DELETE_ACCESS,
	DELETE_ACCESSES,
	GET_ACCESSES_COUNT,
	GET_ACCESSES_NO_LIMIT,
	GET_MEMOS,
	CLEAR_MEMOS,
	CLEAR_SINGLE_ACCESS,
} from '../action/access.js'

import moment from 'moment'

const REQUEST = '_REQUEST',
	SUCCESS = '_SUCCESS',
	FAILURE = '_FAILURE'

const defaultState = {
	fetchingUpdate: false,
	updating: false,
	accesses: {},
	searchAccesses: [],
	total: 0,
	errorMessage: null,
	updatedItem: null,
	//for export excel file
	allAccesses: null,
	downloadingAllAccesses: false,
	memos: null,
	singleAccess: null,
}

const accessReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_ACCESS + REQUEST:
			return {
				...state,
				singleAccess: null,
			}
		case GET_ACCESSES + REQUEST:
			return {
				...state,
				fetchingUpdate: true,
				errorMessage: null,
			}
		case GET_ACCESSES_SEARCH + REQUEST:
		case GET_ACCESSES_STATISTICS + REQUEST:
		case GET_ACCESSES_STATISTICS_HOURLY + REQUEST:
			return {
				...state,
				accesses: {},
				fetchingUpdate: true,
				errorMessage: null,
			}
		case GET_ACCESSES + SUCCESS:
			return {
				...state,
				fetchingUpdate: false,
				accesses: {
					...state.accesses,
					[action.payload.gateId]: action.payload.loadMore
						? [...state.accesses[action.payload.gateId], ...action.result.data]
						: action.result.data,
				},
				errorMessage: null,
			}
		case GET_ACCESSES + FAILURE:
		case GET_ACCESSES_SEARCH + FAILURE:
		case GET_ACCESSES_STATISTICS + FAILURE:
		case GET_ACCESSES_STATISTICS_HOURLY + FAILURE:
			return {
				...state,
				fetchingUpdate: false,
				errorMessage: action.error.response,
			}
		case GET_ACCESSES_SEARCH + SUCCESS:
		case GET_ACCESSES_STATISTICS + SUCCESS:
		case GET_ACCESSES_STATISTICS_HOURLY + SUCCESS:
			return {
				...state,
				fetchingUpdate: false,
				// accesses: action.result.data.reduce((acc, item) => {
				// 	if (acc[item.TBL_GATE_id] == null) {
				// 		acc[item.TBL_GATE_id] = []
				// 	}
				// 	acc[item.TBL_GATE_id].push(item)
				// 	return acc
				// }, {}),
				accesses: {
					[action.payload.gateId]: action.result.data,
				},
				errorMessage: null,
			}
		case RECEIVE_ACCESS:
			var gateId = action.payload.gateId.toUpperCase()

			// if (state.accesses[gateId] == null) {
			//   return state
			// }
			return {
				...state,
				accesses: {
					...state.accesses,
					[gateId]: (state.accesses[gateId] != null
						? [action.payload.item, ...state.accesses[gateId]]
						: [action.payload.item]
					).splice(0, 100),
				},
			}
		case RECEIVE_DELETE_ACCESS:
			var gateId = action.payload.gateId.toUpperCase()
			var accesses = { ...state.accesses }
			var tmp = accesses[gateId].filter((item) => {
				return item._id != action.payload.item._id
			})
			accesses[gateId] = tmp
			return {
				...state,
				accesses: accesses,
			}
		case RECEIVE_UPDATE_ACCESS:
			var gateId = action.payload.gateId.toUpperCase()
			var accesses = { ...state.accesses }
			var index =
				accesses[gateId] == null
					? -1
					: accesses[gateId].findIndex((item) => {
							return action.payload.item._id == item._id
					  })
			if (index != -1) {
				var tmp = [...accesses[gateId]]
				tmp[index] = action.payload.item
				accesses[gateId] = tmp
			}
			return {
				...state,
				accesses: accesses,
			}
		case ADD_ACCESS + REQUEST:
		case UPDATE_ACCESS + REQUEST:
		case DELETE_ACCESS + REQUEST:
		case DELETE_ACCESSES + REQUEST:
			return { ...state, updating: true }

		case GET_ACCESSES_COUNT + SUCCESS:
			return {
				...state,
				total: action.result.data.count,
			}
		case ADD_ACCESS + SUCCESS:
			var accesses = { ...state.accesses }
			if (accesses[action.payload.access.gateId] == null) {
				accesses[action.payload.access.gateId] = []
			}
			accesses[action.payload.access.gateId] = [
				action.payload.access,
				...accesses[action.payload.access.gateId],
			].sort((x, y) => moment(y.accessTime) - moment(x.accessTime))
			action.payload.access._id = action.result.data.access_id
			return {
				...state,
				updating: false,
				accesses,
				updatedItem: action.payload.access,
			}

		case UPDATE_ACCESS + SUCCESS:
			var accesses = { ...state.accesses }
			if (accesses[action.payload.access.gateId] == null) {
				accesses[action.payload.access.gateId] = []
			}
			var index = accesses[action.payload.access.gateId].findIndex((item) => {
				return action.payload.access._id == item._id
			})
			if (index != -1) {
				accesses[action.payload.access.gateId][index] = action.payload.access
			}

			return {
				...state,
				updating: false,
				accesses,
				updatedItem: accesses[action.payload.access.gateId][index],
			}
		case DELETE_ACCESS + SUCCESS:
			var accesses = { ...state.accesses }
			var tmp = accesses[action.payload.gateId].filter((item) => {
				return item._id != action.payload.id
			})
			accesses[action.payload.gateId] = tmp

			return { ...state, updating: false, accesses }

		case GET_ACCESS + SUCCESS:
			var accesses = { ...state.accesses }
			if (accesses[action.result.data.gateId] != null) {
				var index = accesses[action.result.data.gateId].findIndex((item) => {
					return action.result.data._id == item._id
				})
				// accesses[action.result.data.gateId][index] = action.result.data
				var tmp = [...accesses[action.result.data.gateId]]
				tmp[index] = action.result.data
				accesses[action.result.data.gateId] = tmp
			}

			return {
				...state,
				accesses,
				singleAccess: action.result.data,
			}
		case ADD_ACCESS + FAILURE:
		case UPDATE_ACCESS + FAILURE:
		case DELETE_ACCESS + FAILURE:
		case DELETE_ACCESSES + FAILURE:
		case DELETE_ACCESSES + SUCCESS:
			return {
				...state,
				updating: false,
			}
		case GET_ACCESSES_NO_LIMIT + REQUEST:
			return {
				...state,
				downloadingAllAccesses: true,
				allAccesses: null,
			}
		case GET_ACCESSES_NO_LIMIT + SUCCESS:
			return {
				...state,
				downloadingAllAccesses: false,
				allAccesses: action.result.data,
			}
		case GET_ACCESSES_NO_LIMIT + FAILURE:
			return {
				...state,
				downloadingAllAccesses: false,
				errorMessage: [...state.errorMessage, action.error.response],
			}
		case GET_MEMOS + REQUEST:
			return {
				...state,
				memos: null,
			}
		case GET_MEMOS + SUCCESS:
			return {
				...state,
				memos: action.result.data,
			}
		case GET_MEMOS + FAILURE:
			return {
				...state,
				memos: null,
				errorMessage: action.error.response,
			}
		case CLEAR_MEMOS:
			return {
				...state,
				memos: [],
			}
		case CLEAR_SINGLE_ACCESS:
			return {
				...state,
				singleAccess: null,
			}
		default: {
			return state
		}
	}
}

export default accessReducer
