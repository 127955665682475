import React from 'react'
import {
	Card,
	Button,
	Table,
	Row,
	Empty,
	Spin,
	Popover,
	Badge,
	Divider,
	Space,
	Tooltip,
	Popconfirm,
} from 'antd'
import { connect } from 'react-redux'
import moment from 'moment'
import axios from 'axios'

import { PlusOutlined, MoreOutlined } from '@ant-design/icons'

import { getImageUrl } from '../utils'

import { JSMpegVideoPlayer } from '.'
import { renderFunctions, renderName } from './utils'

const CancelToken = axios.CancelToken

class MonitoringGate extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			selected: null,
			imgNotFound: false,
			width: 160,
			videoError: false,
			imageIndex: 0,
		}
	}
	cancel = null

	externColumns = [
		{
			title: '동',
			dataIndex: 'dong',
			key: 'dong',
			responsive: ['lg'],
		},
		{
			title: '호',
			dataIndex: 'ho',
			key: 'ho',
			responsive: ['lg'],
		},
	]
	columns = [
		{
			title: '차량번호',
			render: (record) => {
				if (record.carplate === '미인식') {
					return (
						<Badge dot offset={[4, 0]}>
							<p style={{ fontSize: 16 }}>{record.carplate}</p>
						</Badge>
					)
				} else if (!record.isRegistered) {
					return (
						<p style={{ wordBreak: 'keep-all', display: 'flex', fontSize: 16 }}>
							{record.carplate}&nbsp;&nbsp;
							<Button
								size='small'
								shape='circle'
								icon={<PlusOutlined />}
								onClick={() => {
									const { gate, onClickAddCar } = this.props
									if (onClickAddCar) {
										onClickAddCar(gate, record)
									}
								}}
							></Button>
						</p>
					)
				} else {
					return <p style={{ fontSize: 16 }}>{record.carplate}</p>
				}
			},
		},
		{
			title: '이름',
			key: 'name',
			render: (record) => {
				return renderName(record, {
					privacyMode: this.props.privacyMode,
					layoutSetting: this.props.layoutSetting,
				})
			},
		},
		{
			title: '진입시간',
			dataIndex: 'accessTime',
			key: 'accessTime',
			render: (accessTime) => {
				var style = {}
				if (moment(accessTime).get('dayOfYear') != moment().get('dayOfYear')) {
					style = { color: '#aaa' }
				}
				return (
					<Tooltip title={moment(accessTime).format('yyyy-MM-DD')}>
						<div style={style}>{moment(accessTime).format('HH:mm:ss')}</div>
					</Tooltip>
				)
			},
			width: 100,
		},
		{
			title: '수정',
			render: (record) => {
				return (
					<Button
						shape='circle'
						size='small'
						icon={<MoreOutlined />}
						onClick={() => {
							const { gate, onClickEdit } = this.props
							if (onClickEdit) {
								onClickEdit(gate, record)
							}
						}}
					/>
				)
			},
			width: 60,
		},
	]

	componentDidMount() {
		const { accesses, height, layoutSetting } = this.props
		if (accesses != null) {
			this.setState({
				selected: accesses.length !== 0 ? accesses[0] : null,
			})
		}

		if (height != null) {
			this.setState({
				width: layoutSetting.ratio16_9
					? ((height / 2) * 16) / 9
					: ((height / 2) * 4) / 3,
			})
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { accesses, onChangeDetail, gate, height, user, layoutSetting } =
			this.props

		if (accesses != null && accesses !== prevProps.accesses) {
			this.setState({
				selected: accesses.length !== 0 ? accesses[0] : null,
			})
		}
		if (
			(height != null && height !== prevProps.height) ||
			layoutSetting !== prevProps.layoutSetting
		) {
			this.setState({
				width: layoutSetting.ratio16_9
					? ((height / 2) * 16) / 9
					: ((height / 2) * 4) / 3,
			})
		}
		if (
			(prevState.selected !== this.state.selected &&
				this.state.selected != null) ||
			prevState.imageIndex !== this.state.imageIndex
		) {
			if (this.props.detailInfo != null) {
				if (onChangeDetail) {
					onChangeDetail({
						gate: gate,
						access: this.state.selected,
						url: getImageUrl(
							user,
							this.state.selected._id,
							this.state.imageIndex
						),
					})
				}
			}

			if (this.cancel) {
				this.cancel()
			}

			axios
				.get(
					getImageUrl(user, this.state.selected._id, this.state.imageIndex),
					{
						responseType: 'arraybuffer',
						cancelToken: new CancelToken((c) => (this.cancel = c)),
					}
				)
				.then((res) => {
					try {
						var base64 = new Buffer(res.data, 'binary').toString('base64')
						var ctx = this.refs.img.getContext('2d')
						var img = new Image()
						img.onload = () => {
							if (this.refs.img == null) {
								return
							}
							var rect = this.refs.img.getBoundingClientRect()
							this.refs.img.height = rect.height
							this.refs.img.width = rect.width
							if (img && this.refs.img) {
								ctx.drawImage(
									img,
									0,
									0,
									this.refs.img.width,
									this.refs.img.height
								)
								img.src = ''
							}
						}
						img.src = 'data:image/jpeg;base64,' + base64
						this.setState({
							imgNotFound: false,
						})
					} catch (err) {
						this.setState({
							imgNotFound: true,
						})
					}
				})
				.catch((err) => {
					this.setState({
						imgNotFound: true,
					})
				})
		}
	}

	componentWillUnmount() {}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			this.props.accesses === nextProps.accesses &&
			this.props.user === nextProps.user &&
			this.props.height === nextProps.height &&
			this.props.functions === nextProps.functions &&
			this.props.types === nextProps.types &&
			this.props.fullsize === nextProps.fullsize &&
			this.state.selected === nextState.selected &&
			this.state.width === nextState.width &&
			this.state.imgNotFound === nextState.imgNotFound &&
			this.state.videoError === nextState.videoError &&
			this.state.showDropdown === nextState.showDropdown &&
			this.state.imageIndex === nextState.imageIndex &&
			this.props.showLoopDetecting === nextProps.showLoopDetecting &&
			this.props.showOpeningGate === nextProps.showOpeningGate &&
			this.props.layoutSetting === nextProps.layoutSetting
		) {
			return false
		}

		return true
	}

	handleOnClickImage = () => {
		const { selected } = this.state
		const { onChangeDetail, gate, user } = this.props
		if (onChangeDetail && selected) {
			onChangeDetail({
				gate: gate,
				access: selected,
				url: getImageUrl(user, selected._id, this.state.imageIndex),
			})
		}
	}

	handleOnClickVideo = (url) => {
		const { onChangeDetail, gate } = this.props
		if (onChangeDetail) {
			onChangeDetail({
				gate: gate,
				url: url,
			})
		}
	}

	handleOnClickRow = (record) => {
		this.setState({
			selected: record,
		})
	}

	render() {
		const {
			gate,
			height,
			accesses,
			loading,
			cameras,
			onClickLoadMore,
			types,
			functions,
			fullsize,
			showLoopDetecting = false,
			showOpeningGate = false,
			layoutSetting,
		} = this.props
		const { selected, imgNotFound, width, videoError } = this.state

		types
			.filter((x) => x.category === 'LPR_FUNCTION_STATE')
			.map((x, i) => {
				x.index = i
				x.enabled =
					functions != null &&
					functions.findIndex((y) => y.functionId === x._id && y.enabled) !== -1
				return x
			})
			.filter((x) => {
				return (
					functions != null &&
					functions.findIndex((y) => y.functionId === x._id) !== -1
				)
			})

		if (gate == null) {
			return (
				<div
					style={{
						height: height,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Spin />
				</div>
			)
		}

		return (
			<Card
				tabIndex={0}
				onKeyPress={(e) => {}}
				onKeyDown={(e) => {
					if (e.keyCode >= 37 && e.keyCode <= 40) {
						e.preventDefault()
						if (accesses == null) {
							return
						}
						var index = 0
						if (selected == null) {
							index = 0
						} else {
							index = accesses.indexOf(selected)
						}
						switch (e.keyCode) {
							case 37:
							case 38:
								var newIndex = index - 1
								if (newIndex < 0) {
									newIndex = 0
								}
								this.setState({
									selected: accesses[newIndex],
								})
								break
							case 39:
							case 40:
								newIndex = index + 1
								if (newIndex >= accesses.length) {
									newIndex = accesses.length - 1
								}
								this.setState({
									selected: accesses[newIndex],
								})
								break
							default:
								break
						}
					}
				}}
				size='small'
				headStyle={{ fontWeight: 'bold' }}
				style={{ height: height }}
				bordered={true}
				bodyStyle={{ padding: 0 }}
			>
				{loading ? (
					<div
						style={{
							height: height,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Spin />
					</div>
				) : (
					<Row type='flex' style={{ flexWrap: 'nowrap', overflow: 'hidden' }}>
						<div
							style={{
								borderRightWidth: 1,
								borderRightColor: '#eee',
								borderRightStyle: 'solid',
							}}
						>
							<div
								ref='imageContainer'
								style={{
									height: height / 2,
									width: width,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									minWidth: 160,
								}}
							>
								<div
									style={{
										width: width,
										height: '100%',
										textAlign: 'center',
										borderBottomWidth: 1,
										borderBottomColor: '#eee',
										borderBottomStyle: 'solid',
									}}
								>
									<div style={{ position: 'absolute', top: 8, left: 4 }}>
										{cameras.length > 1 &&
											cameras.map((item, index) => {
												return (
													<Button
														type={
															this.state.imageIndex == index
																? 'primary'
																: 'default'
														}
														shape='circle'
														size='small'
														style={{ marginLeft: 4 }}
														onClick={() => {
															this.setState({
																imageIndex: index,
															})
														}}
													>
														{index + 1}
													</Button>
												)
											})}
									</div>
									<canvas
										ref={'img'}
										onClick={this.handleOnClickImage}
										style={{
											height: imgNotFound ? 0 : '100%',
											width: width,
										}}
									/>
									<Empty
										description='No Image'
										image={Empty.PRESENTED_IMAGE_SIMPLE}
										style={{
											visibility: imgNotFound ? 'visible' : 'collapse',
										}}
									/>
								</div>
							</div>
							<div
								style={{
									height: height / 2,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								{videoError || cameras.length == 0 ? (
									<Empty
										description='No Video'
										image={Empty.PRESENTED_IMAGE_SIMPLE}
									/>
								) : cameras.length != 0 &&
								  cameras[this.state.imageIndex].streamingUrl != null &&
								  cameras[this.state.imageIndex].streamingUrl.startsWith(
										'http'
								  ) ? (
									<iframe
										title={cameras[this.state.imageIndex]._id}
										style={{ border: 0 }}
										src={cameras[this.state.imageIndex].streamingUrl}
										scrolling='no'
										height={height / 2}
										width={width}
										allowfullscreen='true'
										webkitallowfullscreen='true'
										mozallowfullscreen='true'
									></iframe>
								) : (
									<JSMpegVideoPlayer
										url={
											cameras.length == 0
												? null
												: cameras[this.state.imageIndex].streamingUrl
										}
										// style={{ height: height / 2, width: width }}
									/>
								)}
							</div>
						</div>
						<div style={{ flex: 1, height: height }}>
							<div
								style={{
									display: 'flex',
									paddingLeft: 8,
									paddingRight: 8,
									flexDirection: 'column',
									height: 80,
									justifyContent: 'center',
								}}
							>
								<Space style={{ marginBottom: 8 }}>
									<h3 style={{ fontWeight: 'bold' }}>{gate.name}</h3>
									{showOpeningGate && (
										<Tooltip title='차단기 열림'>
											<Badge color={'#52c41a'} />
										</Tooltip>
									)}
									{showLoopDetecting && (
										<Tooltip title='루프 검지'>
											<Badge color={'gold'} />
										</Tooltip>
									)}
								</Space>

								{renderFunctions(this.props)}
							</div>
							<div
								style={{
									height: height - 80,
									overflowY: 'scroll',
								}}
							>
								{accesses != null && (
									<Table
										size={'small'}
										columns={(fullsize
											? [
													...this.columns.slice(0, 2),
													...this.externColumns,
													...this.columns.slice(2, 5),
											  ]
											: this.columns
										).filter((col) => {
											if (col.key == 'dong' && !layoutSetting.showDong) {
												return false
											}
											if (col.key == 'ho' && !layoutSetting.showHo) {
												return false
											}
											if (col.key == 'name' && !layoutSetting.showName) {
												return false
											}
											return true
										})}
										dataSource={accesses}
										pagination={false}
										useFixedHeader={true}
										style={{ fontSize: 16 }}
										onRow={(record, rowIndex) => {
											return {
												onClick: () => {
													this.handleOnClickRow(record)
												},
											}
										}}
										rowClassName={(record) =>
											this.state.selected == record ? 'selected-item' : ''
										}
										rowKey={(record) => {
											return record._id
										}}
										footer={() =>
											accesses.length > 0 && (
												<div style={{ textAlign: 'center' }}>
													<Button
														type='link'
														onClick={() => {
															if (onClickLoadMore) {
																onClickLoadMore(
																	gate,
																	accesses[accesses.length - 1].accessTime
																)
															}
														}}
													>
														더 보기
													</Button>
												</div>
											)
										}
									/>
								)}
							</div>
						</div>
					</Row>
				)}
			</Card>
		)
	}
}

function mapStateToProps(state) {
	return { user: state.user.user }
}

export default connect(mapStateToProps)(MonitoringGate)
