import {
	GET_QNALIST,
	GET_QNALIST_COUNT,
	ADD_QNA,
	GET_QNAVIEW,
	GET_COMMENTLIST,
	ADD_COMMENT,
	DELETE_COMMENT,
	DELETE_QNA,
} from '../action/qna.js'

const REQUEST = '_REQUEST',
	SUCCESS = '_SUCCESS',
	FAILURE = '_FAILURE'

const defaultState = {
	totalPage: 0,
	qnaList: [],
	qnaView: {},
	commentList: [],
	isWrite: false,
	isComment: false,
	CommDelete: '',
	resultDelete: '',
	errorMessage: null,
}

const qnaReducer = (state = defaultState, action) => {
	switch (action.type) {
		// SUCCESS
		case GET_QNALIST + SUCCESS:
			return {
				...state,
				qnaList: action.result.data,
			}
		case GET_QNALIST_COUNT + SUCCESS:
			return {
				...state,
				totalPage: action.result.data.count,
			}
		case ADD_QNA + SUCCESS:
			return { ...state, isWrite: true }
		case GET_QNAVIEW + SUCCESS:
			return { ...state, qnaView: action.result.data }
		case GET_COMMENTLIST + SUCCESS:
			return { ...state, commentList: action.result.data }
		case ADD_COMMENT + SUCCESS:
			return { ...state, isComment: true }
		case DELETE_COMMENT + SUCCESS:
			return { ...state, CommDelete: 'success' }
		case DELETE_QNA + SUCCESS:
			return { ...state, resultDelete: 'success' }

		//ERROR
		case ADD_QNA + FAILURE:
			return { ...state, errorMessage: action.error.response }
		case ADD_COMMENT + FAILURE:
			return { ...state, errorMessage: action.error.response }
		case DELETE_COMMENT + FAILURE:
			return { ...state, errorMessage: action.error.response }
		case DELETE_QNA + FAILURE:
			return { ...state, errorMessage: action.error.response }

		// REQUEST
		case GET_QNALIST + REQUEST:
			return { ...state }
		case GET_QNALIST_COUNT + REQUEST:
			return {
				...state,
			}
		// case ADD_QNA + REQUEST:
		// 	return {}
		// case GET_QNAVIEW + REQUEST:
		// 	return {}
		// case GET_COMMENTLIST + REQUEST:
		// 	return {}
		// case ADD_COMMENT + REQUEST:
		// 	return {}
		// case DELETE_COMMENT + REQUEST:
		// 	return {}

		// FAILURE
		// case GET_QNALIST + FAILURE:
		// 	return { ...state }
		// case GET_QNALIST_COUNT + FAILURE:
		// 	return {
		// 		...state
		// 	}
		// case ADD_QNA + FAILURE:
		// 	return {}
		// case GET_QNAVIEW + FAILURE:
		// 	return {}
		// case GET_COMMENTLIST + FAILURE:
		// 	return {}
		// case ADD_COMMENT + FAILURE:
		// 	return {}
		// case DELETE_COMMENT + FAILURE:
		// 	return {}

		default:
			return state
	}
}

export default qnaReducer
