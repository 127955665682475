import {
	GET_UNREGISTERED,
	HANDLE_UNREGISTERED,
	GET_BLACKLIST_SETTING,
	SET_BLACKLIST_SETTING,
} from '../action/unregistered'

const REQUEST = '_REQUEST',
	SUCCESS = '_SUCCESS',
	FAILURE = '_FAILURE'

const defaultState = {
	unregistered: null,
	totalCount: 0,
	errorMessage: null,
	updating: false,
	setting: {
		useAutoBlacklist: false,
		period: 30,
		visitLimit: 20,
	},
}

const unregisteredReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_UNREGISTERED + REQUEST:
			return {
				...state,
				errorMessage: null,
			}
		case GET_UNREGISTERED + SUCCESS:
			return {
				...state,
				unregistered: action.result.data,
				totalCount: action.result.data.length,
			}
		case GET_UNREGISTERED + FAILURE:
			return {
				...state,
				errorMessage: action.error.response,
			}

		case HANDLE_UNREGISTERED + REQUEST:
		case SET_BLACKLIST_SETTING + REQUEST:
			return {
				...state,
				updating: true,
			}
		case HANDLE_UNREGISTERED + SUCCESS:
		case HANDLE_UNREGISTERED + FAILURE:
		case SET_BLACKLIST_SETTING + SUCCESS:
		case SET_BLACKLIST_SETTING + FAILURE:
			return {
				...state,
				updating: false,
			}
		case GET_BLACKLIST_SETTING + SUCCESS:
			return {
				...state,
				setting: action.result.data == '' ? state.setting : action.result.data,
			}
		default: {
			return state
		}
	}
}

export default unregisteredReducer
