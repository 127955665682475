import React from 'react'
import {
	Card,
	Button,
	Empty,
	Spin,
	Popover,
	Tag,
	Badge,
	Table,
	Space,
	Tooltip,
	Popconfirm,
} from 'antd'
import { connect } from 'react-redux'
import Config from '../config'
import moment from 'moment'
import { blue } from '@ant-design/colors'
import axios from 'axios'

import { getImageUrl } from '../utils'

import { MoreOutlined, PlusOutlined, SwapOutlined } from '@ant-design/icons'

import { JSMpegVideoPlayer } from '.'
import { renderFunctions, renderName } from './utils'

const CancelToken = axios.CancelToken

class MonitoringGateMobile extends React.Component {
	constructor(props) {
		super(props)

		var gateOption = localStorage.getItem(
			`${this.props.user.parking_id}.gates.${props.gate._id}`
		)

		this.state = {
			selected: null,
			imgNotFound: true,
			width: 160,
			videoError: false,
			isVideo: gateOption != null ? gateOption.isVideo || false : false,
			imageIndex: 0,
		}
	}
	cancel = null

	columns = [
		{
			title: '차량번호',
			render: (record) => {
				if (record.carplate === '미인식') {
					return (
						<Badge dot offset={[4, 0]}>
							<p style={{ fontSize: 16 }}>{record.carplate}</p>
						</Badge>
					)
				} else if (!record.isRegistered) {
					return (
						<p>
							{record.carplate}&nbsp;&nbsp;
							<Button
								size='small'
								shape='circle'
								icon={<PlusOutlined />}
								onClick={() => {
									const { gate, onClickAddCar } = this.props
									if (onClickAddCar) {
										onClickAddCar(gate, record)
									}
								}}
							></Button>
						</p>
					)
				} else {
					return <p>{record.carplate}</p>
				}
			},
		},
		{
			title: '이름',
			key: 'name',
			render: (record) => {
				return renderName(record, { layoutSetting: this.props.layoutSetting })
			},
		},
		{
			title: '진입시간',
			dataIndex: 'accessTime',
			key: 'accessTime',
			render: (accessTime) => {
				var style = {}
				if (moment(accessTime).get('dayOfYear') != moment().get('dayOfYear')) {
					style = { color: '#aaa' }
				}
				return (
					<Tooltip title={moment(accessTime).format('yyyy-MM-DD')}>
						<div style={style}>{moment(accessTime).format('HH:mm:ss')}</div>
					</Tooltip>
				)
			},
			width: 100,
		},
		{
			title: '수정',
			render: (record) => {
				return (
					<Button
						shape='circle'
						size='small'
						icon={<MoreOutlined />}
						onClick={() => {
							const { gate, onClickEdit } = this.props
							if (onClickEdit) {
								onClickEdit(gate, record)
							}
						}}
					/>
				)
			},
			width: 60,
		},
	]
	componentDidMount() {
		const { accesses, height } = this.props
		if (accesses != null) {
			this.setState({
				selected: accesses.length !== 0 ? accesses[0] : null,
			})
		}

		if (height != null) {
			this.setState({
				width: (((height - 52) / 2) * 4) / 3,
			})
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { accesses, onChangeDetail, gate, height, user } = this.props
		if (accesses != null && accesses !== prevProps.accesses) {
			this.setState({
				selected: accesses.length !== 0 ? accesses[0] : null,
			})
		}
		if (height != null && height !== prevProps.height) {
			this.setState({
				width: (((height - 52) / 2) * 4) / 3,
			})
		}
		if (
			(prevState.selected !== this.state.selected &&
				this.state.selected != null) ||
			(prevState.isVideo === true &&
				this.state.isVideo === false &&
				this.state.selected !== null) ||
			prevState.imageIndex !== this.state.imageIndex
		) {
			if (this.props.detailInfo != null) {
				if (onChangeDetail) {
					onChangeDetail({
						gate: gate,
						access: this.state.selected,
						url: getImageUrl(
							user,
							this.state.selected._id,
							this.state.imageIndex
						),
					})
				}
			}

			if (this.cancel) {
				this.cancel()
			}
			axios
				.get(
					getImageUrl(user, this.state.selected._id, this.state.imageIndex),
					{
						responseType: 'arraybuffer',
						cancelToken: new CancelToken((c) => (this.cancel = c)),
					}
				)
				.then((res) => {
					try {
						var base64 = new Buffer(res.data, 'binary').toString('base64')
						var ctx = this.refs.img.getContext('2d')
						var img = new Image()
						img.onload = () => {
							if (this.refs.img == null) {
								return
							}
							var rect = this.refs.img.getBoundingClientRect()
							this.refs.img.height = rect.height
							this.refs.img.width = rect.width
							if (img && this.refs.img) {
								ctx.drawImage(
									img,
									0,
									0,
									this.refs.img.width,
									this.refs.img.height
								)
								img.src = ''
							}
						}
						img.src = 'data:image/jpeg;base64,' + base64
						this.setState({
							imgNotFound: false,
						})
					} catch (err) {
						console.error(err)
						this.setState({
							imgNotFound: true,
						})
					}
				})
				.catch((err) => {
					this.setState({
						imgNotFound: true,
					})
				})
		}
	}

	renderRow = (props) => {
		const { accesses } = this.props
		const style =
			this.state.selected === accesses[props['data-row-key']]
				? { backgroundColor: blue[1] }
				: {}
		return <tr {...props} style={style} />
	}

	handleOnClickImage = () => {
		const { selected } = this.state
		const { onChangeDetail, gate, user } = this.props
		if (onChangeDetail && selected) {
			onChangeDetail({
				gate: gate,
				access: selected,
				url: getImageUrl(user, selected._id, this.state.imageIndex),
			})
		}
	}
	handleOnClickRow = (record) => {
		this.setState({
			selected: record,
		})
	}

	renderAccessLog = (item) => {
		const { gate, onClickEdit } = this.props
		if (item.gateId == null) {
			item.gateId = item.TBL_GATE_id
		}
		item.gateId = item.gateId.toUpperCase()

		var name = ''
		if (item.isBlacklist) {
			name = (
				<Popover
					content={
						<div>
							<p style={{ whiteSpace: 'pre-line' }}>{item.blacklistComment}</p>
							<div style={{ textAlign: 'right' }}>
								{moment(item.blacklistDate).format('YYYY-MM-DD')}
							</div>
						</div>
					}
					title='블랙리스트'
					trigger='hover'
				>
					<Tag color='red'>블랙리스트</Tag>
				</Popover>
			)
		} else if (item.isRegistered) {
			name = (
				<Popover
					content={
						<div>
							<div>이름: {item.name}</div>
							<div>동: {item.dong}</div>
							<div>호: {item.ho}</div>
							<div>연락처: {item.phone}</div>
						</div>
					}
					title='등록차량'
					trigger='hover'
				>
					<Tag color='green'>
						{item.name == null || item.name === '' ? '미입력' : item.name}
					</Tag>
				</Popover>
			)
		} else if (item.isTempRegistered) {
			name = <Tag color='blue'>임시출입증</Tag>
		} else if (item.carplate !== '미인식') {
			name = <Tag color='magenta'>미등록</Tag>
		}
		return (
			<Card
				onClick={(e) => {
					this.setState({
						selected: item,
					})
				}}
				title={
					<div>
						{item.carplate === '미인식' ? (
							<Badge dot offset={[4, 0]}>
								{item.carplate}
							</Badge>
						) : (
							item.carplate
						)}
						<Button
							style={{ float: 'right' }}
							shape='circle'
							size='small'
							icon={<MoreOutlined />}
							onClick={() => {
								if (onClickEdit) {
									onClickEdit(gate, item)
								}
							}}
						/>
					</div>
				}
				style={{
					marginBottom: 8,
					width: '100%',
				}}
				bodyStyle={{ padding: 16 }}
				headStyle={{ paddingLeft: 16, paddingRight: 16 }}
			>
				<p>{gate.name}</p>
				<span>{name}</span>
				<span style={{ fontSize: 12, float: 'right' }}>
					{moment(item.accessTime).format('HH:mm:ss')}
				</span>
			</Card>
		)
	}

	componentWillUnmount() {}

	render() {
		const {
			gate,
			height,
			accesses,
			types,
			functions,
			loading,
			cameras,
			onClickLoadMore,
			showLoopDetecting = false,
			showOpeningGate = false,
			layoutSetting,
		} = this.props
		const { imgNotFound, videoError, isVideo, imageIndex, width } = this.state

		types
			.filter((x) => x.category === 'LPR_FUNCTION_STATE')
			.map((x, i) => {
				x.index = i
				x.enabled =
					functions != null &&
					functions.findIndex((y) => y.functionId === x._id && y.enabled) !== -1
				return x
			})
			.filter((x) => {
				return (
					functions != null &&
					functions.findIndex((y) => y.functionId === x._id) !== -1
				)
			})

		if (gate == null) {
			return (
				<div
					style={{
						height: height,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Spin />
				</div>
			)
		}

		// const streamingUrl = `http://192.168.10.79:5050/?ip=${
		// 	camera != null ? camera.ip : ''
		// }&id=guest&pw=guest`

		return (
			<Card
				tabIndex={0}
				size='small'
				headStyle={{ fontWeight: 'bold' }}
				style={{ margin: 4, height: height }}
				title={
					<div>
						<Space align={'center'} style={{ marginBottom: 8 }}>
							<h3>{gate.name}</h3>
							{showOpeningGate && (
								<Tooltip title='차단기 열림'>
									<Badge color={'#52c41a'} />
								</Tooltip>
							)}
							{showLoopDetecting && (
								<Tooltip title='루프 검지'>
									<Badge color={'gold'} />
								</Tooltip>
							)}
						</Space>
						{renderFunctions(this.props, true)}
					</div>
				}
				bordered={true}
				bodyStyle={{ padding: 0 }}
			>
				{loading ? (
					<div
						style={{
							height: height - 137,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Spin />
					</div>
				) : (
					<div>
						<div
							style={{
								borderRightWidth: 1,
								borderRightColor: '#eee',
								borderRightStyle: 'solid',
								position: 'relative',
							}}
						>
							<Button
								style={{
									position: 'absolute',
									right: 10,
									top: 10,
									zIndex: 11,
								}}
								onClick={() => {
									this.setState({
										isVideo: !this.state.isVideo,
									})
									localStorage.setItem(
										`${this.props.user.parking_id}.gates.${gate._id}`,
										JSON.stringify({
											isVideo: !this.state.isVideo,
										})
									)
								}}
								icon={<SwapOutlined />}
							>
								{isVideo ? '사진 전환' : '영상 전환'}
							</Button>
						</div>
						<div style={{ height: (height - 137) / 2, overflow: 'hidden' }}>
							{isVideo ? (
								<div
									style={{
										borderRightWidth: 1,
										borderRightColor: '#eee',
										borderRightStyle: 'solid',
										flex: 1,
									}}
								>
									<div
										style={{
											height: (height - 137) / 2,
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										{videoError || cameras.length === 0 ? (
											<Empty
												description='No Video'
												image={Empty.PRESENTED_IMAGE_SIMPLE}
											/>
										) : cameras.length !== 0 &&
										  cameras[this.state.imageIndex].streamingUrl !== null &&
										  cameras[this.state.imageIndex].streamingUrl.startsWith(
												'http'
										  ) ? (
											<iframe
												title={cameras[this.state.imageIndex]._id}
												style={{ border: 0 }}
												src={cameras[this.state.imageIndex].streamingUrl}
												scrolling='no'
												height={height}
												width={width}
											></iframe>
										) : (
											<JSMpegVideoPlayer
												style={{ height: (height - 137) / 2, width: '100%' }}
												url={
													cameras.length === 0
														? null
														: cameras[imageIndex].streamingUrl
												}
											/>
										)}
									</div>
								</div>
							) : (
								<div
									style={{
										borderRightWidth: 1,
										borderRightColor: '#eee',
										borderRightStyle: 'solid',
										flex: 1,
									}}
								>
									<div
										ref='imageContainer'
										style={{
											height: (height - 137) / 2,
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										<div
											style={{
												width: '100%',
												height: '100%',
												textAlign: 'center',
												borderBottomWidth: 1,
												borderBottomColor: '#eee',
												borderBottomStyle: 'solid',
											}}
										>
											<canvas
												ref={'img'}
												style={{
													height: imgNotFound ? 0 : '100%',
													width: '100%',
												}}
											/>
											{imgNotFound && (
												<Empty
													description='No Image'
													image={Empty.PRESENTED_IMAGE_SIMPLE}
												/>
											)}
										</div>
									</div>
								</div>
							)}
						</div>
						<div style={{ height: (height - 137) / 2, overflow: 'auto' }}>
							{accesses != null && (
								<Table
									size={'small'}
									columns={this.columns.filter((col) => {
										if (col.key == 'dong' && !layoutSetting.showDong) {
											return false
										}
										if (col.key == 'ho' && !layoutSetting.showHo) {
											return false
										}
										if (col.key == 'name' && !layoutSetting.showName) {
											return false
										}
										return true
									})}
									dataSource={accesses}
									pagination={false}
									useFixedHeader={true}
									style={{ fontSize: 16 }}
									onRow={(record, rowIndex) => {
										return {
											onClick: () => {
												this.handleOnClickRow(record)
											},
										}
									}}
									rowClassName={(record) =>
										this.state.selected == record ? 'selected-item' : ''
									}
									rowKey={(record) => {
										return record._id
									}}
									footer={() =>
										accesses.length > 0 && (
											<div style={{ textAlign: 'center' }}>
												<Button
													type='link'
													onClick={() => {
														if (onClickLoadMore) {
															onClickLoadMore(
																gate,
																accesses[accesses.length - 1].accessTime
															)
														}
													}}
												>
													더 보기
												</Button>
											</div>
										)
									}
								/>
							)}
						</div>
					</div>
				)}
			</Card>
		)
	}
}

function mapStateToProps(state) {
	return { user: state.user.user }
}

export default connect(mapStateToProps)(MonitoringGateMobile)
