import Config from '../config'

export const GET_NOTICES = 'GET_NOTICES'

export const getNotices = (parkingId) => {
	return {
		type: GET_NOTICES,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/notice/getNotices`,
			params: {
				parking_id: parkingId,
			},
		},
	}
}
