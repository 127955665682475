import { GET_PARKING_INFO, UPDATE_PARKING_INFO } from '../action/parkingInfo'

const REQUEST = '_REQUEST',
	SUCCESS = '_SUCCESS',
	FAILURE = '_FAILURE'

const defaultState = {
	parkingInfo: [],
	updating: false,
	errorMessage: null
}

const parkingInfoReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_PARKING_INFO + SUCCESS:
			return {
				...state,
				parkingInfo: action.result.data
			}
		case UPDATE_PARKING_INFO + REQUEST:
			return {
				...state,
				updating: true
			}
		case UPDATE_PARKING_INFO + SUCCESS:
			return {
				...state,
				errorMessage: null,
				updating: false
			}
		case UPDATE_PARKING_INFO + FAILURE:
			return {
				...state,
				errorMessage: action.error.response,
				updating: false
			}
		default: {
			return state
		}
	}
}

export default parkingInfoReducer
