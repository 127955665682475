import Config from '../config'

export const GET_REGISTRATIONS = 'GET_REGISTRATIONS'

export const GET_ALL_REGISTRATIONS = 'GET_ALL_REGISTRATIONS'

export const GET_REGISTRATIONS_COUNT = 'GET_REGISTRATIONS_COUNT'

export const ADD_REGISTRATION = 'ADD_REGISTRATION'

export const IMPORT_REGISTRATIONS = 'IMPORT_REGISTRATIONS'

export const UPDATE_REGISTRATION = 'UPDATE_REGISTRATION'

export const DELETE_REGISTRATION = 'DELETE_REGISTRATION'

export const getRegistrations = ({
	parkingId,
	page = 0,
	limit = 20,
	q_name = '',
	q_car = '',
	q_dong = '',
	q_ho = '',
}) => {
	return {
		type: GET_REGISTRATIONS,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/query/getRegisteredCars`,
			params: {
				parking_id: parkingId,
				page,
				limit,
				q_name,
				q_car,
				q_dong,
				q_ho,
			},
		},
	}
}

export const getAllRegistrations = ({
	parkingId,
	q_name = '',
	q_car = '',
	q_dong = '',
	q_ho = '',
}) => {
	return {
		type: GET_ALL_REGISTRATIONS,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/query/getRegisteredCars`,
			params: {
				parking_id: parkingId,
				page: 0,
				limit: 999999,
				q_name,
				q_car,
				q_dong,
				q_ho,
			},
		},
	}
}

export const getRegistrationsCount = ({
	parkingId,
	q_name = '',
	q_car = '',
	q_dong = '',
	q_ho = '',
}) => {
	return {
		type: GET_REGISTRATIONS_COUNT,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/query/getRegisteredCarsCount`,
			params: { parking_id: parkingId, q_name, q_car, q_dong, q_ho },
		},
	}
}

export const addRegistration = ({ parkingId, item }) => {
	return {
		type: ADD_REGISTRATION,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/car/addCar',
			data: {
				parking_id: parkingId,
				car: item.car,
				dong: item.dong,
				ho: item.ho,
				name: item.name,
				phone: item.phone,
				situation_id: item.situation_id,
				memo: item.memo,
				start: item.start,
				expire: item.expire,
			},
		},
	}
}

export const updateRegistration = ({ parkingId, item }) => {
	return {
		type: UPDATE_REGISTRATION,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/car/updateCar',
			data: {
				parking_id: parkingId,
				car_id: item.ownerId,
				dong: item.dong,
				ho: item.ho,
				name: item.name,
				phone: item.phone,
				situation_id: item.situation_id,
				memo: item.memo,
				carplate: item.car,
				start: item.start,
				expire: item.expire,
			},
		},
	}
}

export const importRegistrations = ({ parkingId, items }) => {
	return {
		type: IMPORT_REGISTRATIONS,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/car/importCars',
			data: {
				parking_id: parkingId,
				items: items,
			},
		},
	}
}

export const deleteRegistration = ({ parkingId, carId, ownerId, car }) => {
	return {
		type: DELETE_REGISTRATION,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/car/removeCar',
			data: {
				car,
				car_id: carId,
				owner_id: ownerId,
				parking_id: parkingId,
			},
		},
	}
}

export const deleteRegistrations = ({ parkingId, items }) => {
	return {
		type: DELETE_REGISTRATION,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/car/removeCars',
			data: {
				parking_id: parkingId,
				items,
			},
		},
	}
}
