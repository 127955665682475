import Config from '../config'

export const GET_FUNCTIONS = 'GET_FUNCTIONS'
export const ADD_FUNCTION = 'ADD_FUNCTION'
export const UPDATE_FUNCTION = 'UPDATE_FUNCTION'
export const DELETE_FUNCTION = 'DELETE_FUNCTION'

export const getFunctions = (parkingId) => {
	return {
		type: GET_FUNCTIONS,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/function/getFunctions`,
			params: {
				parking_id: parkingId,
			},
		},
	}
}

export const addFunction = (parkingId, functionItem) => {
	return {
		type: ADD_FUNCTION,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/function/addFunction`,
			data: {
				parking_id: parkingId,
				...functionItem,
			},
		},
		payload: {
			function: functionItem,
		},
	}
}

export const updateFunction = (parkingId, functionItem) => {
	return {
		type: UPDATE_FUNCTION,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/function/updateFunction`,
			data: {
				parking_id: parkingId,
				...functionItem,
			},
		},
		payload: {
			function: functionItem,
		},
	}
}

export const deleteFunction = (parkingId, functionItem) => {
	return {
		type: DELETE_FUNCTION,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/function/deleteFunction`,
			data: { parking_id: parkingId, ...functionItem },
		},
		payload: {
			function: functionItem,
		},
	}
}
