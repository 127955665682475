import Config from '../config'

export const GET_PARKING_INFO = 'GET_PARKING_INFO'
export const UPDATE_PARKING_INFO = 'UPDATE_PARKING_INFO'

export const getParkingInfo = () => {
	return {
		type: GET_PARKING_INFO,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/auth/parkingInfo`
		}
	}
}

export const updateParkingInfo = ({ parking_name }) => {
	return {
		type: UPDATE_PARKING_INFO,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/auth/updateParking',
			data: {
				parking_name
			}
		}
	}
}
