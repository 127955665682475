import Config from '../config'

export const LOGIN = 'LOGIN'

export const INFO = 'INFO'

export const LOGOUT = 'LOGOUT'

export const SIGNUP = 'SIGNUP'

export const login = (id, password, remember) => {
	return {
		type: LOGIN,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/auth/login',
			data: { id, password, remember }
		}
	}
}

export const info = user => {
	return {
		type: INFO,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + '/auth/info'
		},
		payload: {
			user: user
		}
	}
}

export const logout = () => {
	return {
		type: LOGOUT,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/auth/logout'
		}
	}
}

export const signup = data => {
	return {
		type: SIGNUP,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/auth/register',
			data: data
		}
	}
}
