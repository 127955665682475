import {
	GET_SETTING,
	UPDATE_SETTING,
	GET_LATEST_ENTERED,
	GET_PAYMENT,
	GET_REMAINS,
	GET_PAYMENTS,
	RECEIVE_PAYMENT,
	DELETED_PAYMENT,
	MODIFY_CARPLATE
} from '../action/payment.js'

const REQUEST = '_REQUEST',
	SUCCESS = '_SUCCESS',
	FAILURE = '_FAILURE'

const defaultState = {
	fetchingUpdate: false,
	updating: false,
	setting: null,
	payments: []
}

const paymentReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_SETTING + REQUEST:
			return {
				...state,
				fetchingUpdate: true
			}
		case GET_SETTING + SUCCESS:
			return {
				...state,
				fetchingUpdate: false,
				setting: action.result.data
			}
		case GET_SETTING + FAILURE:
			return {
				...state,
				fetchingUpdate: false,
				errorMessage: action.error.response
			}
		case UPDATE_SETTING + REQUEST:
			return {
				...state,
				updating: true,
				errorMessage: null
			}
		case UPDATE_SETTING + SUCCESS:
			return {
				...state,
				updating: false
			}
		case UPDATE_SETTING + FAILURE:
			return {
				...state,
				updating: false,
				errorMessage: action.error.response
			}
		case GET_PAYMENTS + REQUEST:
			return {
				...state,
				fetchingUpdate: true
			}
		case GET_PAYMENTS + SUCCESS:
			// action.result.data.sort((x, y) => {
			// 	if (x.statusType == 9001 || x.statusType == 9002) {
			// 		if (y.statusType == 9001 || y.statusType == 9002) {
			// 			return y._id.localeCompare(x._id)
			// 		} else {
			// 			return -1
			// 		}
			// 	} else {
			// 		if (y.statusType == 9001 || y.statusType == 9002) {
			// 			return 1
			// 		} else {
			// 			return y._id.localeCompare(x._id)
			// 		}
			// 	}
			// })
			return {
				...state,
				fetchingUpdate: false,
				payments: action.result.data
			}
		case GET_PAYMENTS + FAILURE:
			return {
				...state,
				fetchingUpdate: false,
				errorMessage: action.error.response,
				payments: []
			}
		case MODIFY_CARPLATE + REQUEST:
			return { ...state, updating: true }
		case MODIFY_CARPLATE + SUCCESS:
			return { ...state, updating: false, errorMessage: null }
		case MODIFY_CARPLATE + FAILURE:
			return { ...state, updating: false, errorMessage: action.error.response }
		case RECEIVE_PAYMENT:
			var res = state.payments.filter(x => x._id == action.payload.item._id)
			if (res.length !== 0) {
				res[0] = action.payload.item
				return {
					...state,
					payments: state.payments.map(item => {
						if (item._id == action.payload.item._id) {
							return action.payload.item
						} else {
							return item
						}
					})
				}
			} else {
				return {
					...state,
					payments: [action.payload.item, ...state.payments]
				}
			}
		case DELETED_PAYMENT:
			return {
				...state,
				payments: state.payments.filter(item => {
					if (item._id == action.payload.id) {
						return false
					}
					return true
				})
			}
		default: {
			return state
		}
	}
}

export default paymentReducer
