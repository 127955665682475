import Config from '../config'
import moment from 'moment'

export const GET_UNREGISTERED = 'GET_UNREGISTERED'
export const HANDLE_UNREGISTERED = 'HANDLE_UNREGISTERED'

export const GET_BLACKLIST_SETTING = 'GET_BLACKLIST_SETTING'
export const SET_BLACKLIST_SETTING = 'SET_BLACKLIST_SETTING'

export const getUnregistered = ({
	parkingId,
	start = '1970-01-01 00:00:00',
	end = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
}) => {
	return {
		type: GET_UNREGISTERED,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/unregistered/getUnregistered`,
			params: {
				parking_id: parkingId,
				start: start.format('YYYY-MM-DD HH:mm:ss.SSS'),
				end: end.format('YYYY-MM-DD HH:mm:ss.SSS'),
			},
		},
	}
}

export const handleUnregistered = ({ parkingId, item }) => {
	return {
		type: HANDLE_UNREGISTERED,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/unregistered/handleUnregistered',
			data: {
				parking_id: parkingId,
				car_number: item.carNumber,
				state: item.state,
			},
		},
	}
}

export const getBlacklistSetting = (parkingId) => {
	return {
		type: GET_BLACKLIST_SETTING,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/unregistered/getBlacklistSetting`,
			params: {
				parking_id: parkingId,
			},
		},
	}
}

export const setBlacklistSetting = (
	parkingId,
	useAutoBlacklist,
	period,
	visitLimit
) => {
	return {
		type: SET_BLACKLIST_SETTING,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/unregistered/setBlacklistSetting`,
			data: {
				parking_id: parkingId,
				period: period,
				visit_limit: visitLimit,
				use_auto_blacklist: useAutoBlacklist,
			},
		},
	}
}
