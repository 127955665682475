import Config from '../config'

export const GET_QNALIST = 'GET_QNALIST'
export const GET_QNALIST_COUNT = 'GET_QNALIST_COUNT'
export const ADD_QNA = 'ADD_QNA'
export const GET_QNAVIEW = 'GET_QNAVIEW'
export const GET_COMMENTLIST = 'GET_COMMENTLIST'
export const ADD_COMMENT = 'ADD_COMMENT'
export const DELETE_QNA = 'DELETE_QNA'
export const DELETE_COMMENT = 'DELETE_COMMENT'

export const getQnaList = (page, idx) => {
	return {
		type: GET_QNALIST,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/qna/getQnaList`,
			data: {
				idx: idx,
				page: page,
			},
		},
	}
}

export const getQnaListCount = (idx) => {
	return {
		type: GET_QNALIST_COUNT,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/qna/getQnaListCount`,
			data: {
				idx: idx,
			},
		},
	}
}

export const AddQnA = (title, content, writer) => {
	return {
		type: ADD_QNA,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/qna/AddQnA`,
			data: {
				title: title,
				content: content,
				writer: writer,
			},
		},
	}
}

export const getQnaView = (idx) => {
	return {
		type: GET_QNAVIEW,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/qna/getQnaView`,
			params: {
				idx,
			},
		},
	}
}

export const getCommentList = (_id) => {
	return {
		type: GET_COMMENTLIST,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/qna/getCommentList`,
			params: {
				_id,
			},
		},
	}
}

export const AddComment = (_id, content, writer) => {
	return {
		type: ADD_COMMENT,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/qna/AddComment`,
			data: {
				_id: _id,
				content: content,
				writer: writer,
			},
		},
	}
}

export const DeleteQnA = (idx, userIdx) => {
	return {
		type: DELETE_QNA,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/qna/DeleteQnA`,
			data: {
				_id: idx,
				userIdx: userIdx,
			},
		},
	}
}

export const DeleteComment = (commentId, userIdx) => {
	return {
		type: DELETE_COMMENT,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/qna/DeleteComment`,
			data: {
				_id: commentId,
				userIdx: userIdx,
			},
		},
	}
}
