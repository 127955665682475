import React from 'react'

import { PlayCircleFilled, PauseCircleFilled } from '@ant-design/icons'

import JSMpeg from '@cycjimmy/jsmpeg-player'

class JSMpegVideoPlayer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			status: 'uninitialzed',
			isFullscreen: false,
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			this.state.status === nextState.status &&
			this.props.url === nextProps.url &&
			this.state.playerWidth === nextState.playerWidth &&
			this.state.playerHeight === nextState.playerHeight &&
			this.state.isFullscreen === nextState.isFullscreen
		) {
			return false
		}
		return true
	}

	componentDidMount() {
		const { url } = this.props
		this.initPlayer(url)
	}

	componentDidUpdate(prevProps) {
		const { url } = this.props
		if (url != prevProps.url) {
			try {
				this.player.stop()
				this.player.destroy()
				this.player = null
				this.initPlayer(url)
			} catch (e) {
				console.log(e)
			}
		}
	}

	componentWillUnmount() {
		try {
			if (this.player != null) {
				this.player.stop()
				this.player.destroy()
			}
		} catch (e) {
			console.log(e)
		}
	}
	initPlayer = (url) => {
		try {
			if (url == null) {
				return
			}
			if (this.player == null && url != null && this.playerRef != null) {
				this.player = new JSMpeg.VideoElement(this.playerRef, url, {
					hooks: {
						play: () => {
							this.setState({
								status: 'playing',
							})
						},
						stop: () => {
							this.setState({
								status: 'stopped',
							})
						},
						pause: () => {
							this.setState({
								status: 'stopped',
							})
						},
						load: () => {},
					},
				})
			}
		} catch (err) {
			console.log(err)
		}
	}

	render() {
		const { status } = this.state
		//console.log('render video')

		// var style = { ...this.props.style }
		// style.position = 'relative'
		// style.background = 'black'
		// style.display = 'inline-block'

		return (
			<div
				style={{ height: '100%', width: '100%', display: 'inline-block' }}
				onClick={() => {
					this.setState({ isFullscreen: !this.state.isFullscreen })
				}}
			>
				<p
					style={{
						position: 'absolute',
						margin: 8,
						color: '#aaa',
						zIndex: 10,
					}}
				>
					{status == 'playing' ? (
						<PlayCircleFilled style={{ fontSize: 20 }} />
					) : (
						<PauseCircleFilled style={{ fontSize: 20 }} />
					)}
				</p>
				<div
					className={this.state.isFullscreen ? 'fullscreen-video' : ''}
					style={{
						height: '100%',
						width: '100%',
						display: 'inline-block',
					}}
					ref={(e) => {
						if (e != null) {
							this.playerRef = e
							this.setState({
								playerWidth: e.getBoundingClientRect().width,
								playerHeight: e.getBoundingClientRect().height,
							})
						}
					}}
				></div>
			</div>
		)
	}
}

export default JSMpegVideoPlayer
