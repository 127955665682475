import React from 'react'
import { Modal, Input, Checkbox, Row, Col } from 'antd'

class SelectGateModal extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			visible: false,
			selectedGates: [],
		}
	}

	show(selectedGates) {
		this.setState({
			visible: true,
			selectedGates,
		})
	}

	hide() {
		this.setState({
			visible: false,
		})
	}

	onChange = (selected) => {
		this.setState({
			selectedGates: selected,
		})
	}
	render() {
		const { visible, selectedGates } = this.state
		const { onOk, gates } = this.props
		return (
			<Modal
				title={'출입문 선택'}
				centered
				open={visible}
				onOk={onOk}
				onCancel={() => {
					this.setState({
						visible: false,
					})
				}}
				cancelText='취소'
				okText={'적용'}
			>
				<Checkbox.Group
					style={{ width: '100%' }}
					onChange={this.onChange}
					value={selectedGates}
				>
					<Row>
						{gates != null &&
							Object.keys(gates).map((key) => {
								var item = gates[key]
								return (
									<Col span={12} key={key}>
										<Checkbox value={item._id}>{item.name}</Checkbox>
									</Col>
								)
							})}
					</Row>
				</Checkbox.Group>
			</Modal>
		)
	}
}

export default SelectGateModal
