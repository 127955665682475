export { default as HeaderLayout } from './HeaderLayout'
// export { default as MonitoringGate } from './MonitoringGate'
export { default as MonitoringGate } from './MonitoringGate'
export { default as MonitoringGateSimple } from './MonitoringGateSimple'
export { default as MonitoringGateVideo } from './MonitoringGateVideo'
export { default as PrivateRoute } from './PrivateRoute'
export { default as EditCarModal } from './EditCarModal'
export { default as EditAccessModal } from './EditAccessModal'
export { default as EditBlacklistModal } from './EditBlacklistModal'
export { default as EditResidentModal } from './EditResidentModal'
export { default as ImportExcelModal } from './ImportExcelModal'
export { default as PaymentModal } from './PaymentModal'

export { default as MonitoringGateMobile } from './MonitoringGateMobile'
export { default as ModifyCarplateModal } from './ModifyCarplateModal'
export { default as SelectGateModal } from './SelectGateModal'
export { default as SelectLayoutModal } from './SelectLayoutModal'
export { default as VideoPlayer } from './VideoPlayer'
export { default as JSMpegVideoPlayer } from './JSMpegVideoPlayer'
export { default as TicketModal } from './TicketModal'
export { default as ViewMemoModal } from './ViewMemoModal'
