import Config from '../config'
import moment from 'moment'

export const GET_QUERIED = 'GET_QUERIED'
export const GET_QUERIED_COUNT = 'GET_QUERIED_COUNT'
export const DELETE_QUERIED = 'DELETE_QUERIED'

export const GET_OVERDUE = 'GET_OVERDUE'
export const GET_OVERDUE_COUNT = 'GET_OVERDUE_COUNT'
export const DELETE_OVERDUE = 'DELETE_OVERDUE'

export const getQueried = (
	parkingId,
	start = moment().add(-1, 'hour'),
	end = null,
	q_car = '',
	page = null,
	limit = null
) => {
	return {
		type: GET_QUERIED,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/query/getQueriedCars`,
			params: {
				parking_id: parkingId,
				start: start.startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
				end: end
					.startOf('day')
					.add(1, 'days')
					.add(-1, 'seconds')
					.format('YYYY-MM-DD HH:mm:ss.SSS'),
				page,
				limit,
				q_car,
			},
		},
	}
}

export const getQueriedCount = (
	parkingId,
	start = null,
	end = null,
	q_car = ''
) => {
	return {
		type: GET_QUERIED_COUNT,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/query/getQueriedCarsCount`,
			params: {
				parking_id: parkingId,
				start: start.startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
				end: end
					.startOf('day')
					.add(1, 'days')
					.add(-1, 'seconds')
					.format('YYYY-MM-DD HH:mm:ss.SSS'),
				q_car,
			},
		},
	}
}

export const deleteQueried = (parkingId, ids) => {
	return {
		type: DELETE_QUERIED,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/query/deleteQueried`,
			data: { parking_id: parkingId, ids },
		},
	}
}

export const getOverdue = (
	parkingId,
	start = moment().add(-1, 'hour'),
	end = null,
	q_car = '',
	page = 0,
	limit = 20
) => {
	return {
		type: GET_OVERDUE,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/query/getOverdue`,
			params: {
				parking_id: parkingId,
				start: start.startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
				end: end
					.startOf('day')
					.add(1, 'days')
					.add(-1, 'seconds')
					.format('YYYY-MM-DD HH:mm:ss.SSS'),
				page,
				limit,
				q_car,
			},
		},
	}
}

export const getOverdueCount = (
	parkingId,
	start = null,
	end = null,
	q_car = ''
) => {
	return {
		type: GET_OVERDUE_COUNT,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/query/getOverdueCount`,
			params: {
				parking_id: parkingId,
				start: start.startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
				end: end
					.startOf('day')
					.add(1, 'days')
					.add(-1, 'seconds')
					.format('YYYY-MM-DD HH:mm:ss.SSS'),
				q_car,
			},
		},
	}
}

export const deleteOverdue = (parkingId, ids) => {
	return {
		type: DELETE_OVERDUE,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/query/deleteOverdue`,
			data: { parking_id: parkingId, ids },
		},
	}
}
