import React from 'react'
import { Modal, Input, Button, Alert, Popover } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
class EditResidentModal extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			visible: false,
			onOk: null,
			onCancel: null,
			isEdit: false,
			item: null,
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			this.state.visible === nextState.visible &&
			this.state.item === nextState.item &&
			this.state.isEdit === nextState.isEdit &&
			this.state.visible == false &&
			nextState.visible == false
		) {
			return false
		}
		return true
	}

	show({ item, onOk, onCancel }) {
		this.setState({
			visible: true,
			isEdit: item != null,
			item,
			onOk,
			onCancel,
		})
	}
	hide() {
		this.setState({
			visible: false,
			onOk: null,
			onCancel: null,
			item: null,
		})
	}

	parseHo = (input) => {
		return input
			.replace(/ /gi, '')
			.split(',')
			.map((splitted) => {
				return splitted
					.split(/\(|\)/)
					.filter(Boolean)
					.reduce((acc, x, i) => {
						if (x.indexOf('-') != -1) {
							var split = x.split('-')
							var start = parseInt(split[0])
							var end = parseInt(split[1])
							if (acc.length == 0) {
								acc.push(
									...Array.from({ length: end - start + 1 }, (v, k) =>
										(k + start).toString()
									)
								)
							} else {
								var iter = Array.from({ length: end - start + 1 }, (v, k) =>
									(k + start).toString()
								)
								acc = acc
									.map((item) => iter.map((item2) => item + item2))
									.reduce((acc, x, i) => {
										acc.push(...x)
										return acc
									}, [])
							}
							return acc
						} else {
							if (acc.length == 0) {
								acc.push(x)
							} else {
								acc = acc.map((item) => item + x)
							}
						}
						return acc
					}, [])
			})
			.reduce((acc, x) => {
				acc.push(...x)
				return acc
			})
			.sort((x, y) => parseInt(x) - parseInt(y))
			.toString()
	}
	render() {
		const { visible, onOk, onCancel, isEdit, item } = this.state
		return (
			<Modal
				title={isEdit ? '입주민 수정' : '입주민 등록'}
				centered
				open={visible}
				onOk={onOk}
				onCancel={() => {
					this.setState({
						visible: false,
					})
					if (onCancel) {
						onCancel()
					}
				}}
				footer={[
					<Button
						key='cancel'
						onClick={() => {
							this.setState({
								visible: false,
							})
							if (onCancel) {
								onCancel()
							}
						}}
					>
						취소
					</Button>,
					<Button
						key='edit'
						onClick={() => {
							if (onOk) {
								onOk(item)
							}
						}}
						type='primary'
					>
						{isEdit ? '수정' : '등록'}
					</Button>,
				]}
			>
				<div style={{ marginTop: 16 }}>
					<label>이름</label>
					<Input
						placeholder={'이름'}
						value={item != null ? item.name : ''}
						onChange={(e) => {
							var item = { ...this.state.item }
							item.name = e.target.value
							this.setState({
								item,
							})
						}}
					/>
				</div>
				<div style={{ marginTop: 16 }}>
					<label>동</label>
					<Input
						placeholder={'동'}
						value={item != null ? item.dong : ''}
						onChange={(e) => {
							var item = { ...this.state.item }
							item.dong = e.target.value
							this.setState({
								item,
							})
						}}
					/>
				</div>
				<div style={{ marginTop: 16 }}>
					<label>호</label>
					<Input
						placeholder={'호'}
						value={item != null ? item.ho : ''}
						onChange={(e) => {
							var item = { ...this.state.item }
							item.ho = e.target.value
							this.setState({
								item,
							})
						}}
					/>
					{!isEdit && (
						<div>
							<Button
								style={{ marginTop: 8 }}
								onClick={() => {
									if (item != null) {
										item.ho = this.parseHo(item.ho)
										this.setState({
											item,
										})
									}
								}}
							>
								변환
							</Button>
							<Popover
								title={'사용법'}
								content={
									<div>
										<div>
											괄호와 - 기호를 이용해 부분적으로 범위 지정을 할 수
											있습니다.
										</div>
										<div>예제: (2-15)0(1-4)</div>
										<div>입력 후 변환 버튼을 꼭 눌러주세요.</div>
									</div>
								}
							>
								<Button icon={<QuestionCircleOutlined />} type='text' />
							</Popover>
							<Alert
								banner
								style={{ marginTop: 8, fontSize: 14 }}
								type={'info'}
								message={
									<div>
										<div>
											-와 ,기호를 이용해 여러 가구를 한번에 등록하실 수
											있습니다.
										</div>
										<div>예제: 101-109,201-210</div>
									</div>
								}
							/>
						</div>
					)}
				</div>

				<div style={{ marginTop: 16 }}>
					<label>비밀번호</label>
					<Input
						placeholder={'임시 방문증을 발급할 때 사용할 비밀번호입니다.'}
						value={item != null ? item.password : ''}
						type={'password'}
						autoComplete={'new-password'}
						onChange={(e) => {
							var item = { ...this.state.item }
							item.password = e.target.value
							this.setState({
								item,
							})
						}}
					/>
				</div>
			</Modal>
		)
	}
}

export default EditResidentModal
