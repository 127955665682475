import Config from '../config'
import moment from 'moment'

export const GET_ACCESS = 'GET_ACCESS'
export const GET_ACCESSES = 'GET_ACCESSES'
export const GET_ACCESSES_COUNT = 'GET_ACCESSES_COUNT'
export const GET_ACCESSES_ALL = 'GET_ACCESSES_ALL'
export const ADD_ACCESS = 'ADD_ACCESS'
export const UPDATE_ACCESS = 'UPDATE_ACCESS'
export const DELETE_ACCESS = 'DELETE_ACCESS'
export const DELETE_ACCESSES = 'DELETE_ACCESSES'
export const GET_ACCESSES_SEARCH = 'GET_ACCESSES_SEARCH'
export const GET_ACCESSES_STATISTICS = 'GET_ACCESSES_STATISTICS'
export const GET_ACCESSES_STATISTICS_HOURLY = 'GET_ACCESSES_STATISTICS_HOURLY'
export const GET_ACCESSES_NO_LIMIT = 'GET_ACCESSES_NO_LIMIT'

export const RECEIVE_ACCESS = 'RECEIVE_ACCESS'
export const RECEIVE_DELETE_ACCESS = 'RECEIVE_DELETE_ACCESS'
export const RECEIVE_UPDATE_ACCESS = 'RECEIVE_UPDATE_ACCESS'

export const GET_MEMOS = 'GET_MEMOS'
export const CLEAR_MEMOS = 'CLEAR_MEMOS'
export const CLEAR_SINGLE_ACCESS = 'CLEAR_SINGLE_ACCESS'

export const getAccessesAll = (parkingId, limit = 500) => {
	return {
		type: GET_ACCESSES_ALL,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/access/getAccesses`,
			params: {
				parking_id: parkingId,
				start: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
				limit,
			},
		},
	}
	// return {
	// 	type: GET_ACCESSES_ALL,
	// 	promise: {
	// 		method: 'get',
	// 		url:
	// 			Config.SERVER_URL +
	// 			`/access/getAccesses?parking_id=${parkingId}&start=${moment()
	// 				.add(-1, 'hour')
	// 				.format('YYYY-MM-DD HH:mm:ss.SSS')}`
	// 	}
	// }
}

export const getAccess = (parkingId, id) => {
	return {
		type: GET_ACCESS,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/access/getAccess`,
			params: {
				parking_id: parkingId,
				id,
			},
		},
	}
}

export const getAccessesSearch = ({
	parkingId,
	gateId = null,
	start,
	end,
	q_car,
	page = null,
	limit = null,
	q_registered = -1,
	q_access_type = -1,
}) => {
	if (start == null) {
		start = moment().add(-10, 'day')
	}
	if (end == null) {
		end = moment()
	}
	return {
		type: GET_ACCESSES_SEARCH,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/access/getAccesses`,
			params: {
				parking_id: parkingId,
				gate_id: gateId,
				start: start.startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
				end: end
					.startOf('day')
					.add(1, 'days')
					.add(-1, 'seconds')
					.format('YYYY-MM-DD HH:mm:ss.SSS'),
				page,
				limit,
				q_car,
				q_registered,
				q_access_type,
			},
		},
		payload: {
			gateId: gateId,
		},
	}
}

export const getAccessesStatistics = (parkingId, gateId = null, start, end) => {
	if (start == null) {
		start = moment().add(-10, 'day')
	}
	if (end == null) {
		end = moment()
	}
	return {
		type: GET_ACCESSES_STATISTICS,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/access/getAccessesForStatistics`,
			params: {
				parking_id: parkingId,
				gate_id: gateId,
				start: start.startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
				end: end.startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
			},
		},
		payload: {
			gateId: gateId,
		},
	}
}

export const getAccessesStatisticsHourly = (
	parkingId,
	gateId = null,
	start,
	end
) => {
	if (start == null) {
		start = moment().add(-10, 'day')
	}
	if (end == null) {
		end = moment()
	}
	return {
		type: GET_ACCESSES_STATISTICS,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/access/getAccessesForStatisticsHourly`,
			params: {
				parking_id: parkingId,
				gate_id: gateId,
				start: start.startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
				end: end.startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
			},
		},
		payload: {
			gateId: gateId,
		},
	}
}

export const getAccesses = (
	parkingId,
	gateId = null,
	start,
	end,
	q_car,
	page = null,
	limit = null,
	loadMore = false
) => {
	if (start == null) {
		start = moment().add(-10, 'day')
	}
	if (end == null) {
		end = moment().endOf('day')
	}
	return {
		type: GET_ACCESSES,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/access/getAccesses`,
			params: {
				parking_id: parkingId,
				gate_id: gateId,
				start: start.startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
				end: end.format('YYYY-MM-DD HH:mm:ss.SSS'),
				page,
				limit,
				q_car,
			},
		},
		payload: {
			gateId: gateId,
			loadMore,
		},
	}
}

export const getAccessesCount = ({
	parkingId,
	gateId,
	start = null,
	end = null,
	q_car,
	q_registered = -1,
	q_access_type = -1,
}) => {
	return {
		type: GET_ACCESSES_COUNT,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/access/getAccessesCount`,
			params: {
				parking_id: parkingId,
				gate_id: gateId,
				start: start.startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
				end: end
					.startOf('day')
					.add(1, 'days')
					.add(-1, 'seconds')
					.format('YYYY-MM-DD HH:mm:ss.SSS'),
				q_car,
				q_registered,
				q_access_type,
			},
		},
		payload: {
			gateId: gateId,
		},
	}
}

export const receiveAccess = (gateId, item) => {
	return {
		type: RECEIVE_ACCESS,
		payload: {
			gateId,
			item,
		},
	}
}

export const receiveDeleteAccess = (gateId, item) => {
	return {
		type: RECEIVE_DELETE_ACCESS,
		payload: {
			gateId,
			item,
		},
	}
}

export const receiveUpdateAccess = (gateId, item) => {
	return {
		type: RECEIVE_UPDATE_ACCESS,
		payload: {
			gateId,
			item,
		},
	}
}

export const addAccess = (parkingId, access) => {
	return {
		type: ADD_ACCESS,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/access/addAccess`,
			data: {
				parking_id: parkingId,
				...access,
			},
		},
		payload: {
			access,
		},
	}
}

export const updateAccess = (parkingId, access) => {
	return {
		type: UPDATE_ACCESS,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/access/updateAccess`,
			data: {
				parking_id: parkingId,
				id: access._id,
				...access,
			},
		},
		payload: {
			access,
		},
	}
}

export const deleteAccess = (parkingId, access) => {
	return {
		type: DELETE_ACCESS,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/access/deleteAccess`,
			data: { parking_id: parkingId, id: access._id, gate_id: access.gateId },
		},
		payload: {
			gateId: access.gateId,
			id: access._id,
		},
	}
}

export const deleteAccesses = (parkingId, accessIds) => {
	return {
		type: DELETE_ACCESSES,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/access/deleteAccesses`,
			data: { parking_id: parkingId, ids: accessIds },
		},
	}
}

export const getAccessesNoLimit = (
	parkingId,
	gateId = null,
	start = moment().add(-1, 'hour'),
	end = null,
	q_car,
	q_registered = -1,
	q_access_type = -1
) => {
	return {
		type: GET_ACCESSES_NO_LIMIT,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/access/getAccesses`,
			params: {
				parking_id: parkingId,
				gate_id: gateId,
				start: start.startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
				end: end
					.startOf('day')
					.add(1, 'days')
					.add(-1, 'seconds')
					.format('YYYY-MM-DD HH:mm:ss.SSS'),
				page: 0,
				limit: 1000000,
				q_car,
				q_registered,
				q_access_type,
			},
		},
		payload: {
			gateId: gateId,
		},
	}
}

export const getMemos = (parkingId, carplate) => {
	return {
		type: GET_MEMOS,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/access/getMemos`,
			params: {
				parking_id: parkingId,
				carplate,
			},
		},
	}
}

export const clearMemos = () => {
	return {
		type: CLEAR_MEMOS,
	}
}

export const clearSingleAccess = () => {
	return {
		type: CLEAR_SINGLE_ACCESS,
	}
}
