import Config from '../config'

export const GET_CAR_STATUSES = 'GET_CAR_STATUSES'
export const GET_CAR_STATUSES_COUNT = 'GET_CAR_STATUSES_COUNT'
export const DELETE_CAR_STATUS = 'DELETE_CAR_STATUS'

export const getCarStatuses = ({
	parkingId,
	page = 0,
	limit = 20,
	direction = 0,
	q_car = '',
}) => {
	return {
		type: GET_CAR_STATUSES,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/query/getCarStatuses`,
			params: {
				parking_id: parkingId,
				page,
				limit,
				direction,
				q_car,
			},
		},
	}
}

export const getCarStatusesCount = ({
	parkingId,
	direction = 0,
	q_car = '',
}) => {
	return {
		type: GET_CAR_STATUSES_COUNT,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/query/getCarStatusesCount`,
			params: { parking_id: parkingId, direction, q_car },
		},
	}
}

export const deleteCarStatus = ({ parkingId, carId }) => {
	return {
		type: DELETE_CAR_STATUS,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/query/deleteCarStatus',
			data: {
				car_id: carId,
				parking_id: parkingId,
			},
		},
	}
}
