import {
	ADD_BLACKLIST,
	GET_BLACKLIST,
	UPDATE_BLACKLIST,
	DELETE_BLACKLIST,
	GET_BLACKLIST_COUNT
} from '../action/blacklist'

const REQUEST = '_REQUEST',
	SUCCESS = '_SUCCESS',
	FAILURE = '_FAILURE'

const defaultState = {
	blacklist: null,
	totalCount: 0,
	errorMessage: null,
	updating: false
}

const blacklistReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_BLACKLIST + REQUEST:
			return {
				...state,
				errorMessage: null
			}
		case GET_BLACKLIST + SUCCESS:
			return {
				...state,
				blacklist: action.result.data
			}
		case GET_BLACKLIST_COUNT + SUCCESS:
			return {
				...state,
				totalCount: action.result.data.count
			}
		case GET_BLACKLIST + FAILURE:
		case GET_BLACKLIST_COUNT + FAILURE:
			return {
				...state,
				errorMessage: action.error.response
			}

		case ADD_BLACKLIST + REQUEST:
		case UPDATE_BLACKLIST + REQUEST:
		case DELETE_BLACKLIST + REQUEST:
			return {
				...state,
				updating: true
			}
		case ADD_BLACKLIST + SUCCESS:
		case UPDATE_BLACKLIST + SUCCESS:
		case DELETE_BLACKLIST + SUCCESS:
		case ADD_BLACKLIST + FAILURE:
		case UPDATE_BLACKLIST + FAILURE:
		case DELETE_BLACKLIST + FAILURE:
			return {
				...state,
				updating: false
			}
		default: {
			return state
		}
	}
}

export default blacklistReducer
