import gate from './gate'
import user from './user'
import access from './access'
import registration from './registration'
import blacklist from './blacklist'
import device from './device'
import resident from './resident'
import ticket from './ticket'
import type from './type'
import functionReducer from './function'
import parkingInfo from './parkingInfo'
import query from './query'
import discount from './discount'
import overdue from './overdue'
import payment from './payment'
import qna from './qna'
import correctInfo from './correctInfo'
import manager from './manager'
import password from './password'
import unregistered from './unregistered'
import notice from './notice'
import carStatus from './car-status'
import dt from './dt'

import { combineReducers } from 'redux'
import { LOGOUT } from '../action/user'
const REQUEST = '_REQUEST'

const rootReducer = (state, action) => {
	if (action.type == LOGOUT + REQUEST) {
		state = undefined
	}
	return combineReducers({
		user,
		gate,
		access,
		registration,
		device,
		blacklist,
		resident,
		ticket,
		type,
		functionReducer,
		parkingInfo,
		query,
		discount,
		payment,
		qna,
		overdue,
		correctInfo,
		manager,
		password,
		unregistered,
		notice,
		carStatus,
		dt,
	})(state, action)
}
export default rootReducer
