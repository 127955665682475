import {
	GET_CAR_STATUSES,
	GET_CAR_STATUSES_COUNT,
	DELETE_CAR_STATUS,
} from '../action/car-status'

const REQUEST = '_REQUEST',
	SUCCESS = '_SUCCESS',
	FAILURE = '_FAILURE'

const defaultState = {
	cars: null,
	totalCarsCount: 0,
	errorMessage: [],
	updating: false,
}

const carStatusReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_CAR_STATUSES + REQUEST:
			return state
		case GET_CAR_STATUSES + SUCCESS:
			return {
				...state,
				cars: action.result.data,
			}
		case GET_CAR_STATUSES + FAILURE:
			return {
				...state,
				errorMessage: [...state.errorMessage, action.error.response],
			}

		case GET_CAR_STATUSES_COUNT + REQUEST:
			return state
		case GET_CAR_STATUSES_COUNT + SUCCESS:
			return {
				...state,
				totalCarsCount: action.result.data.count,
			}
		case GET_CAR_STATUSES_COUNT + FAILURE:
			return {
				...state,
				totalCarsCount: 0,
				errorMessage: [...state.errorMessage, action.error.response],
			}
		case DELETE_CAR_STATUS + REQUEST:
			return {
				...state,
				updating: true,
			}
		case DELETE_CAR_STATUS + SUCCESS:
			return {
				...state,
				updating: false,
			}
		case DELETE_CAR_STATUS + FAILURE:
			return {
				...state,
				updating: false,
				errorMessage: [
					...state.errorMessage,
					action.error.response.data.message,
				],
			}
		default: {
			return state
		}
	}
}

export default carStatusReducer
