import React from 'react'
import {
	Card,
	Button,
	Table,
	Row,
	Empty,
	Spin,
	Popover,
	Badge,
	Divider,
	Space,
	Tooltip,
	Popconfirm,
} from 'antd'
import { connect } from 'react-redux'
import moment from 'moment'
// import { blue } from '@ant-design/colors'
import axios from 'axios'

import { PlusOutlined, MoreOutlined, SwapOutlined } from '@ant-design/icons'

import { getImageUrl } from '../utils'

import { JSMpegVideoPlayer } from '.'
import { renderFunctions, renderName } from './utils'

const CancelToken = axios.CancelToken

class MonitoringGateSimple extends React.Component {
	constructor(props) {
		super(props)

		var gateOption = JSON.parse(
			localStorage.getItem(
				`${this.props.user.parking_id}.gates.${props.gate._id}`
			)
		)
		this.state = {
			selected: null,
			imgNotFound: false,
			width: 160,
			videoError: false,
			isVideo: gateOption != null ? gateOption.isVideo || false : false,
			imageIndex: 0,
			visibleThumbnail: false,
			thumbnailHeight: 0,
		}
	}
	cancel = null

	timeout = null

	externColumns = [
		{
			title: '동',
			dataIndex: 'dong',
			key: 'dong',
			responsive: ['lg'],
		},
		{
			title: '호',
			dataIndex: 'ho',
			key: 'ho',
			responsive: ['lg'],
		},
	]
	columns = [
		{
			title: '차량번호',
			render: (record) => {
				if (record.carplate === '미인식') {
					return (
						<Badge dot offset={[4, 0]}>
							<p style={{ fontSize: 16 }}>{record.carplate}</p>
						</Badge>
					)
				} else if (!record.isRegistered) {
					return (
						<p style={{ wordBreak: 'keep-all', display: 'flex', fontSize: 16 }}>
							{record.carplate}&nbsp;&nbsp;
							<Button
								size='small'
								shape='circle'
								icon={<PlusOutlined />}
								onClick={() => {
									const { gate, onClickAddCar } = this.props
									if (onClickAddCar) {
										onClickAddCar(gate, record)
									}
								}}
							></Button>
						</p>
					)
				} else {
					return <p style={{ fontSize: 16 }}>{record.carplate}</p>
				}
			},
		},
		{
			title: '이름',
			key: 'name',
			ellipsis: true,
			render: (record) => {
				return renderName(record, {
					privacyMode: this.props.privacyMode,
					layoutSetting: this.props.layoutSetting,
				})
			},
		},
		{
			title: '진입시간',
			dataIndex: 'accessTime',
			key: 'accessTime',
			width: 100,
			render: (accessTime) => {
				var style = {}
				if (moment(accessTime).get('dayOfYear') != moment().get('dayOfYear')) {
					style = { color: '#aaa' }
				}
				return (
					<Tooltip title={moment(accessTime).format('yyyy-MM-DD')}>
						<div style={style}>{moment(accessTime).format('HH:mm:ss')}</div>
					</Tooltip>
				)
			},
		},
		{
			title: '수정',
			render: (record) => {
				return (
					<Button
						shape='circle'
						size='small'
						icon={<MoreOutlined />}
						onClick={() => {
							const { gate, onClickEdit } = this.props
							if (onClickEdit) {
								onClickEdit(gate, record)
							}
						}}
					/>
				)
			},
			width: 60,
		},
	]

	componentDidMount() {
		const { accesses, height, layoutSetting } = this.props
		if (accesses != null) {
			this.setState({
				selected: accesses.length !== 0 ? accesses[0] : null,
			})
		}

		if (height != null) {
			this.setState({
				// width: (height * 4) / 3,
				width: layoutSetting.ratio16_9 ? height * 1.55 : height,
			})
		}
	}

	fitToContainer = (canvas) => {
		// Make it visually fill the positioned parent
		canvas.style.width = '100%'
		canvas.style.height = '100%'
		// ...then set the internal size to match
		canvas.width = canvas.offsetWidth
		canvas.height = canvas.offsetHeight
	}

	componentDidUpdate(prevProps, prevState) {
		const { accesses, onChangeDetail, gate, height, user, layoutSetting } =
			this.props
		if (accesses != null && accesses !== prevProps.accesses) {
			this.setState({
				selected: accesses.length !== 0 ? accesses[0] : null,
				visibleThumbnail: true,
			})
			if (this.timeout != null) {
				clearTimeout(this.timeout)
			}
			this.timeout = setTimeout(() => {
				this.setState({
					visibleThumbnail: false,
				})
			}, 3000)
		}
		if (
			(height != null && height !== prevProps.height) ||
			layoutSetting !== prevProps.layoutSetting
		) {
			this.setState({
				width: layoutSetting.ratio16_9 ? height * 1.55 : height,
			})
		}
		if (
			(prevState.selected !== this.state.selected &&
				this.state.selected != null) ||
			(prevState.isVideo == true &&
				this.state.isVideo == false &&
				this.state.selected != null) ||
			prevState.imageIndex !== this.state.imageIndex
		) {
			if (this.props.detailInfo != null) {
				if (onChangeDetail) {
					onChangeDetail({
						gate: gate,
						access: this.state.selected,
						url: getImageUrl(
							user,
							this.state.selected._id,
							this.state.imageIndex
						),
					})
				}
			}

			if (this.cancel) {
				this.cancel()
			}
			if (this.state.selected != null) {
				axios
					.get(
						getImageUrl(user, this.state.selected._id, this.state.imageIndex),
						{
							responseType: 'arraybuffer',
							cancelToken: new CancelToken((c) => (this.cancel = c)),
						}
					)
					.then((res) => {
						try {
							var base64 = new Buffer(res.data, 'binary').toString('base64')
							if (this.refs.img != null) {
								var ctx = this.refs.img.getContext('2d')
								var img = new Image()
								img.onload = () => {
									if (this.refs.img == null) {
										return
									}

									var ratio = img.width / img.height
									var newHeight = this.state.width / 2 / ratio
									this.setState({ thumbnailHeight: newHeight })

									this.fitToContainer(this.refs.img)
									if (img && this.refs.img) {
										ctx.drawImage(
											img,
											0,
											0,
											this.refs.img.width,
											this.refs.img.height
										)
										img.src = ''
									}
								}
								img.src = 'data:image/jpeg;base64,' + base64
							}
							this.setState({
								imgNotFound: false,
							})
						} catch (err) {
							this.setState({
								imgNotFound: true,
							})
						}
					})
					.catch((err) => {
						if (axios.isCancel(err)) {
						} else {
							console.error(err)
							this.setState({
								imgNotFound: true,
							})
						}
					})
			}
		}
	}

	componentWillUnmount() {}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			this.props.accesses === nextProps.accesses &&
			this.props.user === nextProps.user &&
			this.props.height === nextProps.height &&
			this.props.functions === nextProps.functions &&
			this.props.types === nextProps.types &&
			this.props.fullsize === nextProps.fullsize &&
			this.state.selected === nextState.selected &&
			this.state.width === nextState.width &&
			this.state.imgNotFound === nextState.imgNotFound &&
			this.state.videoError === nextState.videoError &&
			this.state.showDropdown === nextState.showDropdown &&
			this.state.isVideo === nextState.isVideo &&
			this.state.imageIndex === nextState.imageIndex &&
			this.state.visibleThumbnail === nextState.visibleThumbnail &&
			this.props.showLoopDetecting === nextProps.showLoopDetecting &&
			this.props.showOpeningGate === nextProps.showOpeningGate &&
			this.props.layoutSetting === nextProps.layoutSetting
		) {
			return false
		}

		return true
	}

	functionColors = {
		auto_pass: 'magenta',
		business_pass: 'cyan',
		registration_pass: 'green',
		force_open: 'orange',
		payment_exit: 'lime',
	}

	handleOnClickImage = () => {
		const { selected } = this.state
		const { onChangeDetail, gate, user } = this.props
		if (onChangeDetail && selected) {
			onChangeDetail({
				gate: gate,
				access: selected,
				url: getImageUrl(user, selected._id, this.state.imageIndex),
			})
		}
	}

	handleOnClickVideo = (url) => {
		const { onChangeDetail, gate } = this.props
		if (onChangeDetail) {
			onChangeDetail({
				gate: gate,
				url: url,
			})
		}
	}

	handleOnClickRow = (record) => {
		this.setState({
			selected: record,
			visibleThumbnail: true,
		})

		if (this.timeout != null) {
			clearTimeout(this.timeout)
		}
		this.timeout = setTimeout(() => {
			this.setState({
				visibleThumbnail: false,
			})
		}, 3000)
	}

	render() {
		const {
			gate,
			height,
			accesses,
			types,
			functions,
			loading,
			cameras,
			onClickLoadMore,
			fullsize,
			showLoopDetecting = false,
			showOpeningGate = false,
			layoutSetting,
		} = this.props
		const { selected, imgNotFound, width, videoError, isVideo, imageIndex } =
			this.state

		types
			.filter((x) => x.category === 'LPR_FUNCTION_STATE')
			.map((x, i) => {
				x.index = i
				x.enabled =
					functions != null &&
					functions.findIndex((y) => y.functionId === x._id && y.enabled) !== -1
				return x
			})
			.filter((x) => {
				return (
					functions != null &&
					functions.findIndex((y) => y.functionId === x._id) !== -1
				)
			})

		if (gate == null) {
			return (
				<div
					style={{
						height: height,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Spin />
				</div>
			)
		}
		return (
			<Card
				tabIndex={0}
				onKeyPress={(e) => {}}
				onKeyDown={(e) => {
					if (e.keyCode >= 37 && e.keyCode <= 40) {
						e.preventDefault()
						if (accesses == null) {
							return
						}
						var index = 0
						if (selected == null) {
							index = 0
						} else {
							index = accesses.indexOf(selected)
						}
						switch (e.keyCode) {
							case 37:
							case 38:
								var newIndex = index - 1
								if (newIndex < 0) {
									newIndex = 0
								}
								this.setState({
									selected: accesses[newIndex],
									visibleThumbnail: true,
								})
								if (this.timeout != null) {
									clearTimeout(this.timeout)
								}
								this.timeout = setTimeout(() => {
									this.setState({
										visibleThumbnail: false,
									})
								}, 3000)
								break
							case 39:
							case 40:
								newIndex = index + 1
								if (newIndex >= accesses.length) {
									newIndex = accesses.length - 1
								}
								this.setState({
									selected: accesses[newIndex],
									visibleThumbnail: true,
								})
								if (this.timeout != null) {
									clearTimeout(this.timeout)
								}
								this.timeout = setTimeout(() => {
									this.setState({
										visibleThumbnail: false,
									})
								}, 3000)
								break
							default:
								break
						}
					}
				}}
				size='small'
				headStyle={{ fontWeight: 'bold' }}
				style={{ height: height }}
				title={
					<Space>
						<h3>{gate.name}</h3>
						{showOpeningGate && (
							<Tooltip title='차단기 열림'>
								<Badge color={'#52c41a'} />
							</Tooltip>
						)}
						{showLoopDetecting && (
							<Tooltip title='루프 검지'>
								<Badge color={'gold'} />
							</Tooltip>
						)}
					</Space>
				}
				bordered={true}
				extra={renderFunctions(this.props)}
				bodyStyle={{ padding: 0 }}
			>
				{loading ? (
					<div
						style={{
							height: height,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Spin />
					</div>
				) : (
					<Row type='flex' style={{ flexWrap: 'nowrap', overflow: 'hidden' }}>
						<div
							style={{
								borderRightWidth: 1,
								borderRightColor: '#eee',
								borderRightStyle: 'solid',
								position: 'relative',
							}}
						>
							{' '}
							{!(
								cameras.length == 0 ||
								cameras[imageIndex] == null ||
								cameras[imageIndex].streamingUrl == null ||
								cameras[imageIndex].streamingUrl == ''
							) && (
								<Button
									style={{
										position: 'absolute',
										right: 10,
										top: 10,
										zIndex: 11,
									}}
									onClick={() => {
										this.setState({
											isVideo: !this.state.isVideo,
										})
										localStorage.setItem(
											`${this.props.user.parking_id}.gates.${gate._id}`,
											JSON.stringify({
												isVideo: !this.state.isVideo,
											})
										)
									}}
									icon={<SwapOutlined />}
								>
									{height < 200 ? '' : isVideo ? '사진 전환' : '영상 전환'}
								</Button>
							)}
							<div
								style={{ position: 'absolute', top: 8, left: 4, zIndex: 11 }}
							>
								{cameras.length > 1 &&
									cameras.map((item, index) => {
										return (
											<Button
												key={'camera-' + index}
												type={
													this.state.imageIndex == index ? 'primary' : 'default'
												}
												shape='circle'
												size='small'
												style={{ marginLeft: 4 }}
												onClick={() => {
													this.setState({
														imageIndex: index,
													})
												}}
											>
												{index + 1}
											</Button>
										)
									})}
							</div>
							{isVideo ? (
								<div
									style={{
										height: height - 52,
										width: width,
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									{videoError || cameras.length === 0 ? (
										<Empty
											description='No Video'
											image={Empty.PRESENTED_IMAGE_SIMPLE}
										/>
									) : cameras.length !== 0 &&
									  cameras[this.state.imageIndex].streamingUrl !== null &&
									  cameras[this.state.imageIndex].streamingUrl.startsWith(
											'http'
									  ) ? (
										<iframe
											title={cameras[this.state.imageIndex]._id}
											style={{ border: 0 }}
											src={cameras[this.state.imageIndex].streamingUrl}
											scrolling='no'
											height={height - 52}
											width={width}
											allowfullscreen='true'
											webkitallowfullscreen='true'
											mozallowfullscreen='true'
										></iframe>
									) : (
										<JSMpegVideoPlayer
											url={
												cameras.length === 0
													? null
													: cameras[imageIndex].streamingUrl
											}
											style={{ height: height, width: width }}
										/>
									)}
									<div
										style={{
											position: 'absolute',
											bottom: 10,
											right: 10,
											width: width / 2,
											height: this.state.thumbnailHeight,
											zIndex: 10,
											transition: 'opacity 500ms',
											opacity: this.state.visibleThumbnail ? 1 : 0,
											cursor: this.state.visibleThumbnail ? 'auto' : 'none',
											pointerEvents: this.state.visibleThumbnail
												? 'auto'
												: 'none',
										}}
									>
										<canvas
											ref={'img'}
											onClick={this.handleOnClickImage}
											style={{
												height: imgNotFound ? 0 : '100%',
												width: '100%',
											}}
										/>
									</div>
								</div>
							) : (
								<div
									ref='imageContainer'
									style={{
										height: height - 52,
										width: width,
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										minWidth: 160,
									}}
								>
									<div
										style={{
											width: width,
											height: '100%',
											textAlign: 'center',
											borderBottomWidth: 1,
											borderBottomColor: '#eee',
											borderBottomStyle: 'solid',
										}}
									>
										<canvas
											ref={'img'}
											onClick={this.handleOnClickImage}
											style={{
												height: imgNotFound ? 0 : '100%',
												width: width,
											}}
										/>
										<Empty
											description='No Image'
											image={Empty.PRESENTED_IMAGE_SIMPLE}
											style={{
												visibility: imgNotFound ? 'visible' : 'collapse',
											}}
										/>
									</div>
								</div>
							)}
						</div>
						<div style={{ flex: 1, height: height - 52, overflowY: 'auto' }}>
							{accesses != null && (
								<Table
									size={'small'}
									columns={(fullsize
										? [
												...this.columns.slice(0, 2),
												...this.externColumns,
												...this.columns.slice(2, 5),
										  ]
										: this.columns
									).filter((col) => {
										if (col.key == 'dong' && !layoutSetting.showDong) {
											return false
										}
										if (col.key == 'ho' && !layoutSetting.showHo) {
											return false
										}
										if (col.key == 'name' && !layoutSetting.showName) {
											return false
										}
										return true
									})}
									dataSource={accesses}
									pagination={false}
									useFixedHeader={true}
									style={{ fontSize: 16 }}
									onRow={(record, rowIndex) => {
										return {
											onClick: () => {
												this.handleOnClickRow(record)
											},
										}
									}}
									rowClassName={(record) =>
										this.state.selected == record ? 'selected-item' : ''
									}
									rowKey={(record) => {
										return record._id
									}}
									footer={() =>
										accesses.length > 0 && (
											<div style={{ textAlign: 'center' }}>
												<Button
													type='link'
													onClick={() => {
														if (onClickLoadMore) {
															onClickLoadMore(
																gate,
																accesses[accesses.length - 1].accessTime
															)
														}
													}}
												>
													더 보기
												</Button>
											</div>
										)
									}
								/>
							)}
						</div>
					</Row>
				)}
			</Card>
		)
	}
}

function mapStateToProps(state) {
	return { user: state.user.user }
}

export default connect(mapStateToProps)(MonitoringGateSimple)
