import React from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Layout, Input, Button, Select, Modal, Alert } from 'antd'

import { connect } from 'react-redux'

import moment from 'moment'

import { Link } from 'react-router-dom'
import {
	getTicketSetting,
	getTicketInfos,
	issueTicket,
} from '../../action/ticket'
import { CarOutlined, CheckOutlined, FormOutlined } from '@ant-design/icons'

const { Content } = Layout
const { Option } = Select

class IssueByAdmin extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			winHeight: 0,
			selectedTicket: null,
			carNumber: '',
			memo: '',
		}
	}

	componentDidUpdate(prevProps) {
		const { user, dispatch, ticket, carplate } = this.props
		if (user != null && user !== prevProps.user) {
			dispatch(getTicketSetting(user.parking_id))
			dispatch(getTicketInfos(user.parking_id))
		}

		if (prevProps.ticket.updating && ticket.updating == false) {
			if (ticket.errorMessage == null) {
				Modal.success({
					title: '성공',
					content: '임시방문증이 등록되었습니다.',
					maskClosable: true,
					onOk() {},
				})
				this.setState({
					carNumber: '',
					memo: '',
					selectedTimeIndex: 0,
				})
			} else {
				Modal.error({
					maskClosable: true,
					title: '실패',
					content: ticket.errorMessage,
					onOk() {},
				})
			}
		}
		if (
			ticket.tickets != null &&
			ticket.tickets.length > 0 &&
			this.state.selectedTicket == null
		) {
			this.setState({
				selectedTicket: ticket.tickets[0]._id,
			})
		}

		if (carplate != null && this.state.carNumber != carplate) {
			this.setState({
				carNumber: carplate,
			})
		}
	}

	componentDidMount() {
		const { user, dispatch, carplate } = this.props
		if (user != null) {
			dispatch(getTicketSetting(user.parking_id))
			dispatch(getTicketInfos(user.parking_id))
		}
		window.addEventListener('resize', this.handleResize)

		setTimeout(() => {
			this.handleResize()
		}, 50)

		if (carplate != null) {
			this.setState({
				carNumber: carplate,
			})
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize)
	}

	handleResize = (e) => {
		if (this.refs.container != null) {
			this.setState({
				winHeight: this.refs.container.clientHeight,
			})
		}
	}

	handleTicketChanged = (ticket) => {
		this.setState({
			selectedTicket: ticket,
		})
	}

	issue = () => {
		const { user, dispatch } = this.props
		const { carNumber, memo, selectedTicket } = this.state

		if (carNumber == '') {
			Modal.error({
				maskClosable: true,
				title: '실패',
				content: '차량번호를 입력하셔야 합니다.',
				onOk() {},
			})
			return
		}

		if (selectedTicket == null) {
			Modal.error({
				maskClosable: true,
				title: '실패',
				content: '방문증을 선택해주세요.',
				onOk() {},
			})
			return
		}

		dispatch(
			issueTicket(user.parking_id, {
				ticket_id: selectedTicket,
				carplate: carNumber,
				memo: memo,
			})
		)
		// issueTicket({
		// 	parkingId: user.parking_id,
		// 	carNumber,
		// 	memo,
		// 	duration: selectedTicket,
		// })
	}

	render() {
		const { carNumber, memo, selectedTicket } = this.state
		const { dispatch, ticket } = this.props

		return (
			<Content
				style={{
					background: '#fff',
					margin: 0,
					display: 'flex',
				}}
			>
				<div ref={'container'} style={{ flex: 1 }}>
					<Form style={{ marginLeft: 16, marginRight: 16, marginTop: 16 }}>
						<Form.Item>
							<Input
								size='large'
								prefix={<CarOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
								placeholder='차량번호를 입력하세요.'
								value={carNumber}
								onChange={(e) => {
									this.setState({ carNumber: e.target.value })
								}}
							/>
						</Form.Item>
						<Form.Item>
							<Input
								size='large'
								prefix={<FormOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
								placeholder='방문 목적이나 요청한 동/호 수 등 참고사항을 입력하세요. (선택)'
								value={memo}
								onChange={(e) => {
									this.setState({ memo: e.target.value })
								}}
							/>
						</Form.Item>
						<Form.Item>
							<Select
								size='large'
								onChange={this.handleTicketChanged}
								value={selectedTicket}
								notFoundContent={
									<p style={{ padding: 16 }}>
										먼저 방문증 설정에서 방문증을 추가해주세요.{'   '}
										<Link to='/ticket/setting'>[바로가기]</Link>
									</p>
								}
							>
								{ticket.tickets != null &&
									ticket.tickets.map((obj) => (
										<Option key={obj._id} value={obj._id}>
											{`${obj.name}(${obj.duration}시간)`}
										</Option>
									))}
							</Select>
						</Form.Item>

						<Button
							type={'primary'}
							icon={<CheckOutlined />}
							onClick={() => {
								this.issue()
							}}
							loading={ticket.updating}
							style={{ marginBottom: 8 }}
						>
							등록하기
						</Button>
						<br />
						<br />
						<Alert
							message='현재 페이지에서 관리자 발급 시에는 방문증 설정의 발급제한과 상관 없이 발급 가능합니다.'
							type={'info'}
							showIcon
						/>
					</Form>
				</div>
			</Content>
		)
	}
}

function mapStateToProps(state) {
	return {
		ticket: state.ticket,
		user: state.user.user,
	}
}

export default connect(mapStateToProps)(Form.create()(IssueByAdmin))
