import {
	ADD_RESIDENT,
	GET_RESIDENTS,
	UPDATE_RESIDENT,
	DELETE_RESIDENTS,
	GET_RESIDENTS_COUNT
} from '../action/resident'

const REQUEST = '_REQUEST',
	SUCCESS = '_SUCCESS',
	FAILURE = '_FAILURE'

const defaultState = {
	residents: null,
	total: 0,
	errorMessage: null,
	updating: false
}

const residentReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_RESIDENTS + SUCCESS:
			return {
				...state,
				residents: action.result.data
			}
		case GET_RESIDENTS_COUNT + SUCCESS:
			return {
				...state,
				total: action.result.data.count
			}
		case GET_RESIDENTS + FAILURE:
		case GET_RESIDENTS_COUNT + FAILURE:
			return {
				...state,
				errorMessage: action.error.response
			}

		case ADD_RESIDENT + REQUEST:
		case UPDATE_RESIDENT + REQUEST:
		case DELETE_RESIDENTS + REQUEST:
			return {
				...state,
				updating: true
			}
		case ADD_RESIDENT + SUCCESS:
		case UPDATE_RESIDENT + SUCCESS:
		case DELETE_RESIDENTS + SUCCESS:
			return {
				...state,
				updating: false
			}
		case ADD_RESIDENT + FAILURE:
			return {
				...state,
				updating: false,
				errorMessage: '중복된 동, 호수를 확인해주세요.'
			}
		case UPDATE_RESIDENT + FAILURE:
		case DELETE_RESIDENTS + FAILURE:
			return {
				...state,
				updating: false,
				errorMessage: action.error.response
			}
		default: {
			return state
		}
	}
}

export default residentReducer
