import React from 'react'
import {
	PlusOutlined,
	CheckOutlined,
	MoreOutlined,
	DownOutlined,
} from '@ant-design/icons'
import {
	Card,
	Button,
	Table,
	Col,
	Row,
	Empty,
	Spin,
	Popover,
	Dropdown,
	Menu,
	Tag,
	Badge,
} from 'antd'
import { connect } from 'react-redux'
import moment from 'moment'

import { getImageUrl } from '../utils'

import { JSMpegVideoPlayer, VideoPlayer } from '.'

class MonitoringGateVideo extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			selected: null,
			imgNotFound: false,
			width: 160,
			videoError: false,
		}
	}

	componentDidMount() {
		const { accesses, camera, height } = this.props
		if (accesses != null) {
			this.setState({
				selected: accesses.length !== 0 ? accesses[0] : null,
			})
		}

		if (height != null) {
			this.setState({
				width: (((height - 52) / 2) * 4) / 3,
			})
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { accesses, onChangeDetail, gate, camera, height, user } = this.props
		if (accesses != null && accesses !== prevProps.accesses) {
			this.setState({
				selected: accesses.length !== 0 ? accesses[0] : null,
			})
		}
		if (height != null && height !== prevProps.height) {
			this.setState({
				width: (((height - 52) / 2) * 4) / 3,
			})
		}
		if (prevState.selected !== this.state.selected) {
			if (this.props.detailInfo != null) {
				if (onChangeDetail) {
					onChangeDetail({
						gate: gate,
						access: this.state.selected,
						url: getImageUrl(user, this.state.selected._id),
					})
				}
			}
		}
		// if (camera != null && this.player == null) {
		// 	this.initJsmpeg(camera)
		// }
	}

	componentWillUnmount() {
		// if (this.player) {
		// 	try {
		// 		this.player.stop()
		// 		this.player.destroy()
		// 		console.log('player destroyed')
		// 	} catch (err) {
		// 		console.log(err)
		// 	}
		// }
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			this.props.accesses === nextProps.accesses &&
			this.props.user === nextProps.user &&
			this.props.height === nextProps.height &&
			this.state.selected === nextState.selected &&
			this.state.width === nextState.width &&
			this.state.imgNotFound === nextState.imgNotFound &&
			this.state.videoError === nextState.videoError
		) {
			return false
		}

		return true
	}

	renderExtra = () => {
		const {
			types,
			functions,
			onChangeFunction,
			onClickFunction,
			showManualInsert,
		} = this.props

		const stateFunctions = types
			.filter((x) => x.category === 'LPR_FUNCTION_STATE')
			.reduce((acc, item, i) => {
				if (
					functions == null ||
					functions.findIndex((x) => x.functionId === item._id) === -1
				) {
					return acc
				}
				acc[item._id] = item
				return acc
			}, {})

		const controlFunctions = types
			.filter((x) => x.category === 'LPR_FUNCTION_CONTROL')
			.reduce((acc, item, i) => {
				if (
					functions == null ||
					functions.findIndex((x) => x.functionId === item._id) === -1
				) {
					return acc
				}
				acc[item._id] = item
				return acc
			}, {})
		return (
			<div style={{ flex: 1, flexDirection: 'row' }}>
				<Button type={'link'} onClick={showManualInsert}>
					수동 입차
				</Button>
				<Dropdown
					onVisibleChange={(flag) => {
						this.setState({
							showDropdown: flag,
						})
					}}
					open={this.state.showDropdown}
					overlay={
						<Menu
							style={{ width: '100%' }}
							forceSubMenuRender={true}
							onClick={(e) => {
								if (stateFunctions[e.key] == null) {
									return
								}
								var index = functions.findIndex((x) => x.functionId == e.key)
								if (index !== -1) {
									var item = Object.assign({}, functions[index])
									item.enabled = !item.enabled
									if (onChangeFunction) {
										onChangeFunction(item)
									}
								}
							}}
						>
							<Menu.ItemGroup title='상태' className='list-gate'>
								{Object.keys(stateFunctions).length !== 0 ? (
									Object.keys(stateFunctions).map((key, i) => {
										var item = stateFunctions[key]
										var visible =
											functions != null &&
											functions.findIndex(
												(x) => x.functionId === item._id && x.enabled
											) !== -1
										return (
											<Menu.Item
												key={item._id.toString()}
												style={{
													listStyleType: 'none',
												}}
											>
												{item.name.split('|')[1]}
												<CheckOutlined
													style={{
														float: 'right',
														marginLeft: 16,
														visibility: visible ? 'visible' : 'hidden',
													}}
												/>
											</Menu.Item>
										)
									})
								) : (
									<div style={{ padding: 16 }}>
										설정되어 있는 상태 기능이 없습니다.
									</div>
								)}
							</Menu.ItemGroup>
							<Menu.Divider />
							<Menu.ItemGroup title='제어' className='list-gate'>
								{Object.keys(controlFunctions).length !== 0 ? (
									Object.keys(controlFunctions).map((key, i) => {
										var item = controlFunctions[key]
										return (
											<Menu.Item
												style={{
													listStyleType: 'none',
												}}
												onClick={() => {
													if (onClickFunction) {
														onClickFunction(item)
													}
													this.setState({
														showDropdown: false,
													})
												}}
												key={item._id.toString()}
											>
												{item.name}
											</Menu.Item>
										)
									})
								) : (
									<div style={{ padding: 16 }}>
										설정되어 있는 제어 기능이 없습니다.
									</div>
								)}
							</Menu.ItemGroup>
						</Menu>
					}
					trigger={['hover']}
				>
					<Button
						style={{ width: 120 }}
						onClick={() => {
							this.setState({
								showDropdown: true,
							})
						}}
					>
						출입문 설정 <DownOutlined />
					</Button>
				</Dropdown>
			</div>
		)
	}

	handleOnClickImage = () => {
		const { selected } = this.state
		const { onChangeDetail, gate, user } = this.props
		if (onChangeDetail && selected) {
			onChangeDetail({
				gate: gate,
				access: selected,
				url: getImageUrl(user, selected._id),
			})
		}
	}
	handleOnClickRow = (record) => {
		this.setState({
			selected: record,
		})
	}

	render() {
		const { gate, height, accesses, types, functions, loading, camera } =
			this.props
		const { selected, imgNotFound, width, videoError } = this.state

		types
			.filter((x) => x.category === 'LPR_FUNCTION_STATE')
			.map((x, i) => {
				x.index = i
				return x
			})
			.filter((x) => {
				return (
					functions != null &&
					functions.findIndex((y) => y.functionId === x._id && y.enabled) !== -1
				)
			})

		if (gate == null) {
			return (
				<div
					style={{
						height: height,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Spin />
				</div>
			)
		}

		return (
			<Card
				tabIndex={0}
				onKeyPress={(e) => {}}
				onKeyDown={(e) => {
					if (e.keyCode >= 37 && e.keyCode <= 40) {
						e.preventDefault()
						if (accesses == null) {
							return
						}
						var index = 0
						if (selected == null) {
							index = 0
						} else {
							index = accesses.indexOf(selected)
						}
						switch (e.keyCode) {
							case 37:
							case 38:
								var newIndex = index - 1
								if (newIndex < 0) {
									newIndex = 0
								}
								this.setState({
									selected: accesses[newIndex],
								})
								break
							case 39:
							case 40:
								newIndex = index + 1
								if (newIndex >= accesses.length) {
									newIndex = accesses.length - 1
								}
								this.setState({
									selected: accesses[newIndex],
								})
								break
							default:
								break
						}
					}
				}}
				size='small'
				headStyle={{ fontWeight: 'bold' }}
				style={{ height: height }}
				bordered={false}
				bodyStyle={{ padding: 0 }}
			>
				{loading ? (
					<div
						style={{
							height: height,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Spin />
					</div>
				) : (
					<Row type='flex' style={{ flexWrap: 'nowrap', overflow: 'hidden' }}>
						<Col span={24}>
							<div
								style={{
									position: 'absolute',
									left: 0,
									right: 0,
									textAlign: 'center',
								}}
							>
								<p className='shadow-text'>{gate.name}</p>
							</div>
							<div
								style={{
									height: height,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								{videoError || camera == null ? (
									<Empty
										description='No Video'
										image={Empty.PRESENTED_IMAGE_SIMPLE}
									/>
								) : camera.streamingUrl != null &&
								  camera.streamingUrl.startsWith('http') ? (
									<iframe
										title={camera._id}
										style={{ border: 0 }}
										src={camera.streamingUrl}
										scrolling='no'
										height={'100%'}
										width={'100%'}
									></iframe>
								) : (
									<JSMpegVideoPlayer
										url={camera == null ? null : camera.streamingUrl}
										style={{ height: (height - 52) / 2, width: width }}
									/>
								)}
							</div>
						</Col>
					</Row>
				)}
			</Card>
		)
	}
}

function mapStateToProps(state) {
	return { user: state.user.user }
}

export default connect(mapStateToProps)(MonitoringGateVideo)
