import Config from '../config'

export const GET_GATES = 'GET_GATES'

export const getGates = (parkingId) => {
	return {
		type: GET_GATES,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + '/access/getGates',
			params: {
				parking_id: parkingId,
			},
		},
	}
}
