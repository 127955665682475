import {
	GET_QUERIED,
	GET_QUERIED_COUNT,
	DELETE_QUERIED
} from '../action/query.js'

import moment from 'moment'

const REQUEST = '_REQUEST',
	SUCCESS = '_SUCCESS',
	FAILURE = '_FAILURE'

const defaultState = {
	fetchingUpdate: false,
	queried: [],
	total: 0,
	updating: false,
	errorMessage: null
}

const queryReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_QUERIED + REQUEST:
			return {
				...state,
				fetchingUpdate: true,
				errorMessage: null
			}
		case GET_QUERIED + SUCCESS:
			return {
				...state,
				fetchingUpdate: false,
				queried: action.result.data,
				errorMessage: null
			}
		case GET_QUERIED + FAILURE:
			return {
				...state,
				fetchingUpdate: false,
				errorMessage: action.error.response
			}

		case GET_QUERIED_COUNT + SUCCESS:
			return {
				...state,
				total: action.result.data.count
			}
		case DELETE_QUERIED + REQUEST:
			return {
				...state,
				updating: true
			}
		case DELETE_QUERIED + SUCCESS:
		case DELETE_QUERIED + FAILURE:
			return {
				...state,
				updating: false
			}
		default: {
			return state
		}
	}
}

export default queryReducer
