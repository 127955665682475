import Config from '../config'

export const GET_SETTING = 'GET_SETTING'
export const UPDATE_SETTING = 'UPDATE_SETTING'

export const GET_LATEST_ENTERED = 'GET_LATEST_ENTERED'
export const GET_REMAINS = 'GET_REMAINS'
export const GET_PAYMENT = 'GET_PAYMENT'
export const GET_PAYMENTS = 'GET_PAYMENTS'
export const RECEIVE_PAYMENT = 'RECEIVE_PAYMENT'
export const UPDATE_PAYMENT = 'UPDATE_PAYMENT'
export const MODIFY_CARPLATE = 'MODIFY_CARPLATE'
export const DELETED_PAYMENT = 'DELETED_PAYMENT'

export const getSetting = () => {
	return {
		type: GET_SETTING,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/payment/getSetting`,
		},
	}
}
export const updateSetting = (setting) => {
	return {
		type: UPDATE_SETTING,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/payment/updateSetting`,
			data: setting,
		},
	}
}

export const getLatestEntered = (carplate) => {
	return {
		type: GET_LATEST_ENTERED,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/payment/getLatestEntered`,
			params: {
				carplate,
			},
		},
	}
}

export const getRemains = () => {
	return {
		type: GET_REMAINS,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/payment/getRemains`,
		},
	}
}

export const getPayment = (exited) => {
	return {
		type: GET_PAYMENT,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/payment/getPaymentByExited`,
			params: {
				exited,
			},
		},
	}
}
export const getPayments = (exited) => {
	return {
		type: GET_PAYMENTS,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/payment/getPayments`,
		},
	}
}

export const receivePayment = (item) => {
	return {
		type: RECEIVE_PAYMENT,
		payload: {
			item,
		},
	}
}

export const updatePayment = (item) => {
	return {
		type: UPDATE_PAYMENT,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/payment/updatePayment`,
			data: item,
		},
	}
}

export const modifyCarplate = (id, carplate) => {
	return {
		type: MODIFY_CARPLATE,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + `/payment/modifyCarplate`,
			data: {
				paymentId: id,
				carplate,
			},
		},
	}
}

export const deletedPayment = (id) => {
	return {
		type: DELETED_PAYMENT,
		payload: {
			id,
		},
	}
}
