module.exports = {
	SERVER_URL:
		// window.location.protocol.indexOf('https') != -1
		// 	? 'https://ticket.securitynmap.com:3443'
		// 	: 'http://ticket.securitynmap.com:3001',
		// '/api',
		window.location.hostname == 'localhost' ||
		window.location.hostname == '192.168.10.97'
			? 'https://ticket.securitynmap.com:3443'
			: 'https://' + window.location.hostname + '/api',
	// 'https://ticket.securitynmap.com:3443',
	// 'https://ticket.securitynmap.com:3443',
	// 'https://127.0.0.1:3444',
	SOCKET_SERVER_URL: 'https://ticket.securitynmap.com:3443',
	//'https://127.0.0.1:3444',
	// 'https://ticket.securitynmap.com:3443',
}
