import { GET_MANAGERS, ADD_MANAGER, DELETE_MANAGER } from '../action/manager'

const REQUEST = '_REQUEST',
	SUCCESS = '_SUCCESS',
	FAILURE = '_FAILURE'

const defaultState = {
	managers: [],
	updating: false,
	errorMessage: null,
}

const managerReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_MANAGERS + SUCCESS:
			return {
				...state,
				managers: action.result.data,
			}
		case ADD_MANAGER + REQUEST:
			return {
				...state,
				updating: true,
			}
		case ADD_MANAGER + SUCCESS:
			return {
				...state,
				managers: [
					...state.managers,
					{
						_id: action.result.data.managerId,
						loginID: action.payload.id,
						role: action.payload.role,
						name: action.payload.name,
					},
				],
				errorMessage: null,
				updating: false,
			}
		case ADD_MANAGER + FAILURE:
			return {
				...state,
				errorMessage: action.error.response.data.message,
				updating: false,
			}
		case DELETE_MANAGER + REQUEST:
			return {
				...state,
				updating: true,
			}
		case DELETE_MANAGER + SUCCESS:
			return {
				...state,
				managers: state.managers.filter(
					(x) => x._id != action.payload.managerId
				),
				errorMessage: null,
				updating: false,
			}
		case DELETE_MANAGER + FAILURE:
			return {
				...state,
				errorMessage: action.error.response,
				updating: false,
			}
		default: {
			return state
		}
	}
}

export default managerReducer
