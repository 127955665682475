import React, { useState } from 'react'
import { Modal, Input, Table, Progress } from 'antd'
import axios from 'axios'
import Config from '../config'

const { Column } = Table

class ImportExcelModal extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isUploading: false,
			totalProgress: 100,
			currentProgress: 0,
			isCanceled: false,
			isCompleted: false,
		}
	}
	componentDidUpdate(prevProps) {}

	shouldComponentUpdate(nextProps, nextState) {
		const { visible, onOk, onCancel, data, loading } = this.props
		if (visible == false && nextProps.visible == false) {
			return false
		}
		return true
	}

	async start(data) {
		for (var key in data) {
			if (this.state.isCanceled) {
				break
			}
			var item = data[key]
			this.setState({ currentProgress: this.state.currentProgress + 1 })
			const response = await axios.post(Config.SERVER_URL + '/car/addCar', {
				parking_id: this.props.user.parking_id,
				car: item.car,
				dong: item.dong,
				ho: item.ho,
				name: item.name,
				phone: item.phone,
				memo: item.memo,
				start: item.start,
				expire: item.expire,
			})
		}
		this.setState({ isCompleted: true })
	}
	render() {
		const { isUploading, currentProgress, totalProgress, isCompleted } =
			this.state
		const { visible, onOk, onCancel, data, loading } = this.props

		return (
			<Modal
				title={'액셀로 불러오기'}
				width={960}
				centered
				open={visible}
				onOk={() => {
					if (isCompleted) {
						this.setState({
							isCanceled: true,
							isUploading: false,
							isCompleted: false,
							totalProgress: 100,
							currentProgress: 0,
						})
						onOk()
					} else {
						this.setState(
							{
								isUploading: true,
								isCanceled: false,
								isCompleted: false,
								totalProgress: data.length,
								currentProgress: 0,
							},
							() => {
								this.start(data)
							}
						)
					}
				}}
				onCancel={() => {
					this.setState({
						isCanceled: true,
						isUploading: false,
						isCompleted: false,
						totalProgress: 100,
						currentProgress: 0,
					})
					onCancel()
				}}
				cancelText={'취소'}
				okText={isCompleted ? '완료' : isUploading ? '등록 중' : '등록 시작'}
				okButtonProps={{ loading: isUploading && !isCompleted }}
			>
				{isUploading ? (
					<div>
						<Progress
							percent={((100 * currentProgress) / totalProgress).toFixed(1)}
						/>
						{currentProgress == totalProgress ? (
							<div>등록 완료</div>
						) : (
							<div>
								등록 중... {currentProgress} / {totalProgress}
							</div>
						)}
					</div>
				) : (
					<Table size='small' dataSource={data} rowKey={'car'}>
						<Column title='차량번호' dataIndex='car' key='car' />
						<Column title='이름' dataIndex='name' key='name' />
						<Column title='연락처' dataIndex='phone' key='phone' />
						<Column title='동' dataIndex='dong' key='dong' />
						<Column title='호' dataIndex='ho' key='ho' />
						<Column title='시작일' dataIndex='start' key='start' />
						<Column title='만료일' dataIndex='expire' key='expire' />
					</Table>
				)}
			</Modal>
		)
	}
}

export default ImportExcelModal
