import React from 'react'
import { PictureOutlined } from '@ant-design/icons'
import {
	Modal,
	Input,
	Button,
	DatePicker,
	TimePicker,
	Empty,
	Select,
	Col,
	Row,
	Typography,
	Radio,
} from 'antd'
import Config from '../config'
import moment from 'moment'

const { confirm } = Modal
const { Option } = Select
const { Text, Title } = Typography
const { TextArea } = Input
class PaymentModal extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			visible: false,
			data: {},
			isRegistration: false,
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			this.state.finalCharge === nextState.finalCharge &&
			this.state.visible == false &&
			nextState.visible == false
		) {
			return false
		}
		return true
	}

	componentDidUpdate(prevProps) {
		if (prevProps.charge !== this.props.charge) {
			this.setState({
				finalCharge: this.props.charge,
			})
		}
	}

	show({ data, isRegistration }) {
		this.setState({
			visible: true,
			data,
			isRegistration,
		})
	}
	applyUpdated(item) {
		if (item._id == this.state.data._id) {
			this.setState({
				data: item,
			})
		}
	}

	isVisible() {
		return this.state.visible
	}

	hide() {
		clearInterval(this.interval)
		this.setState({
			visible: false,
			isRegistration: false,
		})
	}

	formatter = new Intl.NumberFormat('ko-KR', {
		style: 'currency',
		currency: 'KRW',
	})

	render() {
		const {
			loading,
			discounts,
			types,
			onUpdate,
			onOk,
			onCancel,
			showImage,
			addAccess,
		} = this.props
		const { visible, data, isRegistration } = this.state

		const discountTypes = types
			.filter((x) => x.category == 'LPR_DISCOUNT')
			.reduce((acc, item) => {
				acc[item._id] = item
				return acc
			}, {})

		var total = moment.duration(
			moment(data.exitedTime) - moment(data.enteredTime)
		)
		return (
			<Modal
				width={900}
				title={'주차 정산'}
				centered
				open={visible}
				onOk={onOk}
				onCancel={() => {
					this.setState({
						visible: false,
					})
					if (onCancel) {
						onCancel()
					}
				}}
				footer={[
					// onDelete && (
					// 	<Button
					// 		key='delete'
					// 		onClick={() => {
					// 			onDelete()
					// 		}}
					// 		style={{ float: 'left' }}
					// 		type='danger'
					// 	>
					// 		무시하기
					// 	</Button>
					// ),
					<Button
						key='cancel'
						onClick={() => {
							this.setState({
								visible: false,
							})
							if (onCancel) {
								onCancel()
							}
						}}
					>
						취소
					</Button>,
					<Button
						key='edit'
						onClick={() => {
							if (onOk) {
								onOk(data)
							}
							this.hide()
						}}
						loading={loading}
						type='primary'
					>
						정산 완료
					</Button>,
				]}
			>
				<Row gutter={8}>
					<Col>
						<Row>
							<Col>
								<h3>차량번호</h3>
								<Title level={4}>
									{data.carplate + (isRegistration ? ' (등록차량)' : '')}
								</Title>
							</Col>
						</Row>
						<Row style={{ marginTop: 16 }} gutter={8}>
							<Col span={12}>
								<h3>입차시간</h3>
								<Title level={4}>
									{data.enteredTime != null ? (
										<p>
											{moment(data.enteredTime).format('MM-DD HH:mm:ss')}
											<Button
												shape='circle'
												icon={<PictureOutlined />}
												size='small'
												style={{ marginLeft: 8 }}
												onClick={() => {
													if (showImage != null) {
														showImage(data.enteredId)
													}
												}}
											/>
										</p>
									) : (
										<p>
											<Button
												onClick={() => {
													if (addAccess != null) {
														addAccess(data.carplate, 0)
													}
												}}
											>
												수동 입차 처리
											</Button>
										</p>
									)}
								</Title>
							</Col>
							<Col span={12}>
								<h3>출차시간</h3>
								<Title level={4}>
									{data.exitedTime != null ? (
										<p>
											{moment(data.exitedTime).format('MM-DD HH:mm:ss')}
											<Button
												shape='circle'
												icon={<PictureOutlined />}
												size='small'
												style={{ marginLeft: 8 }}
												onClick={() => {
													if (showImage != null) {
														showImage(data.exitedId)
													}
												}}
											/>
										</p>
									) : (
										<p>
											<Button
												onClick={() => {
													if (addAccess != null) {
														addAccess(data.carplate, 1)
													}
												}}
											>
												수동 출차 처리
											</Button>
										</p>
									)}
								</Title>
							</Col>
						</Row>
						{/* <Row style={{ marginTop: 16 }}>
							<h3>총 주차시간</h3>
							<Title level={4}>
								{`${total.days()} 일 ${total.hours()} 시간 ${total.minutes()} 분 ${total.seconds()} 초`}
							</Title>
						</Row> */}
						<Row style={{ marginTop: 16 }}>
							<Col span={8}>
								<h3>총 주차시간</h3>
								<Title level={4}>
									{total.isValid()
										? `${total.days()} 일 ${total.hours()} 시간 ${total.minutes()} 분 ${total.seconds()} 초`
										: ''}
									{/* {parseInt(total / 60) > 0 && parseInt(total / 60) + '시간 '}
								{parseInt(total % 60)} 분 */}
								</Title>
							</Col>
							<Col span={8}>
								<h3>할인 전 요금</h3>
								<Title level={4}>{this.formatter.format(data.charge)}</Title>
							</Col>
							<Col span={8}>
								<h3>할인 후 요금</h3>
								<Title type='danger' level={4}>
									{this.formatter.format(data.finalCharge)}
								</Title>
							</Col>
						</Row>
						{/* <Row style={{ marginTop: 16 }}></Row> */}
						<Row style={{ marginTop: 16 }}>
							<Col>
								<h3>할인권 선택</h3>
								<Radio.Group
									defaultValue={null}
									value={data.discountId}
									style={{ marginTop: 8, whiteSpace: 'pre-wrap' }}
									onChange={(e, i) => {
										this.setState(
											{
												data: { ...data, discountId: e.target.value },
											},
											() => {
												if (onUpdate != null) {
													onUpdate(this.state.data)
												}
											}
										)
									}}
								>
									{[{ name: '선택 안함', _id: null }, ...discounts].map(
										(item, i) => {
											var str = '-'
											if (discountTypes[item.type] != null) {
												var d = discountTypes[item.type]
												switch (d.keyname) {
													case 'discount_percent':
														str = item.value + '% 할인'
														break
													case 'discount_sum':
														str = '총액에서 ' + item.value + '원 할인'
														break
													case 'discount_fixed':
														str = item.value + '원 고정'
														break
													case 'discount_time':
														str = '주차시간 ' + item.value + '시간 감면'
														break
													default:
														str = '-'
												}
											}
											return (
												<Radio.Button
													value={item._id}
													style={{ height: 'auto', marginTop: 4 }}
												>
													<p>{item.name}</p>
													<p style={{ fontSize: 12, opacity: 0.9 }}>{str}</p>
												</Radio.Button>
											)
										}
									)}
								</Radio.Group>
							</Col>
						</Row>

						<Row style={{ marginTop: 16 }}>
							<h3>메모</h3>
							<TextArea
								rows={2}
								placeholder={'메모'}
								value={data.description}
								onChange={(e) => {
									var data = { ...this.state.data }
									data.description = e.target.value
									this.setState({
										data,
									})
								}}
							/>
						</Row>
					</Col>
				</Row>
			</Modal>
		)
	}
}

export default PaymentModal
