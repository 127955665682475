import { GET_NOTICES } from '../action/notice'

const REQUEST = '_REQUEST',
	SUCCESS = '_SUCCESS',
	FAILURE = '_FAILURE'

const defaultState = {
	notices: [],
	loading: false,
}

const noticeReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_NOTICES + REQUEST:
			return {
				...state,
				loading: true,
				notices: [],
			}
		case GET_NOTICES + SUCCESS:
			return {
				...state,
				notices: action.result.data,
				loading: false,
			}
		default: {
			return state
		}
	}
}

export default noticeReducer
