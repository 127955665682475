import Config from '../config'
import moment from 'moment'

export const GET_BLACKLIST = 'GET_BLACKLIST'
export const GET_BLACKLIST_COUNT = 'GET_BLACKLIST_COUNT'
export const ADD_BLACKLIST = 'ADD_BLACKLIST'
export const UPDATE_BLACKLIST = 'UPDATE_BLACKLIST'
export const DELETE_BLACKLIST = 'DELETE_BLACKLIST'

export const getBlacklist = ({
	parkingId,
	page = 0,
	limit = 20,
	q_car = '',
	start = '1970-01-01 00:00:00',
	end = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
}) => {
	return {
		type: GET_BLACKLIST,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/blacklist/getBlacklist`,
			params: {
				parking_id: parkingId,
				page,
				limit,
				q_car,
				start,
				end,
			},
		},
	}
}

export const getBlacklistCount = ({
	parkingId,
	q_car = '',
	start = '1970-01-01 00:00:00',
	end = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
}) => {
	return {
		type: GET_BLACKLIST_COUNT,
		promise: {
			method: 'get',
			url: Config.SERVER_URL + `/blacklist/getBlacklistCount`,
			params: {
				parking_id: parkingId,
				q_car,
				start,
				end,
			},
		},
	}
}

export const addBlacklist = ({ parkingId, item }) => {
	return {
		type: ADD_BLACKLIST,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/blacklist/addBlacklist',
			data: {
				parking_id: parkingId,
				carplate: item.car,
				comment: item.comment,
			},
		},
	}
}

export const updateBlacklist = ({ parkingId, item }) => {
	return {
		type: UPDATE_BLACKLIST,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/blacklist/updateBlacklist',
			data: {
				parking_id: parkingId,
				car_id: item.carId,
				comment: item.comment,
			},
		},
	}
}

export const deleteBlacklist = ({ parkingId, carIds }) => {
	return {
		type: DELETE_BLACKLIST,
		promise: {
			method: 'post',
			url: Config.SERVER_URL + '/blacklist/deleteBlacklist',
			data: {
				parking_id: parkingId,
				car_ids: carIds,
			},
		},
	}
}
